import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import "./ExploreContainer.css";
import { connect } from "../../data/connect";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import ShoppingTripList from "../shoppingTrips/ShoppingTripList";
import ScanInReceiptModal from "../shoppingTrips/ScanInReceiptModal";
import NewShoppingTripLine from "../shoppingTrips/NewShoppingTripLine";

interface OwnProps {}

interface StateProps {
  shoppingTrips: ShoppingTrip[];
}

interface DispatchProps {}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingTripsContainer: React.FC<ContainerProps> = ({
  shoppingTrips,
}) => {
  useEffect(() => {
    //console.log("Here");
    //getLogs();
    // eslint-disable-next-line
    setShowWelcomeMessage(false); //shoppingTrips.length === 0);
  }, shoppingTrips);

  const [showScanInReceiptModal, setShowScanInReceiptModal] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarShoppingTrip");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  const showScanInModal = () => {
    setShowScanInReceiptModal(!showScanInReceiptModal);
    // <ScanInReceiptModal show={showScanInReceiptModal}/> - Add for button to show up
    /*
    <IonItem>
        <IonButton
          size="small"
          shape="round"
          onClick={() => showScanInModal()}
        >
          Scan in receipt
        </IonButton>
      </IonItem>
     */
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="3" sizeSm="2">
                Date
              </IonCol>
              <IonCol size="4" sizeSm="2">
                Store
              </IonCol>
              <IonCol sizeSm="1" className="ion-hide-sm-down">
                Total items
              </IonCol>
              <IonCol size="3" sizeSm="2">
                Total spent
              </IonCol>
              <IonCol sizeSm="4" className="ion-hide-sm-down">
                Notes
              </IonCol>
              <IonCol size="2"></IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarShoppingTrip">
        <IonGrid>
          <ShoppingTripList shoppingTrips={shoppingTrips} hide={false} />
          <NewShoppingTripLine />
        </IonGrid>
        <ScanInReceiptModal show={showScanInReceiptModal} />
        <IonAlert
          isOpen={showWelcomeMessage}
          onDidDismiss={() => setShowWelcomeMessage(false)}
          header={"Welcome!"}
          message={
            "Shopping trips are where all the data will be drawn from. \n" +
            "You will first select the store where you did the shopping and then the date that this trip occurred. \n" +
            "After that you will add each of the items that you bought as well as the quantity and the price. \nYou can always create new items by selecting that from the dropdown. \r\n\r\n" +
            "Happy tracking!"
          }
          buttons={["OK"]}
        />
      </IonContent>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingTrips: state.shoppingTripData.shoppingTrips,
  }),
  mapDispatchToProps: {},
  component: ShoppingTripsContainer,
});
