import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { ShoppingItem, ShoppingTrip } from "../../models/ShoppingTrip";
import { getShoppingItems } from "../../data/shoppingTrips/shoppingTrips.actions";
import ShoppingItemItem from "./ShoppingItemItem";
import { connect } from "../../data/connect";

interface OwnProps {
  //shoppingItems: ShoppingItem[];
  shoppingTrip: ShoppingTrip;
  hide: boolean;
}

interface StateProps {
  shoppingItems: ShoppingItem[];
}

interface DispatchProps {
  getShoppingItems: typeof getShoppingItems;
}

interface ShoppingItemListProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingItemList: React.FC<ShoppingItemListProps> = ({
  //shoppingItems,
  shoppingTrip,
  hide,
  shoppingItems,
  getShoppingItems,
}) => {
  /*const [shoppingItemsList, setModifiedShoppingItemsList] = useState<
    ShoppingItem[]
  >(shoppingItems);*/

  useEffect(() => {
    (async function loadData() {
      await getShoppingItems(shoppingTrip);
    })();

    //setModifiedShoppingItemsList(shoppingItems);
  }, [shoppingTrip]); //, shoppingItems

  //console.log(shoppingItems);
  if (shoppingItems.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Shopping Items Found</IonListHeader>
      </IonList>
    );
  }

  if (shoppingItems[0].shoppingTripId !== shoppingTrip._id && !hide) {
    return (
      <IonList>
        <IonListHeader>Loading...</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {shoppingItems.map((shoppingItem) => (
          <IonItemGroup key={`group-${shoppingItem._id}`}>
            <ShoppingItemItem
              shoppingItem={shoppingItem}
              shoppingTrip={shoppingTrip}
            ></ShoppingItemItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingItems: state.shoppingTripData.shoppingItems,
  }),
  mapDispatchToProps: { getShoppingItems },
  component: ShoppingItemList,
});
