import React, { useState } from "react";
import {
  IonItem,
  IonLabel,
  IonItemGroup,
  IonAlert,
  IonIcon,
} from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { Unit } from "../../models/Item";
import EditUnitModal from "./EditUnitModal";
import { removeUnit } from "../../data/items/items.actions";
import { connect } from "../../data/connect";

interface OwnProps {
  unit: Unit;
}

interface StateProps {}

interface DispatchProps {
  removeUnit: typeof removeUnit;
}

interface UnitItemProps extends OwnProps, StateProps, DispatchProps {}

const UnitItem: React.FC<UnitItemProps> = ({ unit, removeUnit }) => {
  /*
  name: string;
  symbol: string;
     */
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const showEditUnitModal = () => {
    setShowEditModal(!showEditModal);
  };
  return (
    <IonItemGroup>
      <IonItem>
        <IonLabel onClick={showEditUnitModal}>{unit.name}</IonLabel>
        <IonLabel onClick={showEditUnitModal}>{unit.symbol}</IonLabel>
        <IonIcon icon={trashOutline} onClick={() => setShowDeleteAlert(true)} />
      </IonItem>
      <EditUnitModal show={showEditModal} unit={unit} />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeUnit(unit);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { removeUnit },
  component: React.memo(UnitItem),
});
