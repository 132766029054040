import React from "react";
import "./ExploreContainer.css";
import { IonContent, IonTitle } from "@ionic/react";
import { connect } from "../../data/connect";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

const ChangeLogContainer: React.FC<ContainerProps> = () => {
  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarSettings");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  return (
    <IonContent class="scrollbarSettings">
      <IonTitle>Latest changes:</IonTitle> <br />
      05/09/2022: <br />
      - Clarified log in page and added extra demo page <br />
      <br />
      27/11/2021: <br />
      - Added check to prevent deleting the Demo account <br />
      <br />
      13/11/2021: <br />
      - Added voucher codes functionality <br />
      <br />
      11/11/2021: <br />
      - Fixed theme issues <br />
      <br />
      04/11/2021: <br />
      - Changed logo and light theme <br />
      <br />
      11/10/2021: <br />
      - Fixed Dashboard bug on iOS <br />
      <br />
      09/10/2021: <br />
      - Colour scheme of light redesigned <br />
      <br />
      30/09/2021: <br />
      - Some cosmetic changes to the items grid to help with longer lists <br />
      <br />
      18/09/2021: <br />
      - Added confirm email address functionality <br />
      <br />
      10/09/2021: <br />
      - Added terms and conditions <br />
      <br />
      29/07/2021: <br />
      - Added Freemium checks and functionality <br />- Added info screen for
      the first time a user goes to their shopping trips <br />
      - Filtered item text now gets auto populated when the item isn't found and
      the user selects "Add new item" page <br />
      <br />
      07/06/2021: <br />
      - Added amount spent to new shopping trip page <br />
      <br />
      04/06/2021: <br />
      - Fixed creating shopping trip from a shopping list functionality <br />
      <br />
      31/05/2021: <br />
      - Added date range picker to charts dashboard <br />
      <br />
      29/05/2021: <br />
      - Changed the date picker <br />
      <br />
      25/05/2021: <br />
      - Modified getting started wizard <br />
      <br />
      21/05/2021: <br />
      - Added item price history chart <br />
      <br />
      19/05/2021: <br />
      - User is now greeted with the Home page when logging in instead of the
      dashboard <br />
      <br />
      18/05/2021: <br />
      - Improved shopping trip and list pages performance <br />
      - Added to loading bar to "Spending per item category" chart <br />
      <br />
      12/05/2021: <br />
      - Moved shopping list store compare to own page and smoothed out the
      experience a bit <br />
      <br />
      07/05/2021: <br />
      - Creating new units or item categories will now auto select them if
      possible <br />
      <br />
      06/05/2021: <br />
      - Fixed a few bugs relating to creating shopping lists and trips <br />
      <br />
      03/05/2021: <br />
      - Changed the create shopping list and trip screens to look like the edit
      screens <br />
      - Changed the + button functionality to create a new instance for that
      particular page <br />
      <br />
      17/04/2021: <br />
      - Made the app a bit more GDPR compliant <br />
      <br />
      13/04/2021: <br />
      - Added funtionality allowing the user to export all their data <br />
      <br />
      23/02/2021: <br />
      - Fixed bug where new items weren't being displayed on the item page until
      refreshing <br />
      <br />
      20/02/2021: <br />
      - Improved item page performance <br />
      <br />
      12/02/2021: <br />
      - Redesigned shopping lists page <br />
      <br />
      10/02/2021: <br />
      - Improved item selection <br />
      <br />
      09/02/2021: <br />
      - Improved Item select/create drop down <br />
      - Added delete actions, preventing users from deleting items, stores, etc
      when they are being used elsewhere <br />
      <br />
      05/02/2021: <br />
      - Improved Store select/create drop down <br />- Added checks so that
      users don't delete units or stores that are in use <br />
      <br />
      02/02/2021: <br />
      - Redesigned the shopping trips page <br />
      <br />
      23/01/2021: <br />
      - Added spending per item category chart <br />
      <br />
      19/01/2021: <br />
      - Added demo account <br />
      <br />
      13/01/2021: <br />
      - Added functionality so that the user can delete their account <br />
      <br />
      20/11/2020: <br />
      - Added "Enter" functionality <br />
      <br />
      02/11/2020: <br />
      - Fixed item creation modal <br />
      - Small cosmetic changes <br />
      <br />
      23/10/2020: <br />- Added error messages for trying to log in with the
      incorrect credentials <br />
      - Added messages providing feedback regarding the reset password
      functionality <br />
      <br />
      22/10/2020: <br />
      - Changed layout for smaller screens <br />
      <br />
      14/10/2020: <br />
      - Added functionality for a user you reset their password <br />
      <br />
      13/10/2020: <br />
      - Added user on boarding <br />
      - Added user settings for currency and number format <br />
      - Added custom scroll bar <br />
      <br />
      03/10/2020: <br />
      - Added shopping trips chart <br />
      - Added total trips and spent stats <br />
      <br />
      01/10/2020: <br />
      - Added a modal to allow a price comparison for an item across stores
      <br />
      <br />
      24/09/2020: <br />
      - Added a modal to allow comparing shopping list prices between stores
      <br />
      <br />
      19/09/2020: <br />
      - Added Shopping Lists! <br />
      - Fixed a few totals bugs <br />
      <br />
      28/08/2020: <br />
      - Default units added <br />
      - Default item categories added <br />
      - Added last price to items <br />
      <br />
      07/08/2020: <br />
      - Stores show average prices and spending <br />- Items show average
      prices <br />- Dashboard added
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ChangeLogContainer,
});
