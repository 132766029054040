import React, { useState } from "react";
import {
  //IonHeader,
  //IonToolbar,
  //IonTitle,
  IonContent,
  IonPage,
  //IonButtons,
  //IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonCheckbox,
} from "@ionic/react";
import "./Login.scss";
import {
  //setIsLoggedIn,
  register, //,
  //confirmEmail,
} from "../data/user/user.actions";
import { RegisterUser } from "../models/User";
import { connect } from "../data/connect";
import { Footer } from "../components/layout/Footer";
import { useLocation, RouteComponentProps } from "react-router";
import DemoLoginDetails from "../components/auth/DemoLoginDetails";
import DemoLoginButton from "../components/auth/DemoLoginButton";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  //setIsLoggedIn: typeof setIsLoggedIn;
  register: typeof register;
  //confirmEmail: typeof confirmEmail;
}

interface LoginProps extends OwnProps, DispatchProps {}

const Login: React.FC<LoginProps> = ({
  //setIsLoggedIn,
  history,
  register,
  //confirmEmail,
  match,
}) => {
  const [registerUser, setRegisterUser] = useState<RegisterUser>({
    name: "",
    email: "",
    password: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [agreeTOCError, setAgreeTOCError] = useState(false);
  const [over18Error, setOver18Error] = useState(false);

  const [agreeTOC, setAgreeTOC] = useState(false);
  const [over18, setOver18] = useState(false);

  const location = useLocation();

  const { name, email, password } = registerUser;

  const onChange = (fieldName: string, fieldValue: string) => {
    setRegisterUser({ ...registerUser, [fieldName]: fieldValue });
  };

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!name) {
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (!email) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!password) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (!agreeTOC) {
      setAgreeTOCError(true);
    } else {
      setAgreeTOCError(false);
    }

    if (!over18) {
      setOver18Error(true);
    } else {
      setOver18Error(false);
    }

    if (name && email && password && agreeTOC && over18) {
      //await setIsLoggedIn(true);
      await register(registerUser);
      //confirmEmail(registerUser.email);
      history.push("/", { direction: "none" });
    }
  };

  return (
    <IonPage id="signup-page">
      <IonContent>
        <div className="login-logo">
          <img src="assets/icon/Logo.png" alt="Shopping Tracker logo" />
        </div>

        <form noValidate onSubmit={login}>
          <IonList className="login-block">
            <IonItem>
              <IonLabel position="stacked" color="primary">
                Name
              </IonLabel>
              <IonInput
                name="name"
                type="text"
                value={name}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => {
                  onChange("name", e.detail.value!);
                  setNameError(false);
                }}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && nameError && (
              <IonText color="danger">
                <p className="ion-padding-start">Name is required</p>
              </IonText>
            )}

            <IonItem>
              <IonLabel position="stacked" color="primary">
                Email
              </IonLabel>
              <IonInput
                name="email"
                type="email"
                value={email}
                onIonChange={(e) => {
                  onChange("email", e.detail.value!);
                  setEmailError(false);
                }}
              ></IonInput>
            </IonItem>

            {formSubmitted && emailError && (
              <IonText color="danger">
                <p className="ion-padding-start">Email is required</p>
              </IonText>
            )}

            <IonItem>
              <IonLabel position="stacked" color="primary">
                Password
              </IonLabel>
              <IonInput
                name="password"
                type="password"
                value={password}
                onIonChange={(e) => {
                  onChange("password", e.detail.value!);
                  setPasswordError(false);
                }}
              ></IonInput>
            </IonItem>

            {formSubmitted && passwordError && (
              <IonText color="danger">
                <p className="ion-padding-start">Password is required</p>
              </IonText>
            )}

            <IonItem>
              <IonCheckbox
                checked={agreeTOC}
                slot="start"
                color="primary"
                onIonChange={(e) => {
                  setAgreeTOC(e.detail.checked);
                }}
              ></IonCheckbox>
              <IonText>
                I have read and agreed with the{" "}
                <a
                  href="https://www.shoppingtracker.app/privacypolicy"
                  target="_blank"
                >
                  privacy policy
                </a>{" "}
                and{" "}
                <a href="https://www.shoppingtracker.app/tos" target="_blank">
                  terms and conditions
                </a>
              </IonText>
            </IonItem>

            {formSubmitted && agreeTOCError && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Please read and agree with the above
                </p>
              </IonText>
            )}

            <IonItem>
              <IonCheckbox
                checked={over18}
                slot="start"
                color="primary"
                onIonChange={(e) => {
                  setOver18(e.detail.checked);
                }}
              ></IonCheckbox>
              <IonText>I am at least 18 years old</IonText>
            </IonItem>

            {formSubmitted && over18Error && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  You need to state that you are at least 18 years old before
                  you are allowed to use the service
                </p>
              </IonText>
            )}
          </IonList>

          <IonRow className="login-block">
            <IonCol>
              <IonButton type="submit" expand="block">
                Create
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="login-block">
            <IonCol>
              <DemoLoginButton
                history={history}
                location={location}
                match={match}
              />
            </IonCol>
          </IonRow>
          <DemoLoginDetails />
        </form>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    //setIsLoggedIn,
    register, //,
    //confirmEmail,
  },
  component: Login,
});
