import { IonItemGroup, IonList, IonListHeader, IonProgressBar } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { Item, LastStorePrice } from "../../models/Item";
import ItemShopCompareItem from "./ItemShopCompareItem";
import { connect } from "../../data/connect";

interface OwnProps {
  item: Item;
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface ItemShopCompareListProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ItemShopCompareList: React.FC<ItemShopCompareListProps> = ({
  item,
  hide,
}) => {
  //console.log(item);
  if (item === null) {
    return (
      <IonList>
        <IonListHeader>Loading...</IonListHeader>
        <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
      </IonList>
    );
  }

  if (item.storeLastPrice.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Prices Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {item.storeLastPrice.map((lastStorePrice) => (
          <IonItemGroup key={lastStorePrice.storeId? `group-${lastStorePrice.storeId._id}`: `group-${lastStorePrice._id}`}>
            <ItemShopCompareItem
              lastStorePrice={lastStorePrice}
            ></ItemShopCompareItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ItemShopCompareList,
});
