import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Unit } from "../../models/Item";
import { addNewUnit } from "../../data/items/items.actions";
//import "./CreateUnitModal.scss";

interface OwnProps {
  show: boolean;
  onCreated: (unit: Unit) => void;
}

interface StateProps {}

interface DispatchProps {
  addNewUnit: typeof addNewUnit;
}

interface CreateUnitProps extends OwnProps, StateProps, DispatchProps {}

const CreateUnitModal: React.FC<CreateUnitProps> = ({ show, onCreated, addNewUnit }) => {
  const [unit, setUnit] = useState<Unit>({
    _id: "",
    name: "",
    symbol: "",
  });

  //const [name, setName] = useState<string>("");
  //const [symbol, setSymbol] = useState<string>("");
  const name = unit.name;
  const symbol = unit.symbol;
  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
    //console.log(`Show: ${show}`);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) =>
    setUnit({ ...unit, [fieldName]: fieldValue });

  const insertUnit = async () => {
    //console.log(name);
    //console.log(unit);
    await addNewUnit(unit);
    setShowToastCreated(true);
    setTimeout(() => {
      setShowModal(false);
      onCreated(unit);
      setUnit({ _id: "", name: "", symbol: "" });
    }, 2000)        
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-unit-modal">
        <p>Please enter the details for your new unit</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the unit name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> Symbol:</IonLabel>
          <IonInput
            value={symbol}
            placeholder="Enter the unit symbol"
            onIonChange={(e) => onChange("symbol", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem slot="end">
          <IonButton onClick={insertUnit} slot="end">
            Create Unit
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your unit has been added."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { addNewUnit },
  component: CreateUnitModal,
});
