import { getShoppingTripData } from "../dataApi";
import { ActionType } from "../../utils/types";
import { ShoppingTripsState } from "./shoppingTrips.state";
import { ShoppingTrip, ShoppingItem } from "../../models/ShoppingTrip";
import axios from "axios";
import { ShoppingListItem } from "../../models/ShoppingList";

export const loadShoppingTripData =
  () => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoading(true));
    const data = await getShoppingTripData();
    dispatch(setData(data));
    dispatch(setLoading(false));
  };

export const loadShoppingItemData =
  async (data: ShoppingTrip) => async (dispatch: React.Dispatch<any>) => {
    await getShoppingItems(data);
  };

export const addNewShoppingTripItem =
  (shoppingTrip: ShoppingTrip, shoppingItem: ShoppingItem) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      if (shoppingItem.unitId.symbol === "") {
        shoppingItem.unitId = shoppingItem.itemId.defaultUnitId;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        "/api/shoppingtripitems",
        shoppingItem,
        config
      );
      const result = await res.data; //json();
      //console.log(result);

      dispatch(addShoppingTripItem(result));
      //shoppingTrip.shoppingTripItems.push(result);
      //console.log(shoppingTrip);
      dispatch(editShoppingTrip(shoppingTrip));
    } catch (error) {
      console.log("Error in shoppingTrips.actions -> addNewShoppingTripItem");
      //dispatch(loginFailure(error));
    }
  };

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-shoppingTrips-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<ShoppingTripsState>) =>
  ({
    type: "set-shoppingTrips-data",
    data,
  } as const);

export const getShoppingItems =
  (data: ShoppingTrip) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      if (data._id !== "") {
        const res = await axios.get(`/api/shoppingtripitems/${data._id}`);
        const result = await res.data; //json();

        //console.log(result);

        dispatch(getShoppingItem(result));
      }
    } catch {
      console.log("Error in shoppingTrips.actions -> getShoppingItems");
    }
  };

export const convertShoppingListToTrip =
  (
    shoppingTrip: ShoppingTrip,
    shoppingListItems: ShoppingListItem[],
    populatePrices: boolean,
    createOnlyCompleted: boolean
  ) =>
  async (dispatch: React.Dispatch<any>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/shoppingtrips", shoppingTrip, config);
      const result = await res.data;

      for await (let shoppingListItem of shoppingListItems) {
        if (createOnlyCompleted && !shoppingListItem.completed) {
          continue;
        }
        let shoppingItem: ShoppingItem = {
          _id: "",
          shoppingTripId: result._id,
          itemId: {
            _id: "",
            userId: "",
            name: "",
            defaultUnitId: {
              _id: "",
              name: "",
              symbol: "",
            },
            itemCategories: [],
            avePrice: 0,
            lastPrice: 0,
            storeLastPrice: [],
          },
          unitId: {
            _id: "",
            name: "",
            symbol: "",
          },
          qty: shoppingListItem.qty,
          price: 0,
          notes: shoppingListItem.notes,
        };

        shoppingItem.itemId = shoppingListItem.itemId;
        shoppingItem.unitId = shoppingListItem.unitId;

        if (populatePrices) {
          var lastPrice = 0;
          shoppingListItem.itemId.storeLastPrice.forEach((storeLastPrice) => {
            if (
              JSON.stringify(storeLastPrice.storeId) ===
              `"${shoppingTrip.storeId._id}"`
            ) {
              lastPrice = storeLastPrice.lastPrice;
            }
          });
          if (lastPrice === 0) {
            lastPrice = shoppingListItem.itemId.lastPrice;
          }

          shoppingItem.price = lastPrice * shoppingListItem.qty;
        }

        /*const resItem = */ await axios.post(
          "/api/shoppingtripitems",
          shoppingItem,
          config
        );
        //const resultItem = await resItem.data;
      }

      const updateRes = await axios.put(
        `/api/shoppingTrips/${result._id}`,
        result,
        config
      );
      //console.log(updateRes.data);
      const updateResult = await updateRes.data;

      dispatch(addShoppingTrip(updateResult));
    } catch {
      console.log("Error in shoppingTrips.actions -> addNewShoppingTrip");
    }
  };

export const addNewShoppingTrip =
  (data: ShoppingTrip) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/shoppingtrips", data, config);
      const result = await res.data; //json();

      //console.log(result);

      dispatch(addShoppingTrip(result));
    } catch {
      console.log("Error in shoppingTrips.actions -> addNewShoppingTrip");
    }
  };

export const addNewShoppingItem =
  (data: ShoppingItem) => async (dispatch: React.Dispatch<any>) => {
    try {
      if (data.unitId.symbol === "") {
        data.unitId = data.itemId.defaultUnitId;
      }

      //console.info(data);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/shoppingtripitems", data, config);
      const result = await res.data; //json();

      dispatch(addShoppingTripItem(result));
    } catch {
      console.log("Error in shoppingTrips.actions -> addNewShoppingItem");
    }
  };

export const editShoppingTrip =
  (data: ShoppingTrip) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/shoppingTrips/${data._id}`,
        data,
        config
      );
      const result = await res.data;
      //console.info(result);

      dispatch(updateShoppingTrip(result));
    } catch {
      console.log("Error in shoppingTrips.actions -> editShoppingTrip");
    }
  };

export const editShoppingItem =
  (data: ShoppingItem) => async (dispatch: React.Dispatch<any>) => {
    try {
      if (data.unitId.symbol !== data.itemId.defaultUnitId.symbol) {
        data.unitId = data.itemId.defaultUnitId;
      }
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/shoppingtripitems/${data._id}`,
        data,
        config
      );
      const result = await res.data;
      //console.info(result);

      dispatch(updateShoppingItem(result));
    } catch {
      console.log("Error in shoppingTrips.actions -> editShoppingTrip");
    }
  };

export const removeShoppingTrip =
  (data: ShoppingTrip) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.delete(`/api/shoppingTrips/${data._id}`, config);
      /*const result = */ await res.data;
      //console.info(result);

      dispatch(deleteShoppingTrip(data));
    } catch {
      console.log("Error in shoppingTrips.actions -> editShoppingTrip");
    }
  };

export const removeShoppingItem =
  (data: ShoppingItem) => async (dispatch: React.Dispatch<any>) => {
    try {
      //console.info(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.delete(
        `/api/shoppingtripitems/${data._id}`,
        config
      );
      /*const result = */ await res.data;
      //console.info(result);

      dispatch(deleteShoppingItem(data));
    } catch {
      console.log("Error in shoppingTrips.actions -> removeShoppingItem");
    }
  };

export const getShoppingTrip = (data: ShoppingTrip) =>
  ({
    type: "get-shoppingTrip",
    data,
  } as const);

export const setCurrentShoppingTrip = (data: ShoppingTrip) =>
  ({
    type: "set-current-shoppingTrip",
    data,
  } as const);

export const addShoppingTrip = (data: ShoppingTrip) =>
  ({
    type: "add-shoppingTrip",
    data,
  } as const);

export const updateShoppingTrip = (data: ShoppingTrip) =>
  ({
    type: "update-shoppingTrip",
    data,
  } as const);

export const deleteShoppingTrip = (data: ShoppingTrip) =>
  ({
    type: "delete-shoppingTrip",
    data,
  } as const);

export const getShoppingItem = (data: ShoppingItem[]) =>
  ({
    type: "get-shoppingItem",
    data,
  } as const);

export const addShoppingTripItem = (data: ShoppingItem) =>
  ({
    type: "add-shoppingTripItem",
    data,
  } as const);

export const updateShoppingItem = (data: ShoppingItem) =>
  ({
    type: "update-shoppingItem",
    data,
  } as const);

export const deleteShoppingItem = (data: ShoppingItem) =>
  ({
    type: "delete-shoppingItem",
    data,
  } as const);

export const updateFilteredTrips = (filteredTrips: string[]) =>
  ({
    type: "update-filtered-trips",
    filteredTrips,
  } as const);

export const setSearchText = (searchText?: string) =>
  ({
    type: "set-search-text",
    searchText,
  } as const);

export type ShoppingTripsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof getShoppingTrip>
  | ActionType<typeof setCurrentShoppingTrip>
  | ActionType<typeof addShoppingTrip>
  | ActionType<typeof updateShoppingTrip>
  | ActionType<typeof deleteShoppingTrip>
  | ActionType<typeof getShoppingItem>
  | ActionType<typeof addShoppingTripItem>
  | ActionType<typeof updateShoppingItem>
  | ActionType<typeof deleteShoppingItem>
  | ActionType<typeof updateFilteredTrips>
  | ActionType<typeof setSearchText>;
