import React from "react";
import {
  IonButton,
  IonCheckbox,
  //IonContent,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { DefaultItemCategory, ItemCategory } from "../../models/Item";
import {
  addNewItemCategory,
  updateDefaultItemCategories,
} from "../../data/items/items.actions";
//import { getDefaultItemCategories } from "../../data/defaultLists";

interface OwnProps {
  onCreate: () => void;
}

interface StateProps {
  itemCategories: ItemCategory[];
  defaultItemCategories: DefaultItemCategory[];
}

interface DispatchProps {
  addNewItemCategory: typeof addNewItemCategory;
  updateDefaultItemCategories: typeof updateDefaultItemCategories;
  //getDefaultItemCategories: typeof getDefaultItemCategories;
}

type CreateDefaultItemCategoryListProps = OwnProps & StateProps & DispatchProps;

export const CreateDefaultItemCategoryList: React.FC<CreateDefaultItemCategoryListProps> =
  ({
    onCreate,
    itemCategories,
    defaultItemCategories,
    addNewItemCategory,
    updateDefaultItemCategories,
    //getDefaultItemCategories,
  }) => {
    /*const defaultItemCategories = [
    { _id: "0", name: "Fruit", checked: false },
    { _id: "1", name: "Vegetables", checked: false },
    { _id: "2", name: "Starch", checked: false },
    { _id: "3", name: "Meat", checked: false },
    { _id: "4", name: "Treats", checked: false },
    { _id: "5", name: "Baking", checked: false },
    { _id: "6", name: "Drinks", checked: false },
  ];*/

    const setChecked = async (
      index: number,
      itemCategory: DefaultItemCategory,
      checked: boolean
    ) => {
      defaultItemCategories[index] = {
        _id: itemCategory._id,
        name: itemCategory.name,
        checked: checked,
      };
    };

    const onClick = async () => {
      var checkedCategories = defaultItemCategories.filter((category) => {
        return category.checked === true;
      });

      for await (let category of checkedCategories) {
        const newItemCategory: ItemCategory = {
          _id: "",
          name: category.name,
          userId: "0",
        };
        if (
          itemCategories.filter(
            (itemCategory) => itemCategory.name === newItemCategory.name
          ).length === 0
        ) {
          await addNewItemCategory(newItemCategory);
        }
      }

      updateDefaultItemCategories(defaultItemCategories);

      onCreate();
    };

    return (
      <>
        <IonItemGroup>
          <IonList>
            {defaultItemCategories
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((itemCategory, index) => (
                <IonItem key={`CatItem-${itemCategory._id}-${index}`}>
                  <IonCheckbox
                    key={`Category-${itemCategory._id}-${index}`}
                    checked={itemCategory.checked}
                    onIonChange={(e) =>
                      setChecked(index, itemCategory, e.detail.checked)
                    }
                  ></IonCheckbox>
                  <IonLabel> {itemCategory.name}</IonLabel>
                </IonItem>
              ))}
          </IonList>
        </IonItemGroup>
        <IonButton onClick={onClick}>Create</IonButton>
      </>
    );
  };

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    itemCategories: state.data.itemCategories,
    defaultItemCategories: state.data.defaultItemCategories,
  }),
  mapDispatchToProps: { addNewItemCategory, updateDefaultItemCategories },
  component: CreateDefaultItemCategoryList,
});
