import React, { useEffect } from "react";
import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import "./ExploreContainer.css";
import { connect } from "../../data/connect";
import { Store } from "../../models/Store";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import StoreList from "../stores/StoreList";
import NewStoreLine from "../stores/NewStoreLine";
import { loadStoreData } from "../../data/stores/stores.actions";

interface OwnProps {}

interface StateProps {
  stores: Store[];
  shoppingTrips: ShoppingTrip[];
}

interface DispatchProps {
  loadStoreData: typeof loadStoreData;
}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

const StoresContainer: React.FC<ContainerProps> = ({
  stores,
  shoppingTrips,
  loadStoreData,
}) => {
  useEffect(() => {
    loadStoreData();
    // eslint-disable-next-line
  }, [shoppingTrips]);

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarStore");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  return (
    <IonContent class="scrollbarStore">
      <IonGrid>
        <IonRow>
          <IonCol size="5">Name</IonCol>
          <IonCol size="2">Total trips</IonCol>
          <IonCol size="2">Total spent</IonCol>
          <IonCol size="2">Ave spent</IonCol>
          <IonCol size="1"></IonCol>
        </IonRow>
        <StoreList stores={stores} hide={false} />
        <NewStoreLine />
      </IonGrid>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    stores: state.storeData.stores,
    shoppingTrips: state.shoppingTripData.shoppingTrips,
  }),
  mapDispatchToProps: { loadStoreData },
  component: StoresContainer,
});
