import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
  IonDatetime,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import { Store } from "../../models/Store";
import SelectStoreModal from "../stores/SelectStoreModal";
import { addNewShoppingTrip } from "../../data/shoppingTrips/shoppingTrips.actions";

interface OwnProps {
  show: boolean;
}

interface StateProps {}

interface DispatchProps {
  addNewShoppingTrip: typeof addNewShoppingTrip;
}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type CreateShoppingTripProps = OwnProps & StateProps & DispatchProps;

export const CreateShoppingTripModal: React.FC<CreateShoppingTripProps> = ({
  show,
  addNewShoppingTrip,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [shoppingTrip, setShoppingTrip] = useState<ShoppingTrip>({
    _id: "",
    userId: "",
    storeId: {
      _id: "",
      userId: "",
      name: "",
      url: "",
      totalTrips: 0,
      totalSpent: 0,
    },
    date: "",
    notes: "",
    shoppingTripItems: [],
    totalLines: 0,
    totalAmount: 0,
  });

  //const id = item.id;
  const date = shoppingTrip.date;
  const notes = shoppingTrip.notes;

  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setShoppingTrip({ ...shoppingTrip, [fieldName]: fieldValue });
  };

  const onChangeStore = (fieldValue: Store) => {
    setShoppingTrip({ ...shoppingTrip, storeId: fieldValue });
  };

  const insertShoppingTrip = async () => {
    await addNewShoppingTrip(shoppingTrip);
    setShowToastCreated(true);
    setShoppingTrip({
      _id: "",
      userId: "",
      storeId: {
        _id: "",
        userId: "",
        name: "",
        url: "",
        totalTrips: 0,
        totalSpent: 0,
      },
      date: "",
      notes: "",
      shoppingTripItems: [],
      totalLines: 0,
      totalAmount: 0,
    });
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-shoppingTrip-modal">
        <p>Please enter the details of your new shopping trip</p>
        <IonItem>
          <IonLabel>Date:</IonLabel>
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={date}
            placeholder="Enter the date it took place"
            onIonChange={(e) => onChange("Date", e.detail.value!)}
            slot="end"
          ></IonDatetime>
        </IonItem>
        <IonItem>
          <IonLabel>Store:</IonLabel>
          <SelectStoreModal
            store={shoppingTrip.storeId}
            onChanged={onChangeStore}
            slot="end"
          />
        </IonItem>
        <IonItem>
          <IonLabel> Notes:</IonLabel>
          <IonInput
            value={notes}
            placeholder="Enter your notes"
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton onClick={insertShoppingTrip} slot="end">
            Create Shopping Trip
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your shopping trip has been created."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { addNewShoppingTrip },
  component: CreateShoppingTripModal,
});
