import React, { useState, useEffect } from "react";
import {
  //IonSelect,
  //IonSelectOption,
  IonSearchbar,
  IonContent,
  IonModal,
  IonList,
  //IonItemOptions,
  //IonItemOption,
  IonItem,
  IonRadio,
  IonButton,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Item } from "../../models/Item";
import CreateItemModal from "../items/CreateItemModal";
import "./SelectItemModal.scss";

interface OwnProps {
  item: Item;
  onChanged: (item: Item) => void;
  show: boolean;
  slot?: string;
}

interface StateProps {
  items: Item[];
}

interface DispatchProps {}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type SelectItemProps = OwnProps & StateProps & DispatchProps;

export const SelectItemModal: React.FC<SelectItemProps> = ({
  item,
  onChanged,
  show,
  slot,
  items,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [addNewItemOption, setAddNewItemOption] = useState<Item>({
    _id: "addNewItem",
    userId: "",
    name: "Add new item",
    defaultUnitId: {
      _id: "",
      name: "",
      symbol: "",
    },
    itemCategories: [],
    avePrice: 0,
    lastPrice: 0,
    storeLastPrice: [],
  });
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
    setSearchText("");
  }, [show]);

  const itemCompareWith = (o1: Item, o2: Item) => {
    return o1 && o2 ? o1._id === o2._id : o1 === o2;
  };

  const onChange = (fieldValue: Item) => {
    //console.log(fieldValue)
    if (fieldValue === addNewItemOption) {
      //console.log(showCreateModal);
      showCreateItemModal(true);
    } else {
      onChanged(fieldValue);
      setShowModal(false);
    }
  };

  const onCreate = (newItem: Item) => {
    //console.log(newItem)
    //const filteredItems = items.filter((item) => item.name === newItem.name)
    //console.log(filteredItems[0])
    if (newItem !== undefined) {
      //console.log("Here")
      onChange(newItem);
    }
    showCreateItemModal(false);
  };

  const showCreateItemModal = (showItemModal: boolean) => {
    //console.log(showCreateModal);
    setShowCreateModal(showItemModal);
  };

  return (
    <IonContent>
      <CreateItemModal
        show={showCreateModal}
        onCreated={onCreate}
        partialName={searchText}
      ></CreateItemModal>
      <IonModal isOpen={showModal} id="select-item-modal">
        <IonContent>
          <div slot="fixed">
            <IonSearchbar
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
              animated
              placeholder="Filter items"
            />
          </div>

          <IonList>
            <IonItem key="filterItem"></IonItem>
            {items
              .filter((currentItem) => {
                if (currentItem.name) {
                  return (
                    currentItem.name
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase()) > -1
                  );
                }
              })
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((item) => (
                <IonItem key={item._id} onClick={(e) => onChange(item)}>
                  <IonRadio slot="start"></IonRadio>
                  {item.name}
                </IonItem>
              ))}
            <IonItem key="newItem" onClick={(e) => onChange(addNewItemOption)}>
              <IonRadio slot="start"></IonRadio>
              ...Add new item
            </IonItem>
          </IonList>
        </IonContent>
        <IonItem>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    items: state.data.items,
  }),
  mapDispatchToProps: {},
  component: SelectItemModal,
});
