import React, { useState } from "react";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";
import CreateItemModal from "../items/CreateItemModal";
import CreateShoppingTripModal from "../shoppingTrips/CreateShoppingTripModal";
import CreateStoreModal from "../stores/CreateStoreModal";
import CreateUnitModal from "../units/CreateUnitModal";
import CreateItemCategoryModal from "../itemCategories/CreateItemCategoryModal";
//import CreateShoppingListModal from "../shoppingLists/"
import { useHistory } from "react-router-dom";

interface OwnProps {
  source: string;
}

interface SingleAddBtnProps extends OwnProps {}

export const SingleAddBtn: React.FC<SingleAddBtnProps> = ({ source }) => {
  const [showItemModal, setShowItemModal] = useState(false);
  const [showShoppingTripModal, setShowShoppingTripModal] = useState(false);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [showItemCategoryModal, setShowItemCategoryModal] = useState(false);
  const [showUnitModal, setShowUnitModal] = useState(false);

  let history = useHistory();

  const showCreateItemModal = () => {
    setShowItemModal(!showItemModal);
  };

  const showCreateShoppingTripModal = () => {
    setShowShoppingTripModal(!showShoppingTripModal);
  };

  const showCreateStoreModal = () => {
    setShowStoreModal(!showStoreModal);
  };

  const showCreateItemCategoryModal = () => {
    setShowItemCategoryModal(!showItemCategoryModal);
  };

  const showCreateUnitModal = () => {
    setShowUnitModal(!showUnitModal);
  };

  const onClicked = () => {
    //console.log("clicked!");
    //console.log(source);
    switch (source) {
      case "Items":
        showCreateItemModal();
        break;
      case "ShoppingTrips":
        //showCreateShoppingTripModal();
        history.push("/shoppingtrip/new");
        break;
      case "Stores":
        showCreateStoreModal();
        break;
      case "ItemCategories":
        showCreateItemCategoryModal();
        break;
      case "Units":
        showCreateUnitModal();
        break;
      case "ShoppingLists":
        history.push("/shoppinglist/new");
        break;
      default:
        break;
    }
  };

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <CreateItemModal
        show={showItemModal}
        onCreated={() => {}}
        partialName=""
      ></CreateItemModal>
      <CreateShoppingTripModal show={showShoppingTripModal} />
      <CreateStoreModal show={showStoreModal} onCreated={() => {}} />
      <CreateItemCategoryModal
        show={showItemCategoryModal}
        onCreated={() => {}}
      />
      <CreateUnitModal show={showUnitModal} onCreated={() => {}} />
      <IonFabButton color="primary" onClick={onClicked}>
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>
  );
};
