import {
  IonContent,
  IonTitle,
  IonButton,
  IonItem,
  IonIcon,
  IonInput,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import ItemCategoryList from "../itemCategories/ItemCategoryList";
import CreateItemCategoryModal from "../itemCategories/CreateItemCategoryModal";
import SelectDefaultItemCategoryModal from "../itemCategories/SelectDefaultItemCategoryModal";
import {
  loadItemData,
  addNewItemCategory,
} from "../../data/items/items.actions";
import { ItemCategory } from "../../models/Item";
import { addOutline, saveOutline } from "ionicons/icons";

interface OwnProps {}

interface StateProps {
  itemCategories: ItemCategory[];
}

interface DispatchProps {
  addNewItemCategory: typeof addNewItemCategory;
}

type ItemCategoryContainerProps = OwnProps & StateProps & DispatchProps;

const ItemCategoryContainer: React.FC<ItemCategoryContainerProps> = ({
  itemCategories,
  addNewItemCategory,
}) => {
  useEffect(() => {
    loadItemData();
    // eslint-disable-next-line
  }, []);

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarItemCategory");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreateLine, setShowCreateLine] = useState(false);
  const [showSelectDefaultModal, setShowSelectDefaultModal] = useState(false);

  const [itemCategory, setItemCategory] = useState<ItemCategory>({
    _id: "",
    userId: "",
    name: "",
  });

  const name = itemCategory.name;

  const onChange = (fieldName: string, fieldValue: string) => {
    setItemCategory({ ...itemCategory, [fieldName]: fieldValue });
  };

  const onChanged = (itemCategories: ItemCategory[]) => {};

  const insertItemCategory = async () => {
    //console.log(name);
    //console.log(unit);
    await addNewItemCategory(itemCategory);
    setItemCategory({ _id: "", userId: "", name: "" });
  };

  /*const showCreateItemCategoryModal = () => {
    setShowCreateModal(!showCreateModal);
  };*/

  const showSelectDefaultItemCategoryModal = () => {
    setShowSelectDefaultModal(!showSelectDefaultModal);
  };

  const showCreateUnitLine = () => {
    setShowCreateLine(!showCreateLine);
  };

  return (
    <IonContent class="scrollbarItemCategory">
      <IonTitle>Item categories</IonTitle>
      <IonItem>
        <IonButton
          size="small"
          shape="round"
          onClick={() => showSelectDefaultItemCategoryModal()}
        >
          Add default item categories
        </IonButton>
        <SelectDefaultItemCategoryModal
          onChanged={onChanged}
          slot={""}
          show={showSelectDefaultModal}
        />
      </IonItem>
      <ItemCategoryList
        itemCategories={itemCategories}
        listType="all"
        hide={false}
      />
      <IonItem hidden={!showCreateLine}>
        <IonInput
          value={name}
          placeholder="Enter the item category name"
          onIonChange={(e) => onChange("name", e.detail.value!)}
        ></IonInput>
        <IonIcon
          icon={saveOutline}
          color="primary"
          onClick={insertItemCategory}
        />
      </IonItem>
      <IonItem onClick={showCreateUnitLine}>
        <IonIcon icon={addOutline} /> Add a new item category
      </IonItem>
      <CreateItemCategoryModal
        show={showCreateModal}
        onCreated={() => {}}
      ></CreateItemCategoryModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    itemCategories: state.data.itemCategories,
  }),
  mapDispatchToProps: { addNewItemCategory },
  component: React.memo(ItemCategoryContainer),
});
