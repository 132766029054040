import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import ItemShopCompareList from "./ItemShopCompareList";
import { Item } from "../../models/Item";
import ItemChartsDashboard from "../charts/ItemChartsDashboard";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface StateProps {
  items: Item[];
}

interface ItemShopComparePageProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const ItemShopComparePage: React.FC<ItemShopComparePageProps> = ({ items }) => {
  var { itemid } = useParams<{ itemid: string }>();

  const [item, setItem] = useState<Item>({
    _id: "",
    userId: "",
    name: "",
    defaultUnitId: {
      _id: "",
      name: "",
      symbol: "",
    },
    itemCategories: [],
    avePrice: 0,
    lastPrice: 0,
    storeLastPrice: [],
  });

  const main = async () => {
    const content = document.querySelector(
      "ion-content.scrollbarItemShopCompare"
    );
    const styles = document.createElement("style");

    styles.textContent = `
          ::-webkit-scrollbar {
            width: 5px;
          }
      
          /* Track */
          ::-webkit-scrollbar-track {
            box-shadow: 0 0 5px grey; 
            border-radius: 10px;
          }
      
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: lightgrey; 
            border-radius: 10px;
          }
      
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #63e0ff; 
          }
        `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      const filteredItems = items.filter((item) => item._id === itemid);
      if (filteredItems.length > 0) {
        setItem(filteredItems[0]);
      }
    })();
  }, [itemid]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Item Stats</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="scrollbarItemShopCompare">
        <IonTitle>Compare prices</IonTitle>
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="3">Store</IonCol>
              <IonCol size="3">Average Price</IonCol>
              <IonCol size="3">Last Price</IonCol>
            </IonRow>
            <ItemShopCompareList
              //shoppingItems={shoppingItems}
              item={item}
              hide={false}
            />
          </IonGrid>
        </IonItem>
        <ItemChartsDashboard item={item} />
        <IonItem>
          <IonButton
            routerLink={`/page/Items`}
            routerDirection="none"
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    items: state.data.items,
  }),
  mapDispatchToProps: {},
  component: ItemShopComparePage,
});
