import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
  IonDatetime,
  IonCheckbox,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { connect } from "../../data/connect";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import { ShoppingList, ShoppingListItem } from "../../models/ShoppingList";
import { Store } from "../../models/Store";
import SelectStoreModal from "../stores/SelectStoreModal";
import {
  //addNewShoppingTrip,
  //addNewShoppingTripItem,
  convertShoppingListToTrip,
} from "../../data/shoppingTrips/shoppingTrips.actions";
import {
  getShoppingListItems,
  editShoppingList,
} from "../../data/shoppingLists/shoppingLists.actions";
import DatePickerModal from "../layout/DatePickerModal";
import moment from "moment";
//import { wait } from "@testing-library/dom";

interface OwnProps {
  show: boolean;
  shoppingList: ShoppingList;
}

interface StateProps {
  shoppingListItems: ShoppingListItem[];
  //shoppingTrips: ShoppingTrip[];
  lastCreatedId: string;
}

interface DispatchProps {
  //addNewShoppingTrip: typeof addNewShoppingTrip;
  //addNewShoppingTripItem: typeof addNewShoppingTripItem;
  convertShoppingListToTrip: typeof convertShoppingListToTrip;
  getShoppingListItems: typeof getShoppingListItems;
  editShoppingList: typeof editShoppingList;
}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type ConvertShoppingListToTripProps = OwnProps & StateProps & DispatchProps;

export const ConvertShoppingListToTripModal: React.FC<ConvertShoppingListToTripProps> =
  ({
    show,
    shoppingList,
    shoppingListItems,
    //shoppingTrips,
    lastCreatedId,
    //addNewShoppingTrip,
    //addNewShoppingTripItem,
    convertShoppingListToTrip,
    getShoppingListItems,
    editShoppingList,
  }) => {
    //const [showModal, setShowModal] = useState(false);
    const [shoppingTrip, setShoppingTrip] = useState<ShoppingTrip>({
      _id: "",
      userId: "",
      storeId: {
        _id: "",
        userId: "",
        name: "",
        url: "",
        totalTrips: 0,
        totalSpent: 0,
      },
      date: shoppingList.completedDate,
      notes: shoppingList.notes,
      shoppingTripItems: [],
      totalLines: 0,
      totalAmount: 0,
    });
    /*const [shoppingItem, setShoppingItem] = useState<ShoppingItem>({
    _id: "",
    shoppingTripId: "",
    itemId: {
      _id: "",
      userId: "",
      name: "",
      defaultUnitId: {
        _id: "",
        name: "",
        symbol: "",
      },
      itemCategories: [],
      avePrice: 0,
      lastPrice: 0,
      storeLastPrice: [],
    },
    unitId: {
      _id: "",
      name: "",
      symbol: "",
    },
    qty: 0,
    price: 0,
    notes: "",
  });*/

    //const id = item.id;
    const date = shoppingTrip.date;
    const notes = shoppingTrip.notes;

    const [showModal, setShowModal] = useState(show);
    const [showToastCreated, setShowToastCreated] = useState(false);
    const [showDatePickerModal, setShowDatePickerModal] = useState(false);
    const [populatePrices, setPopulatePrices] = useState(false);
    const [createOnlyCompleted, setCreateOnlyCompleted] = useState(true);
    const [waiting, setWaiting] = useState(false);

    //const oldLastCreatedId = lastCreatedId;

    let history = useHistory();

    useEffect(() => {
      setShowModal(show);

      if (waiting) {
        setWaiting(false);
        history.push(`/shoppingtrip/${lastCreatedId}`);
      }

      (async function loadData() {
        await getShoppingListItems(shoppingList);
      })();

      setShoppingTrip({
        _id: "",
        userId: "",
        storeId: {
          _id: "",
          userId: "",
          name: "",
          url: "",
          totalTrips: 0,
          totalSpent: 0,
        },
        date: shoppingList.completedDate,
        notes: shoppingList.notes,
        shoppingTripItems: [],
        totalLines: 0,
        totalAmount: 0,
      });
    }, [show, lastCreatedId, shoppingList]);

    const onChange = (fieldName: string, fieldValue: string) => {
      setShoppingTrip({ ...shoppingTrip, [fieldName]: fieldValue });
    };

    const onChangeStore = (fieldValue: Store) => {
      setShoppingTrip({ ...shoppingTrip, storeId: fieldValue });
    };

    const onChangeDatePicker = (dateValue: string) => {
      onChange("date", dateValue);
      setShowDatePickerModal(false);
    };

    const onChangePopulatePrices = (value: boolean) => {
      setPopulatePrices(value);
    };

    const onChangeCreateOnlyCompleted = (value: boolean) => {
      setCreateOnlyCompleted(value);
    };

    const insertShoppingTrip = async () => {
      //await addNewShoppingTrip(shoppingTrip);
      setWaiting(true);
      //console.log(oldLastCreatedId);
      await convertShoppingListToTrip(
        shoppingTrip,
        shoppingListItems,
        populatePrices,
        createOnlyCompleted
      );
      //await insertLines();
      shoppingList.completed = true;
      if (!shoppingList.completedDate) {
        shoppingList.completedDate = shoppingTrip.date;
      }
      await editShoppingList(shoppingList);

      /*while (oldLastCreatedId === lastCreatedId) {
      console.log(`${oldLastCreatedId} - ${lastCreatedId}`)
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    history.push(`/shoppingtrip/${lastCreatedId}`);*/
      setShowToastCreated(true);
    };

    return (
      <IonContent>
        <IonModal isOpen={showModal} id="convert-shoppingList-to-trip-modal">
          <p>Please enter the details of this shopping trip</p>
          <IonItem onClick={() => setShowDatePickerModal(true)}>
            <IonLabel>Date:</IonLabel>
            <IonDatetime
              displayFormat="DD MMM YYYY"
              value={date}
              placeholder="Enter the date it took place"
              onIonChange={(e) => onChange("date", e.detail.value!)}
              slot="end"
              readonly={true}
            ></IonDatetime>
          </IonItem>
          <IonItem>
            <IonLabel>Store:</IonLabel>
            <SelectStoreModal
              store={shoppingTrip.storeId}
              onChanged={onChangeStore}
              slot="end"
            />
          </IonItem>
          <IonItem>
            <IonLabel> Notes:</IonLabel>
            <IonInput
              value={notes}
              placeholder="Enter your notes"
              onIonChange={(e) => onChange("notes", e.detail.value!)}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>
              Use last prices for the items (You can always change it later):
            </IonLabel>
            <IonCheckbox
              checked={populatePrices}
              onIonChange={(e) => onChangePopulatePrices(e.detail.checked)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Create only completed items:</IonLabel>
            <IonCheckbox
              checked={createOnlyCompleted}
              onIonChange={(e) => onChangeCreateOnlyCompleted(e.detail.checked)}
            />
          </IonItem>
          <IonItem>
            <IonButton onClick={insertShoppingTrip} slot="end">
              Create Shopping Trip
            </IonButton>
            <IonButton onClick={() => setShowModal(false)} slot="end">
              Close
            </IonButton>
          </IonItem>
          <IonToast
            isOpen={showToastCreated}
            onDidDismiss={() => {
              setShowToastCreated(false);
              //history.push(`/page/shoppingtrips/`);
              //history.push(`/shoppingtrip/${lastCreatedId}`);
              //setWaiting(true);
            }}
            message="Your shopping trip is being created. Please wait..."
            duration={1500}
            color="primary"
          />
          <DatePickerModal
            show={showDatePickerModal}
            date={
              date !== ""
                ? moment(date).format("DD MMM YYYY")
                : moment(new Date()).format("DD MMM YYYY")
            }
            onSelected={onChangeDatePicker}
          />
        </IonModal>
      </IonContent>
    );
  };

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingListItems: state.shoppingListData.shoppingListItems,
    //shoppingTrips: state.shoppingTripData.shoppingTrips,
    lastCreatedId: state.shoppingTripData.lastCreatedId,
  }),
  mapDispatchToProps: {
    //addNewShoppingTrip,
    //addNewShoppingTripItem,
    convertShoppingListToTrip,
    getShoppingListItems,
    editShoppingList,
  },
  component: ConvertShoppingListToTripModal,
});
