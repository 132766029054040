import { getShoppingListData } from "../dataApi";
import { ActionType } from "../../utils/types";
import { ShoppingListsState } from "./shoppingLists.state";
import {
  ShoppingList,
  ShoppingListItem,
  ShoppingCompare,
  ShoppingCompareItemModel,
} from "../../models/ShoppingList";
import axios from "axios";
import React from "react";

export const loadShoppingListData = () => async (
  dispatch: React.Dispatch<any>
) => {
  dispatch(setLoading(true));
  const data = await getShoppingListData();
  dispatch(setData(data));
  dispatch(setLoading(false));
};

export const loadShoppingListItemData = async (data: ShoppingList) => async (
  dispatch: React.Dispatch<any>
) => {
  await getShoppingListItems(data);
};

export const addNewShoppingItem = (
  shoppingList: ShoppingList,
  shoppingListItem: ShoppingListItem
) => async (dispatch: React.Dispatch<any>) => {
  try {
    if (shoppingListItem.unitId.symbol === "") {
      shoppingListItem.unitId = shoppingListItem.itemId.defaultUnitId;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      "/api/shoppinglistitems",
      shoppingListItem,
      config
    );
    const result = await res.data; //json();
    //console.log(result);

    dispatch(addShoppingListItem(result));
    //shoppingList.shoppingListItems.push(result);
    //console.log(shoppingList);
    dispatch(editShoppingList(shoppingList));
  } catch (error) {
    console.log("Error in shoppingLists.actions -> addNewShoppingListItem");
    //dispatch(loginFailure(error));
  }
};

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-shoppingLists-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<ShoppingListsState>) =>
  ({
    type: "set-shoppingLists-data",
    data,
  } as const);

export const getShoppingListItems = (data: ShoppingList) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.trace();
    //console.info(data);
    if (data._id !== "") {
      const res = await axios.get(`/api/shoppinglistitems/${data._id}`);
      const result = await res.data; //json();

      //console.log(result);

      dispatch(getShoppingListItem(result));
    }    
  } catch {
    console.log("Error in shoppingLists.actions -> getShoppingListItems");
  }
};

export const addNewShoppingList = (data: ShoppingList) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/shoppinglists", data, config);
    const result = await res.data; //json();

    dispatch(addShoppingList(result));
  } catch {
    console.log("Error in shoppingLists.actions -> addNewShoppingList");
  }
};

export const addNewShoppingListItem = (data: ShoppingListItem) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    if (data.unitId.symbol === "") {
      data.unitId = data.itemId.defaultUnitId;
    }

    //console.info(data);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/shoppinglistitems", data, config);
    const result = await res.data; //json();

    dispatch(addShoppingListItem(result));
  } catch {
    console.log("Error in shoppingLists.actions -> addNewShoppingListItem");
  }
};

export const editShoppingList = (data: ShoppingList) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(`/api/shoppingLists/${data._id}`, data, config);
    const result = await res.data;
    //console.info(result);

    dispatch(updateShoppingList(result));
  } catch {
    console.log("Error in shoppingLists.actions -> editShoppingList");
  }
};

export const editShoppingListItem = (data: ShoppingListItem) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    if (data.unitId.symbol !== data.itemId.defaultUnitId.symbol) {
      data.unitId = data.itemId.defaultUnitId;
    }
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log(data);

    const res = await axios.put(
      `/api/shoppinglistitems/${data._id}`,
      data,
      config
    );
    const result = await res.data;
    //console.info(result);

    dispatch(updateShoppingListItem(result));
  } catch {
    console.log("Error in shoppingLists.actions -> editShoppingList");
  }
};

export const removeShoppingList = (data: ShoppingList) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.delete(`/api/shoppingLists/${data._id}`, config);
    const result = await res.data;
    //console.info(result);

    dispatch(deleteShoppingList(data));
  } catch {
    console.log("Error in shoppingLists.actions -> editShoppingList");
  }
};

export const removeShoppingListItem = (data: ShoppingListItem) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.delete(
      `/api/shoppinglistitems/${data._id}`,
      config
    );
    const result = await res.data;
    //console.info(result);

    dispatch(deleteShoppingListItem(data));
  } catch {
    console.log("Error in shoppingLists.actions -> removeShoppingListItem");
  }
};

export const getShoppingCompares = (data: ShoppingList) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);

    const res = await axios.get(`/api/shoppingcompares/${data._id}`);
    const result = await res.data;

    //console.log(result);

    dispatch(getShoppingCompareList(result));
  } catch (e) {
    console.log(e.response.data);
    console.log("Error in shoppingLists.actions -> getShoppingCompares");
  }
};

export const getShoppingCompareItems = (data: ShoppingList) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);

    const res = await axios.get(`/api/shoppingcompareitems/${data._id}`);
    const result = await res.data;

    //console.log(result);

    dispatch(getShoppingCompareItemList(result));
  } catch (e) {
    console.log(e.response.data);
    console.log("Error in shoppingLists.actions -> getShoppingCompareItems");
  }
};

export const setLoadingCompare = (loading: boolean) => async(
  dispatch: React.Dispatch<any>
) => {
  dispatch(setLoadingCompareValue(loading));
}

export const getShoppingList = (data: ShoppingList) =>
  ({
    type: "get-shoppingList",
    data,
  } as const);

export const setCurrentShoppingList = (data: ShoppingList) =>
  ({
    type: "set-current-shoppingList",
    data,
  } as const);

export const addShoppingList = (data: ShoppingList) =>
  ({
    type: "add-shoppingList",
    data,
  } as const);

export const updateShoppingList = (data: ShoppingList) =>
  ({
    type: "update-shoppingList",
    data,
  } as const);

export const deleteShoppingList = (data: ShoppingList) =>
  ({
    type: "delete-shoppingList",
    data,
  } as const);

export const getShoppingListItem = (data: ShoppingListItem[]) =>
  ({
    type: "get-shoppingListItem",
    data,
  } as const);

export const addShoppingListItem = (data: ShoppingListItem) =>
  ({
    type: "add-shoppingListItem",
    data,
  } as const);

export const updateShoppingListItem = (data: ShoppingListItem) =>
  ({
    type: "update-shoppingListItem",
    data,
  } as const);

export const deleteShoppingListItem = (data: ShoppingListItem) =>
  ({
    type: "delete-shoppingListItem",
    data,
  } as const);

export const getShoppingCompareList = (data: ShoppingCompare[]) =>
  ({
    type: "get-shoppingCompareList",
    data,
  } as const);

export const getShoppingCompareItemList = (data: ShoppingCompareItemModel[]) =>
  ({
    type: "get-shoppingCompareItemList",
    data,
  } as const);

export const updateFilteredLists = (filteredLists: string[]) =>
  ({
    type: "update-filtered-lists",
    filteredLists,
  } as const);

export const setSearchText = (searchText?: string) =>
  ({
    type: "set-search-text",
    searchText,
  } as const);

  export const setLoadingCompareValue = (loading?: boolean) =>
  ({
    type: "set-loading-compare",
    loading,
  } as const);

export type ShoppingListsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof getShoppingList>
  | ActionType<typeof setCurrentShoppingList>
  | ActionType<typeof addShoppingList>
  | ActionType<typeof updateShoppingList>
  | ActionType<typeof deleteShoppingList>
  | ActionType<typeof getShoppingListItem>
  | ActionType<typeof addShoppingListItem>
  | ActionType<typeof updateShoppingListItem>
  | ActionType<typeof deleteShoppingListItem>
  | ActionType<typeof getShoppingCompareList>
  | ActionType<typeof getShoppingCompareItemList>
  | ActionType<typeof updateFilteredLists>
  | ActionType<typeof setSearchText>
  | ActionType<typeof setLoadingCompareValue>;
