import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { IonButton, IonItem, IonTitle } from "@ionic/react";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import { Store } from "../../models/Store";
import { ChartDataset } from "../../models/ChartObjects";
import { connect } from "../../data/connect";
import { Bar } from "react-chartjs-2";

interface OwnProps {
  selectionStart: String;
  selectionEnd: String;
}

interface StateProps {
  shoppingTrips: ShoppingTrip[];
  stores: Store[];
}

interface DispatchProps {}

interface ShoppingTripsChartProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingTripsChart: React.FC<ShoppingTripsChartProps> = ({
  selectionStart,
  selectionEnd,
  shoppingTrips,
  stores,
}) => {
  const [labels, setLabels] = useState<String[]>([]);
  const [datasets, setDatasets] = useState<ChartDataset[]>([]);
  const chartRef = useRef<Bar>(null);

  var data = [] as Number[];

  useEffect(() => {
    getData();
  }, [shoppingTrips, selectionStart, selectionEnd]);

  useLayoutEffect(() => {
    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.chartInstance.update();
    }
  }, [datasets, labels]);

  var dynamicColors = function (alpha: number) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    //var a = Math.random() * 1;
    return "rgb(" + r + "," + g + "," + b + ", " + alpha + ")";
  };

  const getData = async () => {
    var dateFilteredTrips = shoppingTrips.filter(
      (trip) =>
        trip.date.substring(0, 10) >= selectionStart.substring(0, 10) &&
        trip.date.substring(0, 10) <= selectionEnd.substring(0, 10)
    );

    dateFilteredTrips.forEach((shoppingTrip) => {
      if (!labels.includes(shoppingTrip.date.substring(0, 10))) {
        labels.push(shoppingTrip.date.substring(0, 10));
      }
    });

    labels.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }

      if (n1 < n2) {
        return -1;
      }

      return 0;
    });

    if (labels.length >= 0) {
      setLabels(
        labels.filter(
          (label) =>
            label >= selectionStart.substring(0, 10) &&
            label <= selectionEnd.substring(0, 10)
        )
      );
    }

    stores.forEach((store) => {
      data = [];
      var filterLabels = labels.filter(
        (label) =>
          label >= selectionStart.substring(0, 10) &&
          label <= selectionEnd.substring(0, 10)
      );
      filterLabels.forEach((date) => {
        var filteredTrips = shoppingTrips.filter(
          (shoppingTrip) =>
            shoppingTrip.storeId._id === store._id &&
            shoppingTrip.date.substring(0, 10) === date
        );

        if (filteredTrips.length > 0) {
          filteredTrips.forEach((trip) => {
            data.push(Number(trip.totalAmount.toFixed(2)));
          });
        } else {
          data.push(0);
        }
      });
      var colour = dynamicColors(0.2);
      var dataSet = {
        label: store.name,
        backgroundColor: colour, //"rgba(255,99,132,0.2)",
        borderColor: colour.replace("0.2", "1"), //"rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: colour.replace("0.2", "0.4"), //"rgba(255,99,132,0.4)",
        hoverBorderColor: colour.replace("0.2", "1"), //"rgba(255,99,132,1)",
        data: data,
      };

      var index = datasets.findIndex((ds) => ds.label === dataSet.label);
      if (index >= 0) {
        datasets[index] = dataSet;
      } else {
        datasets.push(dataSet);
      }

      data = [];
    });

    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.chartInstance.update();
    }
  };

  const dataTest = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    title: {
      text: "Shopping trips from " + selectionStart + " to " + selectionEnd,
      display: true,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          labelString: "€",
        },
      ],
    },
    animation: {
      duration: 0,
    },
  };

  return (
    <div>
      <IonItem>
        <IonTitle>Shopping trips</IonTitle>
        <IonButton size="small" shape="round" onClick={() => getData()}>
          Change colours
        </IonButton>
      </IonItem>
      <Bar ref={chartRef} data={dataTest} options={options} redraw></Bar>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingTrips: state.shoppingTripData.shoppingTrips,
    stores: state.storeData.stores,
  }),
  mapDispatchToProps: {},
  component: React.memo(ShoppingTripsChart),
});
