import React, { useState } from "react";
import { IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { connect } from "../../data/connect";
import { ItemCategory } from "../../models/Item";
import CreateItemCategoryModal from "./CreateItemCategoryModal";
import "../../theme/styling.scss";

interface OwnProps {
  itemItemCategories: ItemCategory[];
  onChanged: (itemItemCategories: ItemCategory[]) => void;
  slot?: string;
}

interface StateProps {
  itemCategories: ItemCategory[];
}

interface DispatchProps {}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type SelectItemCategoryProps = OwnProps & StateProps & DispatchProps;

export const SelectItemCategoryModal: React.FC<SelectItemCategoryProps> = ({
  itemItemCategories,
  onChanged,
  slot,
  itemCategories,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [addNewItemCtegoryOption, setAddNewItemCategoryOption] =
    useState<ItemCategory>({
      _id: "newItemCategory",
      userId: "",
      name: "newItemCategory",
    });

  /*const options = {
    cssClass: "select-interface", //"select-interface",
  };*/

  const itemCategoryCompareWith = (o1: ItemCategory, o2: ItemCategory) => {
    return o1 && o2 ? o1._id === o2._id : o1 === o2;
  };

  const onChange = (fieldValue: ItemCategory[]) => {
    //console.log(fieldValue);
    //console.log(addNewItemCtegoryOption);
    if (fieldValue.includes(addNewItemCtegoryOption)) {
      setShowCreateCategoryModal(true);
    } else {
      onChanged(fieldValue);
    }
  };

  const onCreate = (newItemCategory: ItemCategory) => {
    //console.log(newItemCategory)
    //const filteredItems = items.filter((item) => item.name === newItem.name)
    //console.log(filteredItems[0])
    if (newItemCategory !== undefined) {
      //console.log("Here")
      onChange([newItemCategory]);
    }
    setShowCreateCategoryModal(false);
  };

  const setShowCreateCategoryModal = (showModal: boolean) => {
    setShowCreateModal(showModal);
  };

  return (
    <>
      <IonSelect
        //id="newItemLine-select-itemCategory"
        multiple={true}
        placeholder="Item categories"
        compareWith={itemCategoryCompareWith}
        value={itemItemCategories}
        onIonChange={(e) => onChange(e.detail.value)}
        //className="select-interface"
        //interface="alert"
        //interfaceOptions={options}
        slot={slot}
      >
        {itemCategories
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((itemCategory) => (
            <IonSelectOption
              key={itemCategory._id}
              value={itemCategory}
              //className="select-interface.select-interface-option"
            >
              <IonLabel>{itemCategory.name}</IonLabel>
            </IonSelectOption>
          ))}
        <IonSelectOption
          key="newItemCategory"
          value={addNewItemCtegoryOption}
          //className="select-interface-option"
        >
          ...Add new item category
        </IonSelectOption>
        <CreateItemCategoryModal
          show={showCreateModal}
          onCreated={onCreate}
        ></CreateItemCategoryModal>
      </IonSelect>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    itemCategories: state.data.itemCategories,
  }),
  mapDispatchToProps: {},
  component: SelectItemCategoryModal,
});
