import React, { useState, useEffect } from "react";
import {
  IonRow,
  IonItemGroup,
  IonAlert,
  IonIcon,
  IonCol,
  IonCheckbox,
  //IonDatetime,
} from "@ionic/react";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { ShoppingList, ShoppingListItem } from "../../models/ShoppingList";
import EditShoppingListItemModal from "./EditShoppingListItemModal";
import {
  editShoppingList,
  editShoppingListItem,
  removeShoppingListItem,
} from "../../data/shoppingLists/shoppingLists.actions";
import { connect } from "../../data/connect";

interface OwnProps {
  shoppingListItem: ShoppingListItem;
  shoppingList: ShoppingList;
}

interface StateProps {}

interface DispatchProps {
  editShoppingListItem: typeof editShoppingListItem;
  removeShoppingListItem: typeof removeShoppingListItem;
}

interface ShoppingItemItemProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingItemItem: React.FC<ShoppingItemItemProps> = ({
  shoppingListItem,
  shoppingList,
  editShoppingListItem,
  removeShoppingListItem,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [modifiedShoppingListItem, setModifiedShoppingListItem] =
    useState<ShoppingListItem>(shoppingListItem);

  useEffect(() => {
    editShoppingListItem(modifiedShoppingListItem);
  }, [modifiedShoppingListItem.completed]);

  const showEditShoppingItemModal = () => {
    setShowEditModal(!showEditModal);
  };

  const onChangeCompleted = async (value: boolean) => {
    await setModifiedShoppingListItem({
      ...modifiedShoppingListItem,
      completed: value,
    });
  };

  const onChangeShoppingItem = (fieldValue: ShoppingListItem) => {
    editShoppingList(shoppingList);
  };

  const deleteShoppingListItem = async () => {
    await removeShoppingListItem(shoppingListItem);
  };

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="2" sizeSm="2">
          <IonCheckbox
            checked={modifiedShoppingListItem.completed}
            onIonChange={(e) => onChangeCompleted(e.detail.checked)}
          />
        </IonCol>
        <IonCol size="5" sizeSm="3" onClick={showEditShoppingItemModal}>
          {shoppingListItem.itemId.name}
        </IonCol>
        <IonCol size="2" sizeSm="2">
          {shoppingListItem.unitId.symbol}
        </IonCol>
        <IonCol size="2" sizeSm="1">
          {shoppingListItem.qty}
        </IonCol>
        <IonCol sizeSm="3" className="ion-hide-sm-down">
          {shoppingListItem.notes}
        </IonCol>
        <IonCol>
          <IonIcon
            icon={pencilOutline}
            color="tertiary"
            onClick={() => showEditShoppingItemModal()}
          />
          <IonIcon
            icon={trashOutline}
            color="tertiary"
            onClick={() => setShowDeleteAlert(true)}
          />
        </IonCol>
      </IonRow>
      <EditShoppingListItemModal
        shoppingListItem={shoppingListItem}
        shoppingList={shoppingList}
        onChanged={onChangeShoppingItem}
        show={showEditModal}
      />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: async () => {
              await deleteShoppingListItem(); //removeShoppingItem(shoppingItem);
              await editShoppingList(shoppingList);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    editShoppingListItem,
    removeShoppingListItem,
  },
  component: React.memo(ShoppingItemItem),
});
