import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonItem,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  //IonCheckbox
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingList, ShoppingListItem } from "../../models/ShoppingList";
import {
  editShoppingList,
  getShoppingListItems,
} from "../../data/shoppingLists/shoppingLists.actions";
import ShoppingListItemList from "./ShoppingListItemList";
import NewShoppingListItemLine from "./NewShoppingListItemLine";
import ConvertShoppingListToTripModal from "../shoppingLists/ConvertShoppingListToTripModal";

interface OwnProps {
  show: boolean;
  shoppingList: ShoppingList;
}

interface StateProps {
  shoppingListItems: ShoppingListItem[];
}

interface DispatchProps {
  editShoppingList: typeof editShoppingList;
  getShoppingListItems: typeof getShoppingListItems;
}

type ShoppingListItemProps = OwnProps & StateProps & DispatchProps;

export const ShoppingListItemModal: React.FC<ShoppingListItemProps> = ({
  show,
  shoppingList,
  shoppingListItems,
  editShoppingList,
  getShoppingListItems,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedShoppingList, setModifiedShoppingList] = useState<
    ShoppingList
  >(shoppingList);

  //const date = modifiedShoppingList.completedDate;
  //const notes = modifiedShoppingList.notes;

  const [showModal, setShowModal] = useState(show);
  const [showConvertModal, setShowConvertModal] = useState(false);
  //const [closeModal, setCloseModal] = useState(false);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    //if (!closeModal) {
    setShowModal(show);
    //}

    (async function loadData() {
      await getShoppingListItems(shoppingList);
    })();
  }, [show, shoppingList]);

  /*const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedShoppingList({
      ...modifiedShoppingList,
      [fieldName]: fieldValue,
    });
  };*/

  const editCurrentShoppingList = async () => {
    editShoppingList(modifiedShoppingList);
    setShowToastCreated(true);
  };

  const createShoppingTrip = async () => {
    //editShoppingList(modifiedShoppingList);
    setShowConvertModal(!showConvertModal);
    //setShowToastCreated(true);
  };

  /*const closeModal = async () => {
    await editCurrentShoppingList();
    //await editShoppingList(modifiedShoppingList);
    setShowModal(false);
  };*/

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="shoppingListItem-modal">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="2" sizeSm="2"></IonCol>
              <IonCol size="5" sizeSm="3">Item</IonCol>
              <IonCol size="2" sizeSm="2">Unit</IonCol>
              <IonCol size="2" sizeSm="1">Qty</IonCol>              
              <IonCol sizeSm="3" className="ion-hide-sm-down">Notes</IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <ShoppingListItemList
              //shoppingItems={shoppingItems}
              shoppingList={shoppingList}
              hide={false}
            />
            <IonRow>
              <NewShoppingListItemLine shoppingList={shoppingList} />
            </IonRow> 
            <IonRow>
              <IonCol size="5">
                <IonButton onClick={createShoppingTrip} slot="end">
                  Create Shopping Trip
                </IonButton>
              </IonCol>
              <IonCol size="4">
                <IonButton onClick={editCurrentShoppingList} slot="end">
                  Update Shopping List
                </IonButton>
              </IonCol>
              <IonCol size="3">
                <IonButton
                  onClick={() => {
                    setShowModal(false);
                  }}
                  slot="end"
                >
                  Close
                </IonButton>
              </IonCol>
            </IonRow>           
          </IonGrid>
        </IonItem>
        <IonItem className="ion-hide-sm-down">
          <IonButton onClick={createShoppingTrip} slot="end">
            Create Shopping Trip
          </IonButton>
          <IonButton onClick={editCurrentShoppingList} slot="end">
            Update Shopping List
          </IonButton>
          <IonButton
            onClick={() => {
              //setCloseModal(true);
              //editShoppingList(modifiedShoppingList);
              setShowModal(false);
            }}
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>  
        <IonItem className="ion-hide-sm-up">
          <IonButton onClick={createShoppingTrip}>
            Create Shopping Trip
          </IonButton>
          <IonButton onClick={editCurrentShoppingList}>
            Update Shopping List
          </IonButton>
          <IonButton
            onClick={() => {
              //setCloseModal(true);
              //editShoppingList(modifiedShoppingList);
              setShowModal(false);
            }}
          >
            Close
          </IonButton>
        </IonItem> 
        <ConvertShoppingListToTripModal
          shoppingList={shoppingList}
          show={showConvertModal}
        ></ConvertShoppingListToTripModal>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your shopping list has been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingListItems: state.shoppingListData.shoppingListItems,
  }),
  mapDispatchToProps: { editShoppingList, getShoppingListItems },
  component: ShoppingListItemModal,
});
