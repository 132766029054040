import React, { useEffect } from "react";
import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import "./ExploreContainer.css";
import { connect } from "../../data/connect";
import { ShoppingList } from "../../models/ShoppingList";
import ShoppingListList from "../shoppingLists/ShoppingListList";
import NewShoppingListLine from "../shoppingLists/NewShoppingListLine";

interface OwnProps {}

interface StateProps {
  shoppingLists: ShoppingList[];
}

interface DispatchProps {}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingListsContainer: React.FC<ContainerProps> = ({
  shoppingLists,
}) => {
  useEffect(() => {
    //console.log("Here");
    //getLogs();
    // eslint-disable-next-line
  }, []);

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarShoppingList");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  return (
    <IonContent class="scrollbarShoppingList">
      <IonGrid>
        <IonRow>
          <IonCol sizeSm="2" className="ion-hide-sm-down">
            Created date
          </IonCol>
          <IonCol size="5" sizeSm="2" className="ion-hide-sm-down">
            Completed date
          </IonCol>
          <IonCol size="3" sizeSm="2">
            Completed
          </IonCol>
          <IonCol size="2" sizeSm="1" className="ion-hide-sm-down">
            Total items
          </IonCol>
          <IonCol size="7" sizeSm="4">
            Name
          </IonCol>
          <IonCol size="1"></IonCol>
        </IonRow>
        <ShoppingListList shoppingLists={shoppingLists} hide={false} />
        <NewShoppingListLine />
      </IonGrid>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingLists: state.shoppingListData.shoppingLists,
  }),
  mapDispatchToProps: {},
  component: ShoppingListsContainer,
});
