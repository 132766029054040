import { useState /*useEffect*/ } from "react";
import { useCamera } from "@ionic/react-hooks/camera";
import {
  /*useFilesystem,*/ base64FromPath,
} from "@ionic/react-hooks/filesystem";
//import { useStorage } from '@ionic/react-hooks/storage';
//import { isPlatform } from '@ionic/react';
import {
  CameraResultType,
  CameraSource /*, CameraPhoto, Capacitor, FilesystemDirectory*/,
} from "@capacitor/core";

export interface Photo {
  filepath: string;
  webviewPath?: string;
  data?: string;
}

export function usePhotoGallery() {
  const { getPhoto } = useCamera();

  const [photos, setPhotos] = useState<Photo[]>([]);
  //const [photo, setPhoto] = useState<Photo>();
  //const [data, setData] = useState<string>("");

  const toDataURL = (url: string) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const getBase64 = async (url: string) => {
    const base64Data = await base64FromPath(url);

    return base64Data;
  };

  const takePhoto = async () => {
    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });

    const fileName = new Date().getTime() + ".jpeg";
    //await getBase64(cameraPhoto)
    /*if (cameraPhoto !== null) {
        let dataURL = await toDataURL(cameraPhoto.webPath? cameraPhoto.webPath: "") as string
        console.log(dataURL)
        setData(dataURL)
        console.log(data)
      }*/
    console.log(cameraPhoto);

    const newPhotos = [
      {
        filepath: fileName,
        webviewPath: cameraPhoto.webPath,
        data: cameraPhoto.base64String,
      },
      ...photos,
    ];
    setPhotos(newPhotos);

    console.log(photos);

    return {
      filepath: fileName,
      webviewPath: cameraPhoto.webPath,
      data: cameraPhoto.base64String,
    } as Photo;
  };

  //console.log(photos)

  return {
    //photo,
    photos,
    takePhoto,
    toDataURL,
    getBase64,
  };
}
