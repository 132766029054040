import { IonItemGroup, IonList, IonListHeader, IonProgressBar } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { ShoppingList, ShoppingCompare } from "../../models/ShoppingList";
import { getShoppingListItems, getShoppingCompareItems } from "../../data/shoppingLists/shoppingLists.actions";
import ShoppingCompareItem from "./ShoppingCompareItem";
import { connect } from "../../data/connect";

interface OwnProps {
  //shoppingItems: ShoppingItem[];
  shoppingList: ShoppingList;
  hide: boolean;
}

interface StateProps {
  shoppingCompares: ShoppingCompare[];
  loadingCompare?: boolean;
}

interface DispatchProps {
  getShoppingListItems: typeof getShoppingListItems;
  getShoppingCompareItems: typeof getShoppingCompareItems;
}

interface ShoppingCompareListProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ShoppingCompareList: React.FC<ShoppingCompareListProps> = ({
  //shoppingItems,
  shoppingList,
  hide,
  shoppingCompares,
  loadingCompare,
  getShoppingListItems,
  getShoppingCompareItems,
}) => {
  /*const [shoppingItemsList, setModifiedShoppingItemsList] = useState<
    ShoppingItem[]
  >(shoppingItems);*/

  useEffect(() => {
    (async function loadData() {
      if (shoppingList._id !== "") {
        //await getShoppingListItems(shoppingList);
        await getShoppingCompareItems(shoppingList);
      }      
    })();

    //setModifiedShoppingItemsList(shoppingItems);
  }, [shoppingList]); //, shoppingItems

  //console.log(shoppingItems);
  if (shoppingCompares.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Shopping Items Found</IonListHeader>
      </IonList>
    );
  }

  if (loadingCompare) {
    return (
      <IonList>
        <IonListHeader>Loading...</IonListHeader>
        <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {shoppingCompares.map((shoppingCompare) => (
          <IonItemGroup key={`group-${shoppingCompare.store}`}>
            <ShoppingCompareItem
              shoppingCompare={shoppingCompare}
              shoppingList={shoppingList}
            ></ShoppingCompareItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingCompares: state.shoppingListData.shoppingCompares,
    loadingCompare: state.shoppingListData.loadingCompare,
  }),
  mapDispatchToProps: { getShoppingListItems, getShoppingCompareItems },
  component: ShoppingCompareList,
});
