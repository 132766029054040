import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Item, Unit, ItemCategory } from "../../models/Item";
import { editItem } from "../../data/items/items.actions";
import SelectUnitModal from "../units/SelectUnitModal";
import SelectItemCategory from "../itemCategories/SelectItemCategoryModal";

interface OwnProps {
  show: boolean;
  item: Item;
}

interface StateProps {}

interface DispatchProps {
  editItem: typeof editItem;
}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type EditItemProps = OwnProps & StateProps & DispatchProps;

export const EditItemModal: React.FC<EditItemProps> = ({
  show,
  item,
  editItem,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedItem, setModifiedItem] = useState<Item>(item);

  const name = modifiedItem.name;
  const defaultUnitId = modifiedItem.defaultUnitId;
  const itemItemCategories = modifiedItem.itemCategories;
  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedItem({ ...modifiedItem, [fieldName]: fieldValue });
  };

  const onChangeUnit = (fieldValue: Unit) => {
    setModifiedItem({ ...modifiedItem, defaultUnitId: fieldValue });
  };

  const onChangeCategory = (fieldValue: ItemCategory[]) => {
    setModifiedItem({ ...modifiedItem, itemCategories: fieldValue });
  };

  const updateItem = async () => {
    await editItem(modifiedItem);
    setShowToastCreated(true);
    setTimeout(() => {
      setShowModal(false);
    }, 2000);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="edit-item-modal">
        <p>Modify item</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the item name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> Default measuring unit:</IonLabel>
          <SelectUnitModal
            unit={defaultUnitId}
            onChanged={onChangeUnit}
            slot="end"
          />
        </IonItem>
        <IonItem>
          <IonLabel>Categories: </IonLabel>
          <SelectItemCategory
            itemItemCategories={itemItemCategories}
            onChanged={onChangeCategory}
            slot="end"
          />
        </IonItem>
        <IonItem>
          <IonButton onClick={updateItem} slot="end">
            Update Item
          </IonButton>
          <IonButton
            hidden={true}
            onClick={() => setShowModal(false)}
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your item has been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { editItem },
  component: EditItemModal,
});
