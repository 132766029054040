import React, { useState } from "react";
import { IonRow, IonItemGroup, IonIcon, IonCol } from "@ionic/react";
import {
  chevronDownCircleOutline,
  chevronUpCircleOutline,
  checkmarkOutline,
  alertOutline,
} from "ionicons/icons";
import {
  ShoppingCompare,
  ShoppingList,
  ShoppingCompareItemModel,
} from "../../models/ShoppingList";
import { User } from "../../models/User"
import ShoppingCompareItemList from "../shoppingCompareItems/ShoppingCompareItemList";
import { connect } from "../../data/connect";

interface OwnProps {
  shoppingCompare: ShoppingCompare;
  shoppingList: ShoppingList;
}

interface StateProps {
  shoppingCompareItems: ShoppingCompareItemModel[];
  user: User;
}

interface DispatchProps {}

interface ShoppingCompareItemProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ShoppingCompareItem: React.FC<ShoppingCompareItemProps> = ({
  shoppingCompare,
  shoppingList,
  shoppingCompareItems,
  user,
}) => {
  const [showCompareItemsList, setShowCompareItemsList] = useState(false);

  const showShoppingCompareLines = () => {
    setShowCompareItemsList(!showCompareItemsList);
  };

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol>
          <IonIcon
            icon={
              showCompareItemsList
                ? chevronUpCircleOutline
                : chevronDownCircleOutline
            }
            onClick={() => showShoppingCompareLines()}
          />
        </IonCol>
        <IonCol size="3">{shoppingCompare.store}</IonCol>
        <IonCol size="3">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(shoppingCompare.aveCost)}
        </IonCol>
        <IonCol size="3">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(shoppingCompare.lastCost)}
        </IonCol>
        <IonCol>
          {shoppingCompareItems.filter(
            (compareItem) =>
              compareItem.store === shoppingCompare.store &&
              compareItem.aveCost === 0
          ).length === 0 ? (
            <IonIcon icon={checkmarkOutline} color="success"></IonIcon>
          ) : (
            <IonIcon icon={alertOutline} color="danger"></IonIcon>
          )}
        </IonCol>
      </IonRow>
      <ShoppingCompareItemList
        show={showCompareItemsList}
        store={shoppingCompare.store}
        shoppingList={shoppingList}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingCompareItems: state.shoppingListData.shoppingCompareItems,
    user: state.user.user,
  }),
  mapDispatchToProps: {},
  component: React.memo(ShoppingCompareItem),
});
