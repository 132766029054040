import React from "react";
import { IonRow, IonItemGroup, IonCol } from "@ionic/react";
import { ShoppingCompareItemModel } from "../../models/ShoppingList";
import { connect } from "../../data/connect";

interface OwnProps {
  shoppingCompareItem: ShoppingCompareItemModel;
}

interface StateProps {}

interface DispatchProps {}

interface ShoppingCompareItemItemProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ShoppingCompareItemItem: React.FC<ShoppingCompareItemItemProps> = ({
  shoppingCompareItem,
}) => {
  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="1"></IonCol>
        <IonCol size="4">{shoppingCompareItem.item}</IonCol>
        <IonCol size="3">
          {new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(shoppingCompareItem.aveCost)}
        </IonCol>
        <IonCol size="3">
          {new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(shoppingCompareItem.lastCost)}
        </IonCol>
      </IonRow>
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(ShoppingCompareItemItem),
});
