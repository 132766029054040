import React, { useState, useEffect } from "react";
import { IonDatetime, IonItem, IonLabel } from "@ionic/react";
import ItemPriceHistoryChart from "./ItemPriceHistoryChart";
import StatsBoard from "./StatsBoard";
import { connect } from "../../data/connect";
import { getItemPriceHistoryChartData, setItemPriceHistoryChartLoading } from "../../data/items/items.actions"
import { Item } from "../../models/Item";

interface OwnProps {
    item: Item;
}

interface StateProps {}

interface DispatchProps {
  getItemPriceHistoryChartData: typeof getItemPriceHistoryChartData;
  setItemPriceHistoryChartLoading: typeof setItemPriceHistoryChartLoading;
}

interface ChartsDashboardProps extends OwnProps, StateProps, DispatchProps {}

const ChartsDashboard: React.FC<ChartsDashboardProps> = ({ item, getItemPriceHistoryChartData, setItemPriceHistoryChartLoading }) => {
  const formatDate = (date: Date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return (
      year.toString() +
      "-" +
      (month.toString().length === 2
        ? month.toString()
        : "0" + month.toString()) +
      "-" +
      (day.toString().length === 2 ? day.toString() : "0" + day.toString())
    );
  };

  var today = new Date();
  var fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1);

  const [selectionStart, setSelectionStart] = useState<string>(
    formatDate(fromDate)
  );
  const [selectionEnd, setSelectionEnd] = useState<string>(formatDate(today));

  useEffect(() => {
      if (item) {
        let chartDates = {startDate: selectionStart, endDate: selectionEnd, itemId: item._id}
        setItemPriceHistoryChartLoading(true);
        getItemPriceHistoryChartData(chartDates)
      }          
  }, [selectionStart, selectionEnd, item]);

  const onChangeStart = (fieldValue: string) => {
    setSelectionStart(fieldValue.substring(0, 10));
  };

  const onChangeEnd = (fieldValue: string) => {
    setSelectionEnd(fieldValue.substring(0, 10));
  };

  return (
    <div>
      <IonItem>
        <IonLabel slot="start">From date:</IonLabel>
        <IonDatetime
          displayFormat="YYYY-MM-DD"
          placeholder="Select a from date"
          value={selectionStart}
          onIonChange={(e) => onChangeStart(e.detail.value!)}
          slot="start"
        />
        <IonLabel slot="start">To date:</IonLabel>
        <IonDatetime
          displayFormat="YYYY-MM-DD"
          placeholder="Select a to date"
          value={selectionEnd}
          onIonChange={(e) => onChangeEnd(e.detail.value!)}
          slot="start"
        />
      </IonItem>
      <ItemPriceHistoryChart
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
        itemId={item._id}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { 
    getItemPriceHistoryChartData, 
    setItemPriceHistoryChartLoading 
  },
  component: React.memo(ChartsDashboard),
});
