import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React from "react";
import { ItemCategory } from "../../models/Item";
import ItemCategoryItem from "./ItemCategoryItem";
import { connect } from "../../data/connect";
//import { ItemsState } from "../../data/items/items.state";
//import { addFavorite, removeFavorite } from '../data/sessions/sessions.actions';

interface OwnProps {
  //schedule: Schedule;
  itemCategories: ItemCategory[];
  listType: "all" | "favorites";
  hide: boolean;
}

interface DispatchProps {
  //addFavorite: typeof addFavorite;
  //removeFavorite: typeof removeFavorite;
}

interface ItemCategoryListProps extends OwnProps, DispatchProps {}

const ItemCategoryList: React.FC<ItemCategoryListProps> = ({
  /*addFavorite, removeFavorite,*/ itemCategories,
  hide,
  listType,
}) => {
  if (itemCategories.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Item Categories Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {itemCategories.map((itemCategory, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <ItemCategoryItem itemCategory={itemCategory}></ItemCategoryItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    //addFavorite,
    //removeFavorite
  },
  component: ItemCategoryList,
});
