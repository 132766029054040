import { ShoppingTripsActions } from "./shoppingTrips.actions";
import { ShoppingTripsState } from "./shoppingTrips.state";

export const shoppingTripsReducer = (
  state: ShoppingTripsState,
  action: ShoppingTripsActions
): ShoppingTripsState => {
  switch (action.type) {
    case "set-shoppingTrips-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-shoppingTrips-data": {
      return { ...state, ...action.data };
    }
    case "get-shoppingTrip": {
      return { ...state }; //currentStore: action.data };
    }
    case "set-current-shoppingTrip": {
      return { ...state, currentShoppingTrip: action.data };
    }
    case "add-shoppingTrip": {
      return {
        ...state,
        shoppingTrips: [action.data, ...state.shoppingTrips],
        lastCreatedId: action.data._id,
      };
    }
    case "update-shoppingTrip": {
      return {
        ...state,
        shoppingTrips: state.shoppingTrips.map((shoppingTrip) =>
          shoppingTrip._id === action.data._id ? action.data : shoppingTrip
        ),
      };
    }
    case "delete-shoppingTrip": {
      return {
        ...state,
        shoppingTrips: state.shoppingTrips.filter(
          (shoppingTrip) => shoppingTrip._id !== action.data._id
        ),
      };
    }
    case "get-shoppingItem": {
      return {
        ...state,
        shoppingItems: action.data,
      };
    }
    case "add-shoppingTripItem": {
      return {
        ...state,
        /*shoppingTrips: state.shoppingTrips.map((shoppingTrip) =>
          shoppingTrip._id === action.data._id ? action.data : shoppingTrip
        ),*/
        shoppingItems: [...state.shoppingItems, action.data],
      };
    }
    case "update-shoppingItem":
      return {
        ...state,
        shoppingItems: state.shoppingItems.map((shoppingItem) =>
          shoppingItem._id === action.data._id ? action.data : shoppingItem
        ),
      };
    case "delete-shoppingItem":
      return {
        ...state,
        shoppingItems: state.shoppingItems.filter(
          (shoppingItem) => shoppingItem._id !== action.data._id
        ),
      };
    case "update-filtered-trips": {
      return { ...state, filteredTrips: action.filteredTrips };
    }
    case "set-search-text": {
      return { ...state, searchText: action.searchText };
    }
  }
};
