import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonInput,
  IonCheckbox,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { ShoppingList, ShoppingListItem } from "../../models/ShoppingList";
//import { Store } from "../../models/Store";
import {
  editShoppingList,
  getShoppingListItems,
  addNewShoppingList,
} from "../../data/shoppingLists/shoppingLists.actions";
import ShoppingListItemList from "./ShoppingListItemList";
import NewShoppingListItemLine from "./NewShoppingListItemLine";
import ConvertShoppingListToTripModal from "../shoppingLists/ConvertShoppingListToTripModal";
import DatePickerModal from "../layout/DatePickerModal";
import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {
  //shoppingList: ShoppingList;
}

interface DispatchProps {
  editShoppingList: typeof editShoppingList;
  getShoppingListItems: typeof getShoppingListItems;
  addNewShoppingList: typeof addNewShoppingList;
}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
  shoppingListItems: ShoppingListItem[];
  shoppingLists: ShoppingList[];
  lastCreatedId: string;
}

interface ShoppingListItemsPageProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const ShoppingListItemsPage: React.FC<ShoppingListItemsPageProps> = ({
  shoppingLists,
  lastCreatedId,
  editShoppingList,
  getShoppingListItems,
  addNewShoppingList,
}) => {
  var { shoppinglistid } = useParams<{ shoppinglistid: string }>();

  const [showToastCreated, setShowToastCreated] = useState(false);
  const [shoppingList, setShoppingList] = useState<ShoppingList>({
    _id: "",
    userId: "",
    createdDate: "",
    completedDate: "",
    completed: false,
    notes: "",
    totalLines: 0,
  });
  const [modifiedShoppingList, setModifiedShoppingList] =
    useState<ShoppingList>(shoppingList);
  const [showConvertModal, setShowConvertModal] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  //const [newCreated, setNewCreated] = useState(false);
  //const [date, setDate] = useState(modifiedShoppingList.date);

  const completed = modifiedShoppingList.completed;
  const completedDate = modifiedShoppingList.completedDate;
  const notes = modifiedShoppingList.notes;

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const main = async () => {
    const content = document.querySelector(
      "ion-content.scrollbarShoppingListItems"
    );
    const styles = document.createElement("style");

    styles.textContent = `
          ::-webkit-scrollbar {
            width: 5px;
          }
      
          /* Track */
          ::-webkit-scrollbar-track {
            box-shadow: 0 0 5px grey; 
            border-radius: 10px;
          }
      
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: lightgrey; 
            border-radius: 10px;
          }
      
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #bdc9bd; 
          }
        `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    //console.log(`lastCreatedId: ${lastCreatedId}`);
    //console.log(`shoppinglistid: ${shoppinglistid}`);
    (async function loadData() {
      if (shoppinglistid === "new" && lastCreatedId !== "") {
        //shoppinglistid = lastCreatedId;
        const filteredShoppingLists = shoppingLists.filter(
          (list) => list._id === lastCreatedId
        );
        //console.log(filteredShoppingLists[0]);
        //console.log(modifiedShoppingList);
        if (
          filteredShoppingLists.length > 0 &&
          modifiedShoppingList.completed ===
            filteredShoppingLists[0].completed &&
          //&& modifiedShoppingList.createdDate === filteredShoppingLists[0].createdDate
          modifiedShoppingList.notes === filteredShoppingLists[0].notes &&
          modifiedShoppingList.totalLines ===
            filteredShoppingLists[0].totalLines
        ) {
          setShoppingList(filteredShoppingLists[0]);
          setModifiedShoppingList(filteredShoppingLists[0]);
          //console.log(filteredShoppingLists[0])
          await getShoppingListItems(filteredShoppingLists[0]);
          shoppinglistid = lastCreatedId;
          //console.log("Found!");
          //console.log(filteredShoppingLists[0]);
        }
        //setNewCreated(false);
      }

      if (shoppinglistid !== "new") {
        const filteredShoppingLists = shoppingLists.filter(
          (list) => list._id === shoppinglistid
        );
        if (filteredShoppingLists.length > 0) {
          setShoppingList(filteredShoppingLists[0]);
          setModifiedShoppingList(filteredShoppingLists[0]);
          //console.log(filteredShoppingLists[0])
          await getShoppingListItems(filteredShoppingLists[0]);
        }
      }
    })();
  }, [shoppinglistid, /*getShoppingListItems,*/ lastCreatedId]);

  const editCurrentShoppingList = async () => {
    /*console.log(modifiedShoppingList)

        if (modifiedShoppingList.storeId._id === "") {
            const filteredStores = stores.filter((store) => store.name === modifiedShoppingList.storeId.name)
            console.log(filteredStores[0])
            //onChanged(filteredStores[0]);
            if (filteredStores.length > 0) {
                setModifiedShoppingList({ ...modifiedShoppingList, storeId: filteredStores[0] });
            }             
          }*/
    if (modifiedShoppingList._id === "") {
      await createShoppingList();
    } else {
      editShoppingList(modifiedShoppingList);
    }

    setShowToastCreated(true);
  };

  const createShoppingList = async () => {
    addNewShoppingList(modifiedShoppingList);
    //setNewCreated(true);
  };

  const createShoppingTrip = async () => {
    //editShoppingList(modifiedShoppingList);
    setShowConvertModal(!showConvertModal);
    //setShowToastCreated(true);
  };

  const onNewRowClicked = async () => {
    //console.log("Clicked!")
    //console.log(modifiedShoppingList)
    if (modifiedShoppingList._id === "") {
      await createShoppingList();
    }
  };

  const onChange = (fieldName: string, fieldValue: string) => {
    //console.log(fieldValue)
    setModifiedShoppingList({
      ...modifiedShoppingList,
      [fieldName]: fieldValue,
    });
  };

  const onChangeCompleted = (value: boolean) => {
    setModifiedShoppingList({
      ...modifiedShoppingList,
      completed: value,
    });
  };

  const onChangeDatePicker = (dateValue: string) => {
    onChange("completedDate", dateValue);
    setShowDatePickerModal(false);
  };
  /*
      <ConvertShoppingListToTripModal
          shoppingList={shoppingList}
          show={showConvertModal}
        /> 
       */

  return (
    <IonPage id="newShoppingList">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Shopping list</IonTitle>
        </IonToolbar>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Completed:</IonLabel>
          <IonCheckbox
            checked={completed}
            onIonChange={(e) => onChangeCompleted(e.detail.checked)}
          />
        </IonItem>
        <IonItem
          onClick={() => setShowDatePickerModal(true)}
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>Completed date:</IonLabel>
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={completedDate}
            placeholder="Enter the date it took place"
            onIonChange={(e) => onChange("completedDate", e.detail.value!)}
            slot="end"
            readonly={true}
          ></IonDatetime>
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel> Name:</IonLabel>
          <IonInput
            value={notes}
            placeholder="Enter the list's name"
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonHeader>

      <IonContent class="scrollbarShoppingListItems">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="2" sizeSm="2"></IonCol>
              <IonCol size="5" sizeSm="3">
                Item
              </IonCol>
              <IonCol size="2" sizeSm="2">
                Unit
              </IonCol>
              <IonCol size="2" sizeSm="1">
                Qty
              </IonCol>
              <IonCol sizeSm="3" className="ion-hide-sm-down">
                Notes
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <ShoppingListItemList
              //shoppingItems={shoppingItems}
              shoppingList={shoppingList}
              hide={false}
            />
            <IonRow
              onClick={async () => {
                onNewRowClicked();
              }}
            >
              <NewShoppingListItemLine shoppingList={shoppingList} />
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonButton onClick={createShoppingTrip} slot="end">
            Create Shopping Trip
          </IonButton>
          <IonButton
            routerLink={`/page/ShoppingLists`}
            routerDirection="none"
            onClick={editCurrentShoppingList}
            slot="end"
          >
            {modifiedShoppingList._id === "" ? "Create " : "Update "}Shopping
            List
          </IonButton>
          <IonButton
            routerLink={`/page/ShoppingLists`}
            routerDirection="none"
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
        <DatePickerModal
          show={showDatePickerModal}
          date={
            modifiedShoppingList.completedDate
              ? modifiedShoppingList.completedDate
              : moment(new Date()).format("YYYY-MM-DD")
          }
          onSelected={onChangeDatePicker}
        />
        <ConvertShoppingListToTripModal
          shoppingList={shoppingList}
          show={showConvertModal}
        />
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your shopping list has been updated."
          duration={1500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
    shoppingItems: state.shoppingListData.shoppingListItems,
    shoppingLists: state.shoppingListData.shoppingLists,
    lastCreatedId: state.shoppingListData.lastCreatedId,
    stores: state.storeData.stores,
  }),
  mapDispatchToProps: {
    editShoppingList,
    getShoppingListItems,
    addNewShoppingList,
  },
  component: ShoppingListItemsPage,
});
