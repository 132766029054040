import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
  IonDatetime,
  IonCheckbox,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingList } from "../../models/ShoppingList";
import { editShoppingList } from "../../data/shoppingLists/shoppingLists.actions";
import ConvertShoppingListToTripModal from "./ConvertShoppingListToTripModal";

interface OwnProps {
  show: boolean;
  shoppingList: ShoppingList;
}

interface StateProps {}

interface DispatchProps {
  editShoppingList: typeof editShoppingList;
}

type EditShoppingListProps = OwnProps & StateProps & DispatchProps;

export const EditShoppingListModal: React.FC<EditShoppingListProps> = ({
  show,
  shoppingList,
  editShoppingList,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedShoppingList, setModifiedShoppingList] = useState<
    ShoppingList
  >(shoppingList);

  const completedDate = modifiedShoppingList.completedDate;
  const completed = modifiedShoppingList.completed;
  const notes = modifiedShoppingList.notes;

  const [showModal, setShowModal] = useState(show);
  const [showConvertModal, setShowConvertModal] = useState(false);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedShoppingList({
      ...modifiedShoppingList,
      [fieldName]: fieldValue,
    });
  };

  const onChangeCompleted = (value: boolean) => {
    setModifiedShoppingList({
      ...modifiedShoppingList,
      completed: value,
    });
  };

  const insertShoppingList = async () => {
    editShoppingList(modifiedShoppingList);
    setShowToastCreated(true);
  };

  const createShoppingTrip = async () => {
    //editShoppingList(modifiedShoppingList);
    setShowConvertModal(!showConvertModal);
    //setShowToastCreated(true);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="edit-shoppingList-modal">
        <p>Please modify the details you wish to change</p>
        <IonItem>
          <IonLabel>Completed:</IonLabel>
          <IonCheckbox
            checked={completed}
            onIonChange={(e) => onChangeCompleted(e.detail.checked)}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Completed date:</IonLabel>
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={completedDate}
            placeholder="Enter the date it took place"
            onIonChange={(e) => onChange("completedDate", e.detail.value!)}
            slot="end"
          ></IonDatetime>
        </IonItem>
        <IonItem>
          <IonLabel> Name:</IonLabel>
          <IonInput
            value={notes}
            placeholder="Enter the list's name"
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton onClick={createShoppingTrip} slot="end">
            Create Shopping Trip
          </IonButton>
          <IonButton onClick={insertShoppingList} slot="end">
            Update Shopping List
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <ConvertShoppingListToTripModal
          shoppingList={shoppingList}
          show={showConvertModal}
        ></ConvertShoppingListToTripModal>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => {
            setShowToastCreated(false);
            setShowModal(false);
          }}
          message="Your shopping list has been updated."
          duration={1000}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { editShoppingList },
  component: EditShoppingListModal,
});
