import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { Item } from "../../models/Item";
import ItemItem from "./ItemItem";
import { connect } from "../../data/connect";
//import { ItemsState } from "../../data/items/items.state";
//import { addFavorite, removeFavorite } from '../data/sessions/sessions.actions';

interface OwnProps {
  //schedule: Schedule;
  items: Item[];
  //listType: "all" | "favorites";
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {
  //addFavorite: typeof addFavorite;
  //removeFavorite: typeof removeFavorite;
}

interface ItemListProps extends OwnProps, StateProps, DispatchProps {}

const ItemList: React.FC<ItemListProps> = ({
  /*addFavorite, removeFavorite,*/ items,
  hide,
  //listType,
}) => {
  const [listItems, setListItems] = useState<Item[]>(items.slice(0, 4));
  //let length = listItems.length;

  const recursive = () => {
    setTimeout(() => {
      if (listItems.length  < items.length) {
        setListItems( 
          items.slice(0, listItems.length + 1)//length)
        );
      }
      
      //length += 4;
      //if (hasMore) recursive();
    }, 0);
  }

  useEffect(() => {
    //length = listItems.length;
    //console.log(listItems.length);
    recursive();
  }, [listItems, items]);

  if (items.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Items Found</IonListHeader>
      </IonList>
    );
  }  

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {listItems.map((item, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <ItemItem item={item}></ItemItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    //addFavorite,
    //removeFavorite
  },
  component: ItemList,
});
