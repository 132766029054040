import React, { useEffect } from "react";
import { connect } from "../../data/connect";
import { IonItem, IonFooter, IonToolbar, IonButton } from "@ionic/react";

interface OwnProps {
  hide: boolean;
}

interface DispatchProps {}

interface FooterProps extends OwnProps, DispatchProps {}

export const Footer: React.FC<FooterProps> = ({ hide }) => {
  useEffect(() => {}, []);

  return (
    <IonFooter hidden={hide}>
      <IonToolbar>
        <IonButton
          fill="clear"
          color="tertiary"
          href="https://www.shoppingtracker.app/privacypolicy"
          target="_blank"
          title="Privacy Policy "
        >
          Privacy Policy
        </IonButton>
        <IonButton
          fill="clear"
          color="tertiary"
          href="https://www.shoppingtracker.app/tos"
          target="_blank"
          title="Terms & Conditions "
        >
          Terms & Conditions
        </IonButton>
        <IonButton
          fill="clear"
          color="tertiary"
          routerLink={"/impressum"}
          routerDirection="none"
          title="Legal disclosure/Impressum"
        >
          Legal disclosure/Impressum
        </IonButton>
      </IonToolbar>
    </IonFooter>
  );
};

export default connect<OwnProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: Footer,
});
