//import {Action, Reducer } from "redux"
import { ItemsActions } from "./items.actions";
import { ItemsState } from "./items.state";

export const itemsReducer = (
  state: ItemsState,
  action: ItemsActions
): ItemsState => {
  switch (action.type) {
    case "set-items-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-items-data": {
      return { ...state, ...action.data };
    }
    case "add-item": {
      return { ...state, items: [...state.items, action.data] };
    }
    case "update-item": {
      return {
        ...state,
        items: state.items.map((item) =>
          item._id === action.data._id ? action.data : item
        ),
      };
    }
    case "delete-item": {
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.data._id),
      };
    }
    case "get-unit": {
      return { ...state, currentUnit: action.data };
    }
    case "add-unit": {
      return { ...state, units: [...state.units, action.data] };
    }
    case "update-unit": {
      return {
        ...state,
        units: state.units.map((unit) =>
          unit._id === action.data._id ? action.data : unit
        ),
      };
    }
    case "delete-unit": {
      return {
        ...state,
        units: state.units.filter((unit) => unit._id !== action.data._id),
      };
    }
    case "add-itemCategory": {
      return {
        ...state,
        itemCategories: [...state.itemCategories, action.data],
      };
    }
    case "update-itemCategory": {
      return {
        ...state,
        itemCategories: state.itemCategories.map((itemCategory) =>
          itemCategory._id === action.data._id ? action.data : itemCategory
        ),
      };
    }
    case "delete-itemCategory": {
      return {
        ...state,
        itemCategories: state.itemCategories.filter(
          (itemCategory) => itemCategory._id !== action.data._id
        ),
      };
    }
    case "update-defaultItemCategories": {
      return { ...state, defaultItemCategories: action.updatedCategories };
    }
    case "set-item-category-chart-data": {
      return { ...state, itemCategoryChartData: action.chartData, itemCategoryChartLoading: false}
    }      
    case "set-item-category-chart-loading": {
      return { ...state, itemCategoryChartLoading: action.loading}
    }
    case "set-item-price-history-chart-data": {
      return { ...state, itemPriceHistoryChartData: action.chartData, itemPriceHistoryChartLoading: false}
    }      
    case "set-item-price-history-chart-loading": {
      return { ...state, itemPriceHistoryChartLoading: action.loading}
    }
    case "add-favorite": {
      return { ...state, favorites: [...state.favorites, action.sessionId] };
    }
    case "remove-favorite": {
      return {
        ...state,
        favorites: [...state.favorites.filter((x) => x !== action.sessionId)],
      };
    }
    case "update-filtered-items": {
      return { ...state, filteredItems: action.filteredItems };
    }
    case "set-search-text": {
      return { ...state, searchText: action.searchText };
    }
    case "set-menu-enabled": {
      return { ...state, menuEnabled: action.menuEnabled };
    }
  }
};
