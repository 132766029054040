import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonProgressBar,
  IonRow,
  IonCol,
} from "@ionic/react";
import React from "react";
import "./Home.scss";
//import LoginComponent from "../components/auth/LoginComponent";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { Footer } from "../components/layout/Footer";
import DemoLoginDetails from "../components/auth/DemoLoginDetails";
import DemoLoginButton from "../components/auth/DemoLoginButton";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface StateProps {
  isAuthenticated: boolean;
  hasSeenTutorial: boolean;
  error: string;
  loading: boolean;
}

interface HomeProps extends OwnProps, DispatchProps, StateProps {}

const Home: React.FC<HomeProps> = ({
  isAuthenticated,
  hasSeenTutorial,
  error,
  loading,
  history,
  location,
  match,
}) => {
  //const { name } = useParams<{ name: string }>();
  //className={`${darkMode ? "content-block" : ""}`}
  return (
    <IonPage id="background">
      <IonHeader>
        <IonToolbar hidden={!isAuthenticated}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Shopping Tracker</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <br />
        <IonTitle size="large">
          <h1>Welcome!</h1>
        </IonTitle>
        <br />
        <div className="home-page" id="home-page">
          <DemoLoginDetails />
          <IonRow className="login-block">
            <IonCol>
              <DemoLoginButton
                history={history}
                location={location}
                match={match}
              />
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    hasSeenTutorial: state.user.hasSeenTutorial,
    error: state.user.error,
    loading: state.user.loading,
  }),
  mapDispatchToProps: {},
  component: Home,
});
