import React, { useState } from "react";
import { IonItem, IonInput, IonIcon, IonAlert } from "@ionic/react";
import { addNewStore } from "../../data/stores/stores.actions";
import { Store } from "../../models/Store";
import { connect } from "../../data/connect";
import { allowedToAddStore } from "../../data/premiumChecks";
import { addOutline, saveOutline } from "ionicons/icons";
import PremiumAlert from "../layout/PremiumAlert";

interface OwnProps {}

interface StateProps {
  stores: Store[];
  premium: boolean;
}

interface DispatchProps {
  addNewStore: typeof addNewStore;
}

interface NewStoreLineProps extends OwnProps, StateProps, DispatchProps {}

const NewStoreLine: React.FC<NewStoreLineProps> = ({
  stores,
  premium,
  addNewStore,
}) => {
  const [showCreateLine, setShowCreateLine] = useState(false);
  const [showPremiumAlert, setShowPremiumAlert] = useState(false);

  const [store, setStore] = useState<Store>({
    _id: "",
    userId: "",
    name: "",
    url: "",
    totalTrips: 0,
    totalSpent: 0,
  });

  const onChange = (fieldName: string, fieldValue: string) => {
    setStore({ ...store, [fieldName]: fieldValue });
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      insertStore();
    }
  };

  const onPremiumAlertClose = () => {
    setShowPremiumAlert(false)
  }

  //const id = item.id;
  const name = store.name;
  //const url = store.url;

  const showCreateStoreLine = () => {
    setShowCreateLine(!showCreateLine);
  };

  const insertStore = async () => {
    //console.log(stores.length);
    //console.log(premium);
    if (allowedToAddStore(stores.length, premium) === true) {
      await addNewStore(store);
      //setShowToastCreated(true);
      setStore({
        _id: "",
        userId: "",
        name: "",
        url: "",
        totalTrips: 0,
        totalSpent: 0,
      });
    } else {
      setShowPremiumAlert(true);
    }
  };

  return (
    <>
      <IonItem hidden={!showCreateLine}>
        <IonInput
          type="text"
          value={name}
          placeholder="Enter the store name"
          onIonChange={(e) => onChange("name", e.detail.value!)}
          onKeyDown={(e) => onKeyPress(e)}
          slot="start"
          size={10}
        ></IonInput>
        <IonIcon
          icon={saveOutline}
          color="primary"
          onClick={insertStore}
          slot="end"
        />
      </IonItem>
      <IonItem onClick={showCreateStoreLine}>
        <IonIcon icon={addOutline} /> Add a new store
      </IonItem>
      <PremiumAlert show={showPremiumAlert} onClosed={onPremiumAlertClose} type="stores"/>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    premium: state.user.user.premium,
    stores: state.storeData.stores,
  }),
  mapDispatchToProps: { addNewStore },
  component: React.memo(NewStoreLine),
});
