import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    //IonItem,
    //IonButton,
    //IonText,
    //IonProgressBar ,
  } from "@ionic/react";
  import React from "react";
  import "./Home.scss";
  //import { useParams } from "react-router";
  //import { AddBtn } from "../components/layout/AddBtn";
  //import LoginComponent from "../components/auth/LoginComponent"
  //import DemoLoginDetails from "../components/auth/DemoLoginDetails"
  //import { Login } from "./Login";
  import { connect } from "../data/connect";
  import { RouteComponentProps } from "react-router";
  import { Footer } from "../components/layout/Footer";
  
  interface OwnProps extends RouteComponentProps {}
  
  interface DispatchProps {}
  
  interface StateProps {
  }
  
  interface HomeProps extends OwnProps, DispatchProps, StateProps {}
  
  const Impressum: React.FC<HomeProps> = ({

  }) => {
    //const { name } = useParams<{ name: string }>();
  
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Shopping Tracker</IonTitle>
          </IonToolbar>
        </IonHeader>
  
        <IonContent>                
          <br/>
          <IonTitle size="large"><h1>Legal disclosure/Impressum</h1></IonTitle>        
          <br />
          <p className="styles.EmailListForm.Text">
          www.shoppingtracker.app is developed and run by <br /> 
          H Janse van Rensburg <br />
          Cecilienstr 195 <br />
          12683 <br />
          Berlin <br />
          Germany <br />
          <br />
          Email: <a href="mailto:info@shoppingtracker.app">info@shoppingtracker.app</a>
        </p>
          <br />
          <br />
          
        </IonContent>
        <Footer hide={false} />
      </IonPage>
    );
  };
  
  export default connect<OwnProps, {}, DispatchProps>({
    mapStateToProps: (state) => ({      
    }),
    mapDispatchToProps: {},
    component: Impressum,
  });
  