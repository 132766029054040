import { ShoppingListsActions } from "./shoppingLists.actions";
import { ShoppingListsState } from "./shoppingLists.state";

export const shoppingListsReducer = (
  state: ShoppingListsState,
  action: ShoppingListsActions
): ShoppingListsState => {
  switch (action.type) {
    case "set-shoppingLists-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-shoppingLists-data": {
      return { ...state, ...action.data };
    }
    case "get-shoppingList": {
      return { ...state }; //currentStore: action.data };
    }
    case "set-current-shoppingList": {
      return { ...state, currentShoppingList: action.data };
    }
    case "add-shoppingList": {
      return { ...state, shoppingLists: [...state.shoppingLists, action.data], lastCreatedId: action.data._id };
    }
    case "update-shoppingList": {
      return {
        ...state,
        shoppingLists: state.shoppingLists.map((shoppingList) =>
          shoppingList._id === action.data._id ? action.data : shoppingList
        ),
      };
    }
    case "delete-shoppingList": {
      return {
        ...state,
        shoppingLists: state.shoppingLists.filter(
          (shoppingList) => shoppingList._id !== action.data._id
        ),
      };
    }
    case "get-shoppingListItem": {
      return {
        ...state,
        shoppingListItems: action.data,
      };
    }
    case "add-shoppingListItem": {
      return {
        ...state,
        /*shoppingLists: state.shoppingLists.map((shoppingList) =>
          shoppingList._id === action.data._id ? action.data : shoppingList
        ),*/
        shoppingListItems: [...state.shoppingListItems, action.data],
      };
    }
    case "update-shoppingListItem":
      return {
        ...state,
        shoppingListItems: state.shoppingListItems.map((shoppingListItem) =>
          shoppingListItem._id === action.data._id
            ? action.data
            : shoppingListItem
        ),
      };
    case "delete-shoppingListItem":
      return {
        ...state,
        shoppingListItems: state.shoppingListItems.filter(
          (shoppingListItem) => shoppingListItem._id !== action.data._id
        ),
      };
    case "get-shoppingCompareList":
      return {
        ...state,
        shoppingCompares: action.data,
      };
    case "get-shoppingCompareItemList":
      return {
        ...state,
        shoppingCompareItems: action.data,
        loadingCompare: false,
      };
    case "update-filtered-lists": {
      return { ...state, filteredLists: action.filteredLists };
    }
    case "set-search-text": {
      return { ...state, searchText: action.searchText };
    }
    case "set-loading-compare": {
      return { ...state, loadingCompare: action.loading};
    }
  }
};
