import { DefaultItem, DefaultItemCategory } from "../models/Item";

export const getDefaultItemCategories = () => {
  const defaultItemCategories = [
    { _id: "0", name: "Fruit & Vegetables", checked: false },
    { _id: "1", name: "Seeds, Nuts & Dried Fruit", checked: false },
    //{ _id: "2", name: "Starch", checked: false },
    { _id: "3", name: "Meat", checked: false },
    { _id: "4", name: "Treats", checked: false },
    { _id: "5", name: "Baking", checked: false },
    { _id: "6", name: "Tea/Coffee", checked: false },
    { _id: "7", name: "Dairy/Eggs", checked: false },
    { _id: "8", name: "Dairy Alternatives", checked: false },
    { _id: "9", name: "Cereals", checked: false },
    { _id: "10", name: "Bread", checked: false },
    { _id: "11", name: "Tinned Food", checked: false },
    { _id: "12", name: "Dry Food", checked: false },
    { _id: "13", name: "Oils & Vinegars", checked: false },
    { _id: "14", name: "Condiments", checked: false },
    { _id: "15", name: "Frozen Food", checked: false },
    { _id: "16", name: "Herbs & Spices", checked: false },
    { _id: "17", name: "Toiletries", checked: false },
  ] as DefaultItemCategory[];
  return defaultItemCategories;
};

export const getDefaultItems = () => {
  var defaultItems = [] as DefaultItem[];

  defaultItems = defaultItems.concat(getDefaultFruitItems());
  defaultItems = defaultItems.concat(getDefaultSeedsNutsItems());
  defaultItems = defaultItems.concat(getDefaultMeatItems());
  //defaultItems = defaultItems.concat(getDefaultStarch());
  defaultItems = defaultItems.concat(getDairyEggs())
  defaultItems = defaultItems.concat(getDairyAlternatives())
  defaultItems = defaultItems.concat(getCereals())
  defaultItems = defaultItems.concat(getBreads())
  defaultItems = defaultItems.concat(getTeaCoffee())
  defaultItems = defaultItems.concat(getTinnedFood())
  defaultItems = defaultItems.concat(getDryFood())
  defaultItems = defaultItems.concat(getOils())
  defaultItems = defaultItems.concat(getCondiments())
  defaultItems = defaultItems.concat(getFrozenFoods())
  defaultItems = defaultItems.concat(getBaking())
  defaultItems = defaultItems.concat(getHerbsSpices())
  defaultItems = defaultItems.concat(getTreats())
  defaultItems = defaultItems.concat(getToiletries())

  return defaultItems;
};

export const getDefaultFruitItems = () => {
  const defaultFruit = [
    {
      _id: "0",
      name: "Apple",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "1",
      name: "Banana",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "2",
      name: "Pear",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "3",
      name: "Kiwi",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "4",
      name: "Red onions",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "5",
      name: "White onions",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "6",
      name: "Garlic",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "7",
      name: "Ginger",
      defaultUnitId: "Pieces",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "8",
      name: "Cauliflower",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "9",
      name: "Broccoli",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
    {
      _id: "10",
      name: "Potatoes",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Fruit & Vegetables"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultFruit;
};

export const getDefaultSeedsNutsItems = () => {
  const defaultSeeds = [
    {
      _id: "100",
      name: "Raisins",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Seeds, Nuts & Dried Fruit"],
      checked: true,
    },
    {
      _id: "101",
      name: "Sunflower seeds",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Seeds, Nuts & Dried Fruit"],
      checked: true,
    },
    {
      _id: "102",
      name: "Flax seeds",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Seeds, Nuts & Dried Fruit"],
      checked: true,
    },
    {
      _id: "103",
      name: "Sesame seeds",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Seeds, Nuts & Dried Fruit"],
      checked: true,
    },
    {
      _id: "104",
      name: "Chia seeds",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Seeds, Nuts & Dried Fruit"],
      checked: true,
    },
    {
      _id: "105",
      name: "Dates",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Seeds, Nuts & Dried Fruit"],
      checked: true,
    },
  ] as DefaultItem[]

  return defaultSeeds;
}

export const getDefaultMeatItems = () => {
  const defaultMeat = [
    {
      _id: "200",
      name: "Chicken breasts",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Meat"],
      checked: true,
    },
    {
      _id: "201",
      name: "Beef mince",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Meat"],
      checked: true,
    },
    {
      _id: "202",
      name: "Beef gulash",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Meat"],
      checked: true,
    },
    {
      _id: "203",
      name: "Pork gulash",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Meat"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultMeat;
};

export const getDairyEggs = () => {
  const defaultDairyEggs = [
    {
      _id: "400",
      name: "Milk",
      defaultUnitId: "Litres",
      itemCategories: ["Dairy/Eggs"],
      checked: true,
    },
    {
      _id: "401",
      name: "Eggs",
      defaultUnitId: "Pieces",
      itemCategories: ["Dairy/Eggs"],
      checked: true,
    },
    {
      _id: "402",
      name: "Cheese",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Dairy/Eggs"],
      checked: true,
    },
    {
      _id: "403",
      name: "Butter",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Dairy/Eggs"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultDairyEggs;
}

export const getDairyAlternatives = () => {
  const defaultDairyAlt = [
    {
      _id: "500",
      name: "Almond milk",
      defaultUnitId: "Litres",
      itemCategories: ["Dairy Alternatives"],
      checked: true,
    },
    {
      _id: "501",
      name: "Oat milk",
      defaultUnitId: "Litres",
      itemCategories: ["Dairy Alternatives"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultDairyAlt;
}

export const getCereals = () => {
  const defaultCereals = [
    {
      _id: "600",
      name: "Muesli",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Cereals"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultCereals;
}

export const getBreads = () => {
  const defaultBreads = [
    {
      _id: "700",
      name: "Bread rolls",
      defaultUnitId: "Pieces",
      itemCategories: ["Bread"],
      checked: true,
    },
    {
      _id: "701",
      name: "Sliced bread",
      defaultUnitId: "Pieces",
      itemCategories: ["Bread"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultBreads;
}

export const getTeaCoffee = () => {
  const defaultTeaCoffee = [
    {
      _id: "800",
      name: "Black tea",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Tea/Coffee"],
      checked: true,
    },
    {
      _id: "801",
      name: "Filter coffee",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Tea/Coffee"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultTeaCoffee;
}

export const getTinnedFood = () => {
  const defaultTinnedFood = [
    {
      _id: "900",
      name: "Tinned tomatoes",
      defaultUnitId: "Pieces",
      itemCategories: ["Tinned Food"],
      checked: true,
    },
    {
      _id: "901",
      name: "Kidney beans",
      defaultUnitId: "Pieces",
      itemCategories: ["Tinned Food"],
      checked: true,
    },
    {
      _id: "902",
      name: "Tinned tuna",
      defaultUnitId: "Pieces",
      itemCategories: ["Tinned Food"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultTinnedFood;
}

export const getDryFood = () => {
  const defaultDryFood = [
    {
      _id: "1000",
      name: "Oats",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Dry Food"],
      checked: true,
    },
    {
      _id: "1001",
      name: "Lentils",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Dry Food"],
      checked: true,
    },
    {
      _id: "1002",
      name: "Pasta",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Dry Food"],
      checked: true,
    },
    {
      _id: "1003",
      name: "Vegetable stock",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Dry Food"],
      checked: true,
    },
    {
      _id: "1004",
      name: "Maize cakes",
      defaultUnitId: "Pieces",
      itemCategories: ["Dry Food"],
      checked: true,
    },
    {
      _id: "1005",
      name: "Rice",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Dry Food"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultDryFood;
}

export const getOils = () => {
  const defaultOils = [
    {
      _id: "1100",
      name: "Olive oil",
      defaultUnitId: "Litres",
      itemCategories: ["Oils & Vinegars"],
      checked: true,
    },
    {
      _id: "1101",
      name: "Sunflower oil",
      defaultUnitId: "Litres",
      itemCategories: ["Oils & Vinegars"],
      checked: true,
    },
    {
      _id: "1102",
      name: "Canola oil",
      defaultUnitId: "Litres",
      itemCategories: ["Oils & Vinegars"],
      checked: true,
    },
    {
      _id: "1103",
      name: "Apple cider vinegar",
      defaultUnitId: "Litres",
      itemCategories: ["Oils & Vinegars"],
      checked: true,
    },
    {
      _id: "1104",
      name: "White vinegar",
      defaultUnitId: "Litres",
      itemCategories: ["Oils & Vinegars"],
      checked: true,
    },
    {
      _id: "1105",
      name: "Balsamic vinegar",
      defaultUnitId: "Litres",
      itemCategories: ["Oils & Vinegars"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultOils;
}

export const getCondiments = () => {
  const defaultItems = [
    {
      _id: "1200",
      name: "Mayonnaise",
      defaultUnitId: "Litres",
      itemCategories: ["Condiments"],
      checked: true,
    },
    {
      _id: "1201",
      name: "Tomato sauce",
      defaultUnitId: "Litres",
      itemCategories: ["Condiments"],
      checked: true,
    },
    {
      _id: "1202",
      name: "Dijon mustard",
      defaultUnitId: "Litres",
      itemCategories: ["Condiments"],
      checked: true,
    },
    {
      _id: "1203",
      name: "Ketchup",
      defaultUnitId: "Litres",
      itemCategories: ["Condiments"],
      checked: true,
    },
    {
      _id: "1204",
      name: "Apricot jam",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Condiments"],
      checked: true,
    },
    {
      _id: "1205",
      name: "Cashew creme",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Condiments"],
      checked: true,
    },
    {
      _id: "1206",
      name: "Almond butter",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Condiments"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultItems;
}

export const getFrozenFoods = () => {
  const defaultItems = [
    {
      _id: "1300",
      name: "Mixed vegetables",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Frozen Food"],
      checked: true,
    },  
    {
      _id: "1301",
      name: "Frozen spinach",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Frozen Food"],
      checked: true,
    },   
    {
      _id: "1302",
      name: "Frozen broccoli",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Frozen Food"],
      checked: true,
    }, 
    {
      _id: "1303",
      name: "Frozen cauliflower",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Frozen Food"],
      checked: true,
    },   
    {
      _id: "1304",
      name: "Sweet potato fries",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Frozen Food"],
      checked: true,
    },   
    {
      _id: "1305",
      name: "Berry smoothie mix",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Frozen Food"],
      checked: true,
    },  
  ] as DefaultItem[];
  return defaultItems;
}

export const getBaking = () => {
  const defaultItems = [
    {
      _id: "1400",
      name: "White sugar",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    },  
    {
      _id: "1401",
      name: "Brown sugar",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    },   
    {
      _id: "1402",
      name: "Muffin cups",
      defaultUnitId: "Pieces",
      itemCategories: ["Baking"],
      checked: true,
    },   
    {
      _id: "1403",
      name: "White flour",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1404",
      name: "Buckwheat flour",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1405",
      name: "Icing sugar",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1406",
      name: "Cooking chocolate",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1407",
      name: "Baking powder",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1408",
      name: "Bicarbonate of soda",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1409",
      name: "Yeast",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1410",
      name: "Vanilla sugar",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1411",
      name: "Cocoa",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1412",
      name: "Chocolate sprinkles",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1413",
      name: "Ground almonds",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
    {
      _id: "1414",
      name: "Mini marshmallows",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Baking"],
      checked: true,
    }, 
  ] as DefaultItem[];
  return defaultItems;
}

export const getHerbsSpices = () => {
  const defaultItems = [
    {
      _id: "1500",
      name: "Cinnamon",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    },      
    {
      _id: "1501",
      name: "Caraway seeds",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    },  
    {
      _id: "1502",
      name: "Caraway powder",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    },  
    {
      _id: "1503",
      name: "Cumin",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    },  
    {
      _id: "1504",
      name: "Nutmeg",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    },  
    {
      _id: "1505",
      name: "Ground cloves",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1506",
      name: "Oregano",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1507",
      name: "Curry",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1508",
      name: "Tumeric",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1509",
      name: "Ground chili",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1510",
      name: "Chili flakes",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1511",
      name: "Paprika",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1512",
      name: "Thyme",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1513",
      name: "Coriander",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1514",
      name: "Cayenne pepper",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1515",
      name: "Rosemary",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1516",
      name: "Mugwort",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1517",
      name: "Ginger",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1518",
      name: "Garam masala",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1519",
      name: "Ground onions",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1520",
      name: "Ground garlic",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1521",
      name: "Salt",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1522",
      name: "Black pepper",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1523",
      name: "White pepper",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1524",
      name: "Petersilli",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
    {
      _id: "1525",
      name: "Chives",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Herbs & Spices"],
      checked: true,
    }, 
  ] as DefaultItem[];
  return defaultItems;
}

export const getTreats = () => {
  const defaultItems = [
    {
      _id: "1600",
      name: "Turkish delight",
      defaultUnitId: "Kilogrammes",
      itemCategories: ["Treats"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultItems;
}

export const getToiletries = () => {
  const defaultItems = [
    {
      _id: "1700",
      name: "Deodrant",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1701",
      name: "Shower gel",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1702",
      name: "Shampoo",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1703",
      name: "Toothbrush",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1704",
      name: "Conditioner",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1705",
      name: "Moisturising cream",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1706",
      name: "Face wash",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1707",
      name: "Toner",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1708",
      name: "Toothpaste",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1709",
      name: "Hand soap",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1710",
      name: "Toilet paper",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
    {
      _id: "1711",
      name: "Tissues",
      defaultUnitId: "Pieces",
      itemCategories: ["Toiletries"],
      checked: true,
    },
  ] as DefaultItem[];
  return defaultItems;
}