import React, { useState } from "react";
import { IonItem, IonInput, IonIcon } from "@ionic/react";
import { addNewItem } from "../../data/items/items.actions";
import { Item, Unit, ItemCategory } from "../../models/Item";
import { connect } from "../../data/connect";
import { addOutline, saveOutline } from "ionicons/icons";
import SelectUnitModal from "../units/SelectUnitModal";
import SelectItemCategory from "../itemCategories/SelectItemCategoryModal";
import { allowedToAddItem } from "../../data/premiumChecks";
import PremiumAlert from "../layout/PremiumAlert";
import "../../theme/styling.scss"

interface OwnProps {}

interface StateProps {
  items: Item[];
  premium: boolean;
}

interface DispatchProps {
  addNewItem: typeof addNewItem;
}

interface NewItemLineProps extends OwnProps, StateProps, DispatchProps {}

const NewItemLine: React.FC<NewItemLineProps> = ({ items, premium, addNewItem }) => {
  const [showCreateLine, setShowCreateLine] = useState(false);
  const [showPremiumAlert, setShowPremiumAlert] = useState(false);

  const [item, setItem] = useState<Item>({
    _id: "",
    userId: "",
    name: "",
    defaultUnitId: {
      _id: "",
      name: "",
      symbol: "",
    },
    itemCategories: [],
    avePrice: 0,
    lastPrice: 0,
    storeLastPrice: [],
  });

  const onChange = (fieldName: string, fieldValue: string) => {
    setItem({ ...item, [fieldName]: fieldValue });
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      insertItem()
    }
  }

  const onChangeUnit = (fieldValue: Unit) => {
    setItem({ ...item, defaultUnitId: fieldValue });
  };

  const onChangeCategory = (fieldValue: ItemCategory[]) => {
    setItem({ ...item, itemCategories: fieldValue });
  };

  //const id = item.id;
  const name = item.name;
  const defaultUnitId = item.defaultUnitId;
  const itemItemCategories = item.itemCategories;

  const showCreateItemLine = () => {
    setShowCreateLine(!showCreateLine);
  };

  const onPremiumAlertClose = () => {
    setShowPremiumAlert(false)
  }

  const insertItem = async () => {
    if (allowedToAddItem(items.length, premium) === true) {
      await addNewItem(item);
      //setShowToastCreated(true);
      setItem({
        _id: "",
        userId: "",
        name: "",
        defaultUnitId: {
          _id: "",
          name: "",
          symbol: "",
        },
        itemCategories: [],
        avePrice: 0,
        lastPrice: 0,
        storeLastPrice: [],
      });
    } else {
      setShowPremiumAlert(true);
    }
  };

  return (
    <>
      <IonItem id="newItemLine" hidden={!showCreateLine} onKeyPress={(e) => onKeyPress(e)}>
        <IonInput
          type="text"
          value={name}
          placeholder="Name"
          onIonChange={(e) => onChange("name", e.detail.value!)}
          slot="start"
          size={4}
        ></IonInput>        
        <SelectItemCategory
          itemItemCategories={itemItemCategories}
          onChanged={onChangeCategory}
          slot="start"
        />
        <SelectUnitModal
          unit={defaultUnitId}
          onChanged={onChangeUnit}
          slot="start"
        />
        <IonIcon icon={saveOutline} color="primary" onClick={insertItem} slot="end" />
      </IonItem>
      <IonItem onClick={showCreateItemLine}>
        <IonIcon icon={addOutline} /> Add a new item
      </IonItem>
      <PremiumAlert show={showPremiumAlert} onClosed={onPremiumAlertClose} type="items"/>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    premium: state.user.user.premium,
    items: state.data.items,
  }),
  mapDispatchToProps: { addNewItem },
  component: React.memo(NewItemLine),
});
