import React, { useState, useEffect } from "react";
import { IonDatetime, IonItem, IonLabel } from "@ionic/react";
import ShoppingTripsChart from "./ShoppingTripsChart";
import ItemCategoriesChart from "./ItemCategoriesChart";
import StatsBoard from "./StatsBoard";
import { connect } from "../../data/connect";
import {
  getItemCategoryChartData,
  setItemCategoryChartLoading,
} from "../../data/items/items.actions";
//import { ChartDateParameters } from "../../models/ChartObjects";
import DateRangePickerModal from "../layout/DateRangePickerModal";
import moment from "moment";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {
  getItemCategoryChartData: typeof getItemCategoryChartData;
  setItemCategoryChartLoading: typeof setItemCategoryChartLoading;
}

interface ChartsDashboardProps extends OwnProps, StateProps, DispatchProps {}

const ChartsDashboard: React.FC<ChartsDashboardProps> = ({
  getItemCategoryChartData,
  setItemCategoryChartLoading,
}) => {
  const formatDate = (date: Date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return (
      year.toString() +
      "-" +
      (month.toString().length === 2
        ? month.toString()
        : "0" + month.toString()) +
      "-" +
      (day.toString().length === 2 ? day.toString() : "0" + day.toString())
    );
  };

  var today = new Date();
  var fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1);

  const [selectionStart, setSelectionStart] = useState<string>(
    formatDate(fromDate)
  );
  const [selectionEnd, setSelectionEnd] = useState<string>(formatDate(today));
  const [showDateRangePickerModal, setShowDateRangePickerModal] =
    useState(false);
  //const [showEndDatePickerModal, setShowEndDatePickerModal] = useState(false);

  useEffect(() => {
    let chartDates = { startDate: selectionStart, endDate: selectionEnd };
    setItemCategoryChartLoading(true);
    getItemCategoryChartData(chartDates);
  }, [selectionStart, selectionEnd]);

  const onChangeStart = (fieldValue: string) => {
    //console.log(fieldValue);
    setSelectionStart(fieldValue.substring(0, 10));
    setShowDateRangePickerModal(false);

    //console.log("onChangeStart triggered")
    //let chartDates = {startDate: fieldValue.substring(0, 10), endDate: selectionEnd}
    //getItemCategoryChartData(chartDates)
  };

  const onChangeEnd = (fieldValue: string) => {
    setSelectionEnd(fieldValue.substring(0, 10));
    setShowDateRangePickerModal(false);

    //console.log("onChangeEnd triggered")
    //let chartDates = {startDate: selectionStart, endDate: fieldValue.substring(0, 10)}
    //getItemCategoryChartData(chartDates)
  };

  const onChangeDates = (dateValues: string[]) => {
    //console.log(dateValues);
    if (dateValues.length > 1) {
      onChangeStart(dateValues[0]);
      onChangeEnd(dateValues[1]);
    }
  };

  return (
    <div>
      <IonItem onClick={() => setShowDateRangePickerModal(true)}>
        <IonLabel slot="start">From date:</IonLabel>
        <IonDatetime
          displayFormat="YYYY-MM-DD"
          placeholder="Select a from date"
          value={selectionStart}
          //onIonChange={(e) => onChangeStart(e.detail.value!)}
          slot="start"
          readonly={true}
          /*onClick={() => {
            setShowDateRangePickerModal(true);
            console.log("Clicked!");
          }}*/
        />
        <IonLabel slot="start">To date:</IonLabel>
        <IonDatetime
          displayFormat="YYYY-MM-DD"
          placeholder="Select a to date"
          value={selectionEnd}
          //onIonChange={(e) => onChangeEnd(e.detail.value!)}
          slot="start"
          readonly={true}
          //onClick={() => setShowDateRangePickerModal(true)}
        />
      </IonItem>
      <StatsBoard selectionStart={selectionStart} selectionEnd={selectionEnd} />
      <ShoppingTripsChart
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <ItemCategoriesChart
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <DateRangePickerModal
        show={showDateRangePickerModal}
        dates={[
          moment(selectionStart).format("DD MMM YYYY"),
          moment(selectionEnd).format("DD MMM YYYY"),
        ]}
        onSelected={onChangeDates}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    getItemCategoryChartData,
    setItemCategoryChartLoading,
  },
  component: React.memo(ChartsDashboard),
});
