import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Store } from "../../models/Store";
import { addNewStore } from "../../data/stores/stores.actions";

interface OwnProps {
  onCreate: () => void;
}

interface StateProps {}

interface DispatchProps {
  addNewStore: typeof addNewStore;
}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type CreateStoreProps = OwnProps & StateProps & DispatchProps;

export const CreateStore: React.FC<CreateStoreProps> = ({
  onCreate,
  addNewStore,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [store, setStore] = useState<Store>({
    _id: "",
    userId: "",
    name: "",
    url: "",
    totalTrips: 0,
    totalSpent: 0,
  });

  //const id = item.id;
  const name = store.name;
  const url = store.url;

  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {}, []);

  const onChange = (fieldName: string, fieldValue: string) => {
    setStore({ ...store, [fieldName]: fieldValue });
  };

  const insertStore = async () => {
    await addNewStore(store);
    setShowToastCreated(true);
    onCreate();
    setTimeout(() => {
      setStore({
        _id: "",
        userId: "",
        name: "",
        url: "",
        totalTrips: 0,
        totalSpent: 0,
      });
    }, 2000);
  };

  return (
    <>
      <p>Please enter the details of your new store</p>
      <IonItem>
        <IonLabel>Name:</IonLabel>
        <IonInput
          value={name}
          placeholder="Enter the store name"
          onIonChange={(e) => onChange("name", e.detail.value!)}
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel> URL:</IonLabel>
        <IonInput
          value={url}
          placeholder="Enter the store url (Optional)"
          onIonChange={(e) => onChange("url", e.detail.value!)}
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonButton onClick={async () => await insertStore()} slot="end">
          Create Store
        </IonButton>
      </IonItem>
      <IonToast
        isOpen={showToastCreated}
        onDidDismiss={() => setShowToastCreated(false)}
        message="Your store has been created."
        duration={1500}
        color="primary"
      />
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { addNewStore },
  component: CreateStore,
});
