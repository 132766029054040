import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ItemCategory } from "../../models/Item";
import { editItemCategory } from "../../data/items/items.actions";

interface OwnProps {
  show: boolean;
  itemCategory: ItemCategory;
}

interface StateProps {}

interface DispatchProps {
  editItemCategory: typeof editItemCategory;
}

interface EditItemCategoryProps extends OwnProps, StateProps, DispatchProps {}

const EditItemCategoryModal: React.FC<EditItemCategoryProps> = ({
  show,
  itemCategory,
  editItemCategory,
}) => {
  const [modifiedItemCategory, setModifiedItemCategory] = useState<
    ItemCategory
  >(itemCategory);

  const name = modifiedItemCategory.name;
  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) =>
    setModifiedItemCategory({
      ...modifiedItemCategory,
      [fieldName]: fieldValue,
    });

  const updateItemCategory = async () => {
    editItemCategory(modifiedItemCategory);
    setShowToastCreated(true);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="edit-item-category-modal">
        <p>Modify item category</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the item category name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem slot="end">
          <IonButton onClick={updateItemCategory} slot="end">
            Update Item Category
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your item category have been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { editItemCategory },
  component: EditItemCategoryModal,
});
