import React from "react";
import { IonRow, IonItemGroup, IonCol } from "@ionic/react";
import { LastStorePrice } from "../../models/Item";
import { User } from "../../models/User"
import { connect } from "../../data/connect";

interface OwnProps {
  lastStorePrice: LastStorePrice;
}

interface StateProps {
  user: User;
}

interface DispatchProps {}

interface ItemShopCompareItemProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ItemShopCompareItem: React.FC<ItemShopCompareItemProps> = ({
  lastStorePrice,
  user,
}) => {
  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="3">{lastStorePrice.storeId? lastStorePrice.storeId.name: "Unknown"}</IonCol>
        <IonCol size="3">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(lastStorePrice.avePrice)}
        </IonCol>
        <IonCol size="3">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(lastStorePrice.lastPrice)}
        </IonCol>
      </IonRow>
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  mapDispatchToProps: {},
  component: React.memo(ItemShopCompareItem),
});
