import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React from "react";
import { Unit } from "../../models/Item";
import UnitItem from "./UnitItem";
import { connect } from "../../data/connect";
//import { ItemsState } from "../../data/items/items.state";
//import { addFavorite, removeFavorite } from '../data/sessions/sessions.actions';

interface OwnProps {
  //schedule: Schedule;
  units: Unit[];
  listType: "all" | "favorites";
  hide: boolean;
}

interface DispatchProps {
  //addFavorite: typeof addFavorite;
  //removeFavorite: typeof removeFavorite;
}

interface UnitListProps extends OwnProps, DispatchProps {}

const UnitList: React.FC<UnitListProps> = ({
  /*addFavorite, removeFavorite,*/ units,
  hide,
  listType,
}) => {
  if (units.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Units Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {units.map((unit, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <UnitItem unit={unit}></UnitItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    //addFavorite,
    //removeFavorite
  },
  component: UnitList,
});
