export const allowedToAddStore = (totalStores: number, premium: boolean) => {
  if (!premium && totalStores >= maxStores) {
    return false;
  }

  return true;
};

export const allowedToAddItem = (totalItems: number, premium: boolean) => {
  if (!premium && totalItems >= maxItems) {
    return false;
  }

  return true;
};

export const maxStores = 5;

export const maxItems = 125;
