import React, { useState, useEffect } from "react";
import { IonModal } from "@ionic/react";
import { connect } from "../../data/connect";
import Calendar from "react-calendar";
import "./Calendar.css";
import "./DatePickerModal.scss";
import moment from "moment";

interface OwnProps {
  show: boolean;
  onSelected: (date: string) => void;
  date: string;
}

interface StateProps {}

interface DispatchProps {}

type DatePickerProps = OwnProps & StateProps & DispatchProps;

export const DatePickerModal: React.FC<DatePickerProps> = ({
  show,
  onSelected,
  date,
}) => {
  const [pickerDate, setPickerDate] = useState(date);

  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    //console.log(show)
    //console.log(date);
    setShowModal(show);
  }, [show]);

  const onSelect = (dateValue: Date | Date[]) => {
    //console.log(dateValue);
    var dateString = moment(dateValue.toString()).format("YYYY-MM-DD");
    //console.log(dateString);
    setPickerDate(dateString);
    onSelected(dateString);
    setShowModal(false);
  };

  const onDismiss = () => {
    onSelected(pickerDate);
    setShowModal(false);
  };

  return (
    <>
      <IonModal
        isOpen={showModal}
        id="date-picker-modal"
        onDidDismiss={onDismiss}
        cssClass={"auto-height"}
      >
        <div className="inner-content">
          <Calendar
            onChange={onSelect}
            value={new Date(pickerDate)}
            className="react-calendar"
            //tileClassName="react-calendar__tile"
          />
        </div>
      </IonModal>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: DatePickerModal,
});
