import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingList } from "../../models/ShoppingList";
import { getShoppingCompares } from "../../data/shoppingLists/shoppingLists.actions";
import ShoppingCompareList from "../shoppingCompare/ShoppingCompareList";

interface OwnProps {
  show: boolean;
  shoppingList: ShoppingList;
}

interface StateProps {}

interface DispatchProps {
  getShoppingCompares: typeof getShoppingCompares;
}

type ShoppingCompareProps = OwnProps & StateProps & DispatchProps;

export const ShoppingCompareModal: React.FC<ShoppingCompareProps> = ({
  show,
  shoppingList,
  getShoppingCompares,
}) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);

    (async function loadData() {
      await getShoppingCompares(shoppingList);
    })();
  }, [show, shoppingList]);

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="shoppingItem-modal">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="1"></IonCol>
              <IonCol size="3">Store</IonCol>
              <IonCol size="3">Ave Price</IonCol>
              <IonCol size="3">Last Price</IonCol>
              <IonCol size="2">Complete</IonCol>
            </IonRow>
            <ShoppingCompareList
              //shoppingItems={shoppingItems}
              shoppingList={shoppingList}
              hide={false}
            />
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={() => {
              setShowModal(false);
            }}
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { getShoppingCompares },
  component: ShoppingCompareModal,
});
