import React from "react";

const noop = (files: FileList | null) => {};

interface Props {
    value: File | undefined;
    onChange: (files: FileList | null) => void;
  }

const FileInput: React.FC<Props> = ({ value, onChange = noop }) => (
  <div>
    <div>Selected file: {value !== undefined? value.name: ""}</div>
    <label>
      Click to select some files...
      <input
        style={{ display: "none" }}
        type="file"
        onChange={e => {
          onChange(e.target.files);
        }}
      />
    </label>
  </div>
);

export default FileInput;

/*{Boolean(value.length) && (
      <div>Selected files: {value.map(f => f.name).join(", ")}</div>
    )}*/