import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useEffect } from "react";
import {
  ShoppingList,
  ShoppingCompareItemModel,
} from "../../models/ShoppingList";
import { getShoppingCompareItems } from "../../data/shoppingLists/shoppingLists.actions";
import ShoppingCompareItemItem from "./ShoppingCompareItemItem";
import { connect } from "../../data/connect";

interface OwnProps {
  shoppingList: ShoppingList;
  store: string;
  show: boolean;
}

interface StateProps {
  shoppingCompareItems: ShoppingCompareItemModel[];
}

interface DispatchProps {
  getShoppingCompareItems: typeof getShoppingCompareItems;
}

interface ShoppingCompareItemListProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ShoppingCompareItemList: React.FC<ShoppingCompareItemListProps> = ({
  shoppingList,
  store,
  show,
  shoppingCompareItems,
  getShoppingCompareItems,
}) => {
  useEffect(() => {
    (async function loadData() {
      if (shoppingList._id !== "") {
        //await getShoppingCompareItems(shoppingList);
      }      
    })();
  }, [shoppingList]);

  if (shoppingCompareItems.length === 0 && show) {
    return (
      <IonList>
        <IonListHeader>No Items Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={!show ? { display: "none" } : {}}>
        {shoppingCompareItems
          .filter((compareItem) => compareItem.store === store)
          .map((shoppingCompareItem) => (
            <IonItemGroup key={`group-${shoppingCompareItem.item}`}>
              <ShoppingCompareItemItem
                shoppingCompareItem={shoppingCompareItem}
              ></ShoppingCompareItemItem>
            </IonItemGroup>
          ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingCompareItems: state.shoppingListData.shoppingCompareItems,
  }),
  mapDispatchToProps: { getShoppingCompareItems },
  component: ShoppingCompareItemList,
});
