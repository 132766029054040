import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonBadge,
  //IonNote,
  //IonButton,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "../data/connect";
import { logoutUser } from "../data/user/user.actions";
import {
  bookmarkOutline,
  logOutOutline,
  //logOutSharp,
  //heartOutline,
  //heartSharp,
  buildOutline,
  buildSharp,
  nutritionOutline,
  nutritionSharp,
  //restaurantOutline,
  //restaurantSharp,
  receiptOutline,
  receiptSharp,
  listOutline,
  listSharp,
  //bookOutline,
  //bookSharp,
  cartOutline,
  cartSharp,
  fileTrayFull,
  fileTrayFullOutline,
  calculator,
  calculatorOutline,
  homeOutline,
  homeSharp,
  flashSharp,
} from "ionicons/icons";
import "./Menu.css";
//import { Footer } from "../components/layout/Footer";
//import { userReducer } from "../data/user/user.reducer";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/Home",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: "Items",
    url: "/page/Items",
    iosIcon: nutritionOutline,
    mdIcon: nutritionSharp,
  },
  {
    title: "Shopping Lists",
    url: "/page/ShoppingLists",
    iosIcon: listOutline,
    mdIcon: listSharp,
  },
  {
    title: "Shopping Trips",
    url: "/page/ShoppingTrips",
    iosIcon: receiptOutline,
    mdIcon: receiptSharp,
  },
  {
    title: "Stores",
    url: "/page/Stores",
    iosIcon: cartOutline,
    mdIcon: cartSharp,
  },
  {
    title: "Item Categories",
    url: "/page/ItemCategories",
    iosIcon: fileTrayFullOutline,
    mdIcon: fileTrayFull,
  },
  {
    title: "Units",
    url: "/page/Units",
    iosIcon: calculatorOutline,
    mdIcon: calculator,
  },
  {
    title: "Settings",
    url: "/page/Settings",
    iosIcon: buildOutline,
    mdIcon: buildSharp,
  },
];

const labels = ["Favourites"];

interface OwnProps {
  hide: boolean;
}

interface StateProps {
  name: string;
  premium: boolean;
}

interface DispatchProps {
  logoutUser: typeof logoutUser;
}

interface MenuProps extends OwnProps, StateProps, DispatchProps {}
//type MenuProps = OwnProps & RouterProps;

const Menu: React.FC<MenuProps> = ({ hide, name, premium, logoutUser }) => {
  const location = useLocation();

  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

  return (
    <IonMenu contentId="main" type="overlay" hidden={hide}>
      <IonContent className="menu-theme">
        <IonList id="inbox-list">
          <div className="login-logo">
            <img
              src={
                prefersDark.matches
                  ? "assets/icon/Logo-Dark.svg"
                  : "assets/icon/Logo-Light.svg"
              }
              alt="Shopping Tracker Logo"
            />
          </div>
          <IonListHeader hidden={true}>Shopping Tracker</IonListHeader>
          <IonItem
            routerLink={"/dashboard"}
            routerDirection="none"
            lines="none"
            detail={false}
          >
            <IonLabel slot="end">{name}</IonLabel>{" "}
            <IonBadge color="primary" slot="end">
              {premium ? "PREMIUM" : "FREE"}
            </IonBadge>
          </IonItem>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonItem
            className={""}
            routerLink="/subscribe"
            routerDirection="none"
            lines="none"
            detail={false}
            hidden={premium}
          >
            <IonIcon slot="start" icon={flashSharp} />
            <IonLabel>Upgrade</IonLabel>
          </IonItem>
          <IonItem
            className={""}
            routerLink="/loggedout"
            routerDirection="none"
            lines="none"
            detail={false}
            onClick={async () => {
              await logoutUser();
            }}
          >
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Log out</IonLabel>
          </IonItem>
        </IonList>

        <IonList id="labels-list" hidden={true}>
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    name: state.user.user.name,
    premium: state.user.user.premium,
  }),
  mapDispatchToProps: {
    logoutUser,
  },
  component: Menu,
});

//export default Menu;
