import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonItem,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingTrip, ShoppingItem } from "../../models/ShoppingTrip";
import {
  editShoppingTrip,
  getShoppingItems,
} from "../../data/shoppingTrips/shoppingTrips.actions";
import ShoppingItemList from "./ShoppingItemList";
import NewShoppingItemLine from "./NewShoppingItemLine";

interface OwnProps {
  show: boolean;
  shoppingTrip: ShoppingTrip;
}

interface StateProps {
  shoppingItems: ShoppingItem[];
}

interface DispatchProps {
  editShoppingTrip: typeof editShoppingTrip;
  getShoppingItems: typeof getShoppingItems;
}

type ShoppingItemProps = OwnProps & StateProps & DispatchProps;

export const ShoppingItemModal: React.FC<ShoppingItemProps> = ({
  show,
  shoppingTrip,
  shoppingItems,
  editShoppingTrip,
  getShoppingItems,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedShoppingTrip, setModifiedShoppingTrip] = useState<
    ShoppingTrip
  >(shoppingTrip);

  const date = modifiedShoppingTrip.date;
  const notes = modifiedShoppingTrip.notes;

  const [showModal, setShowModal] = useState(show);
  //const [closeModal, setCloseModal] = useState(false);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    //if (!closeModal) {
    setShowModal(show);
    //}

    (async function loadData() {
      await getShoppingItems(shoppingTrip);
    })();
  }, [show, shoppingTrip]);

  /*const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedShoppingTrip({
      ...modifiedShoppingTrip,
      [fieldName]: fieldValue,
    });
  };*/

  const editCurrentShoppingTrip = async () => {
    editShoppingTrip(modifiedShoppingTrip);
    setShowToastCreated(true);
  };

  /*const closeModal = async () => {
    await editCurrentShoppingTrip();
    //await editShoppingTrip(modifiedShoppingTrip);
    setShowModal(false);
  };*/

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="shoppingItem-modal">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="5" sizeSm="3">Item</IonCol>
              <IonCol size="2">Unit</IonCol>
              <IonCol size="2" sizeSm="1">Qty</IonCol>
              <IonCol size="2">Price</IonCol>
              <IonCol sizeSm="3" className="ion-hide-sm-down">Notes</IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <ShoppingItemList
              //shoppingItems={shoppingItems}
              shoppingTrip={shoppingTrip}
              hide={false}
            />
            <IonRow>
              <NewShoppingItemLine shoppingTrip={shoppingTrip} />
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonButton onClick={editCurrentShoppingTrip} slot="end">
            Update Shopping Trip
          </IonButton>
          <IonButton
            onClick={() => {
              //setCloseModal(true);
              //editShoppingTrip(modifiedShoppingTrip);
              setShowModal(false);
            }}
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your shopping trip has been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingItems: state.shoppingTripData.shoppingItems,
  }),
  mapDispatchToProps: { editShoppingTrip, getShoppingItems },
  component: ShoppingItemModal,
});
