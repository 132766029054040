import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Item, Unit, ItemCategory } from "../../models/Item";
import { addNewItem } from "../../data/items/items.actions";
import SelectUnitModal from "../units/SelectUnitModal";
import SelectItemCategory from "../itemCategories/SelectItemCategoryModal";
import { allowedToAddItem } from "../../data/premiumChecks";
import PremiumAlert from "../layout/PremiumAlert";

interface OwnProps {
  show: boolean;
  partialName: string;
  onCreated: (item: Item) => void;
}

interface StateProps {
  units: Unit[];
  itemCategories: ItemCategory[];
  items: Item[];
  premium: boolean;
}

interface DispatchProps {
  addNewItem: typeof addNewItem;
}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type CreateItemProps = OwnProps & StateProps & DispatchProps;

export const CreateItemModal: React.FC<CreateItemProps> = ({
  show,
  partialName,
  onCreated,
  units,
  itemCategories,
  items,
  premium,
  addNewItem,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState<Item>({
    _id: "",
    userId: "",
    name: partialName,
    defaultUnitId: {
      _id: "",
      name: "",
      symbol: "",
    },
    itemCategories: [],
    avePrice: 0,
    lastPrice: 0,
    storeLastPrice: [],
  });

  //const id = item.id;
  const name = item.name;
  const defaultUnitId = item.defaultUnitId;
  const itemItemCategories = item.itemCategories;

  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);
  const [showPremiumAlert, setShowPremiumAlert] = useState(false);

  useEffect(() => {
    //console.log(show)
    setShowModal(show);
    setItem({ ...item, name: partialName });
  }, [show, partialName]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setItem({ ...item, [fieldName]: fieldValue });
  };

  /*const onChangeUnit = (fieldValue: Unit) => {
    setItem({ ...item, defaultUnitId: fieldValue });
  };*/

  const onChangeUnit = (fieldValue: Unit) => {
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
        const filteredUnits = units.filter(
          (unit) => unit.name === fieldValue.name
        );
        if (filteredUnits.length > 0) {
          setItem({ ...item, defaultUnitId: filteredUnits[0] });
        } else {
          setItem({ ...item, defaultUnitId: fieldValue });
        }
      } else {
        setItem({ ...item, defaultUnitId: fieldValue });
      }
    }
  };

  /*const onChangeCategory = (fieldValue: ItemCategory[]) => {
    setItem({ ...item, itemCategories: fieldValue });
  };*/

  const onChangeCategory = (fieldValue: ItemCategory[]) => {
    if (fieldValue !== undefined) {
      const newItemCategories = fieldValue.filter(
        (newItemCategory) => newItemCategory._id === ""
      );
      if (newItemCategories.length > 0) {
        const filteredItemCategories = itemCategories.filter(
          (itemCategory) => itemCategory.name === newItemCategories[0].name
        );
        if (filteredItemCategories.length > 0) {
          const itemCategoryArray = fieldValue.filter(
            (newItemCategory) => newItemCategory._id !== ""
          );
          itemCategoryArray.push(filteredItemCategories[0]);
          setItem({ ...item, itemCategories: itemCategoryArray });
        } else {
          setItem({ ...item, itemCategories: fieldValue });
        }
      } else {
        setItem({ ...item, itemCategories: fieldValue });
      }
    }
  };

  const onPremiumAlertClose = () => {
    setShowPremiumAlert(false);
  };

  const insertItem = async () => {
    if (allowedToAddItem(items.length, premium) === true) {
      await addNewItem(item);
      setShowToastCreated(true);
      setTimeout(() => {
        setShowModal(false);
        onCreated(item);
        setItem({
          _id: "",
          userId: "",
          name: "",
          defaultUnitId: {
            _id: "",
            name: "",
            symbol: "",
          },
          itemCategories: [],
          avePrice: 0,
          lastPrice: 0,
          storeLastPrice: [],
        });
      }, 2000);
    } else {
      setShowPremiumAlert(true);
    }
  };

  return (
    <IonContent id="create-item-modal">
      <IonModal isOpen={showModal} id="create-item-modal">
        <p>Please enter the details of your new item</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the item name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel slot="start"> Default measuring unit:</IonLabel>
          <SelectUnitModal
            unit={defaultUnitId}
            onChanged={onChangeUnit}
            slot="end"
          />
        </IonItem>
        <IonItem className="select-item">
          <IonLabel slot="start">Categories: </IonLabel>
          <SelectItemCategory
            itemItemCategories={itemItemCategories}
            onChanged={onChangeCategory}
            slot="end"
          />
        </IonItem>
        <IonItem>
          <IonButton onClick={async () => await insertItem()} slot="end">
            Create Item
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your item has been created."
          duration={1500}
          color="primary"
        />
      </IonModal>
      <PremiumAlert
        show={showPremiumAlert}
        onClosed={onPremiumAlertClose}
        type="items"
      />
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    units: state.data.units,
    itemCategories: state.data.itemCategories,
    premium: state.user.user.premium,
    items: state.data.items,
  }),
  mapDispatchToProps: { addNewItem },
  component: CreateItemModal,
});
