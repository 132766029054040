import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Unit } from "../../models/Item";
import SelectDefaultUnitModal from "./SelectDefaultUnitModal";
import { addNewUnit } from "../../data/items/items.actions";
//import "./CreateUnitModal.scss";

interface OwnProps {
  show: boolean;
}

interface StateProps {}

interface DispatchProps {
  addNewUnit: typeof addNewUnit;
}

interface CreateDefaultUnitProps extends OwnProps, StateProps, DispatchProps {}

const CreateDefaultUnitModal: React.FC<CreateDefaultUnitProps> = ({
  show,
  addNewUnit,
}) => {
  const [units, setUnits] = useState<Unit[]>([]);

  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
    //console.log(`Show: ${show}`);
  }, [show]);

  const insertUnits = async () => {
    console.log(units);
    for await (let unit of units) {
      await addNewUnit(unit);
      console.log(unit);
    }

    setUnits([]);

    setShowToastCreated(true);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-default-unit-modal">
        <p>Please select the units you wish to create</p>
        <SelectDefaultUnitModal
          onChanged={setUnits}
          slot={""}
          show={true}
        ></SelectDefaultUnitModal>
        <IonItem slot="end">
          <IonButton onClick={insertUnits} slot="end">
            Create Units
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your units have been added."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { addNewUnit },
  component: CreateDefaultUnitModal,
});
