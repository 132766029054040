import React, { useState, useEffect } from "react";
import {
  //IonHeader,
  //IonToolbar,
  //IonTitle,
  IonContent,
  IonPage,
  //IonButtons,
  //IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
} from "@ionic/react";
import "./Login.scss";
import { ResetPasswordDetails } from "../models/User";
import { resetPassword, clearError } from "../data/user/user.actions";
import { useParams } from "react-router";
//import { useAuth, login, clearErrors } from "../data/user/AuthState";
import { connect } from "../data/connect";
import { Footer } from "../components/layout/Footer";
import { RouteComponentProps } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
    resetPassword: typeof resetPassword;
  clearError: typeof clearError;
}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
}

interface ResetPasswordProps extends OwnProps, DispatchProps, StateProps {}

const ResetPassword: React.FC<ResetPasswordProps> = ({
    resetPassword,
  clearError,
  isAuthenticated,
  error,
  history,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordError2, setPasswordError2] = useState(false);

  const [resetPasswordDetails, setResetPasswordDetails] = useState<ResetPasswordDetails>({
    email: "",
    password: "",
    password2: "",
    resetToken: ""
  });
  //const location = useLocation();
  const { email, resetToken, password, password2 } = resetPasswordDetails;
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }    

    if (token) {
        setResetPasswordDetails({ ...resetPasswordDetails, "resetToken": token})
    }

    if (error === "Invalid Credentials") {
      clearError();
    }
  }, [error, isAuthenticated, history]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setResetPasswordDetails({ ...resetPasswordDetails, [fieldName]: fieldValue });
  };

  const onClick = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!email) {
      setEmailError(true);
    } else if (!password) {
        setPasswordError(true)
    } else if (password !== password2) {
        setPasswordError2(true)
    }

    await resetPassword(resetPasswordDetails)
    history.push("/");
  };

  return (
    <IonPage id="forgotpassword-page">
      <IonContent>
        <div className="login-logo">
          <img src="assets/icon/Logo.png" alt="Shopping Tracker logo" />
        </div>

        <form noValidate onSubmit={onClick}>
          <IonList className="login-block">
            <IonItem>
              <IonLabel position="stacked" color="primary">
                Email
              </IonLabel>
              <IonInput
                name="email"
                type="email"
                value={email}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => onChange("email", e.detail.value!)}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && emailError && (
              <IonText color="danger">
                <p className="ion-padding-start">Email is required</p>
              </IonText>
            )}

            <IonItem>
              <IonLabel position="stacked" color="primary">
                New password
              </IonLabel>
              <IonInput
                name="password"
                type="password"
                value={password}
                onIonChange={(e) => {
                  onChange("password", e.detail.value!);
                  setPasswordError(false);
                }}
              ></IonInput>
            </IonItem>

            {formSubmitted && passwordError && (
              <IonText color="danger">
                <p className="ion-padding-start">Password is required</p>
              </IonText>
            )}

<IonItem>
              <IonLabel position="stacked" color="primary">
                Repeat password
              </IonLabel>
              <IonInput
                name="password2"
                type="password"
                value={password2}
                onIonChange={(e) => {
                  onChange("password2", e.detail.value!);
                  setPasswordError2(false);
                }}
              ></IonInput>
            </IonItem>

            {formSubmitted && passwordError2 && (
              <IonText color="danger">
                <p className="ion-padding-start">Please make sure that your passwords match</p>
              </IonText>
            )}

            {formSubmitted && !passwordError2 && !passwordError && !emailError && (
              <IonText color="primary">
                <p className="ion-padding-start">Your password has been reset!</p>
              </IonText>
            )}
          </IonList>

          <IonRow className="login-block">
            <IonCol>
              <IonButton type="submit" expand="block">
                Reset password
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
  }),
  mapDispatchToProps: {
    resetPassword,
    clearError,
  },
  component: ResetPassword,
});
