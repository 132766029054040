import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Unit } from "../../models/Item";
import { editUnit } from "../../data/items/items.actions";

interface OwnProps {
  show: boolean;
  unit: Unit;
}

interface StateProps {}

interface DispatchProps {
  editUnit: typeof editUnit;
}

interface EditUnitProps extends OwnProps, StateProps, DispatchProps {}

const EditUnitModal: React.FC<EditUnitProps> = ({ show, unit, editUnit }) => {
  const [modifiedUnit, setModifiedUnit] = useState<Unit>(unit);
  const name = modifiedUnit.name;
  const symbol = modifiedUnit.symbol;
  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) =>
    setModifiedUnit({ ...modifiedUnit, [fieldName]: fieldValue });

  const updateUnit = async () => {
    console.log(name);
    console.log(modifiedUnit);
    editUnit(modifiedUnit);
    setShowToastCreated(true);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="edit-unit-modal">
        <p>Modify unit</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the unit name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> Symbol:</IonLabel>
          <IonInput
            value={symbol}
            placeholder="Enter the unit symbol"
            onIonChange={(e) => onChange("symbol", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem slot="end">
          <IonButton onClick={updateUnit} slot="end">
            Update Unit
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your unit have been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { editUnit },
  component: EditUnitModal,
});
