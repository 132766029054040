import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { IonButton, IonItem, IonLabel, IonTitle, IonProgressBar } from "@ionic/react";
import { ShoppingTrip, ShoppingItem } from "../../models/ShoppingTrip";
import { ItemCategory } from "../../models/Item";
import { ChartDataset, ChartData, ChartDateParameters } from "../../models/ChartObjects";
import { getShoppingItems } from "../../data/shoppingTrips/shoppingTrips.actions";
import { getItemCategoryChartData, setItemCategoryChartLoading } from "../../data/items/items.actions"
import { connect } from "../../data/connect";
import { Pie, Bar } from "react-chartjs-2";

interface OwnProps {
  selectionStart: String;
  selectionEnd: String;
}

interface StateProps {
  shoppingTrips: ShoppingTrip[];
  shoppingItems: ShoppingItem[];
  itemCategories: ItemCategory[];
  itemCategoryChartData: ChartData;
  itemCategoryChartLoading?: boolean;
}

interface DispatchProps {
    getShoppingItems: typeof getShoppingItems;
    getItemCategoryChartData: typeof getItemCategoryChartData;
    setItemCategoryChartLoading: typeof setItemCategoryChartLoading;
}

interface ItemCategoriesChartProps extends OwnProps, StateProps, DispatchProps {}

const ItemCategoriesChart: React.FC<ItemCategoriesChartProps> = ({
  selectionStart,
  selectionEnd,
  shoppingTrips,
  shoppingItems,
  itemCategories,
  itemCategoryChartData,
  itemCategoryChartLoading,
  getShoppingItems,
  getItemCategoryChartData,
  setItemCategoryChartLoading,
}) => {
  const [labels, setLabels] = useState<String[]>([]);
  const [datasets, setDatasets] = useState<ChartDataset[]>([]);
  //const [loading, setLoading] = useState(false);
  const chartRef = useRef<Pie>(null);

  //var loading = false;
  //console.log(loading);

  var data = [] as Number[];

  useEffect(() => {
    //setLoading(true);
    getData();   
    //setLoading(false); 
  }, [itemCategoryChartData]);

  useLayoutEffect(() => {
    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.chartInstance.update();
    }
  }, [datasets, labels]);

  var dynamicColors = function (alpha: number) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    //var a = Math.random() * 1;
    return "rgb(" + r + "," + g + "," + b + ", " + alpha + ")";
  };

  const refreshData = async () => {
    //setLoading(true);
    await setItemCategoryChartLoading(true);
    let chartDates = {startDate: selectionStart, endDate: selectionEnd} as ChartDateParameters;
      await getItemCategoryChartData(chartDates);
      //setLoading(false);
  }

  const getData = async () => {
    setLabels(itemCategoryChartData.labels);
    setDatasets(itemCategoryChartData.datasets);
    /*var dateFilteredTrips = shoppingTrips.filter(
      (trip) =>
        trip.date.substring(0, 10) >= selectionStart.substring(0, 10) &&
        trip.date.substring(0, 10) <= selectionEnd.substring(0, 10)
    );

    itemCategories.forEach((itemCategory) => {
        if (!labels.includes(itemCategory.name))
            labels.push(itemCategory.name)
    })
    
    labels.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }

      if (n1 < n2) {
        return -1;
      }

      return 0;
    });

    if (labels.length >= 0) {
      setLabels(labels);
    }    */

    /*var backgroundColours = [];
    var borderColours = [] as string[];
    var hoverBackgroundColours = [] as string[];
    var hoverBorderColours = [] as string[];*/

    /*for await (const label of labels) {
        var total = 0;
        var itemCat = itemCategories.filter(
            (itemCategory) =>
                itemCategory.name === label
            )

        for await (const filteredTrip of dateFilteredTrips) {
            await getShoppingItems(filteredTrip)
            console.log (shoppingItems)

            shoppingItems.forEach((item) => {
                console.log(item)
                if (item.itemId.itemCategories.includes(itemCat[0]))
                    total +=(Number((item.price * item.qty).toFixed(2)));
            })
        }

        //data.push(total)
        var colour = dynamicColors(0.2);*/
        /*backgroundColours.push(colour);
        borderColours.push(colour.replace("0.2", "1")); 
        hoverBackgroundColours.push(colour.replace("0.2", "0.4"));
        hoverBorderColours.push(colour.replace("0.2", "1"));*/

        /*var dataSet = {
            label: label,
            backgroundColor: colour,
            borderColor: colour.replace("0.2", "1"), 
            borderWidth: 1,
            hoverBackgroundColor: colour.replace("0.2", "0.4"),
            hoverBorderColor: colour.replace("0.2", "1"),
            data: [total],
          }

        var index = datasets.findIndex((ds) => ds.label === dataSet.label);
        if (index >= 0) {
            datasets[index] = dataSet;
        } else {
            datasets.push(dataSet);
        }
    }   */         

    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.chartInstance.update();
      chartRef.current.chartInstance.resize();
    }
  };

  const dataTest = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    title: {
      text: "Spending per item category from " + selectionStart + " to " + selectionEnd + " (Note that an item can belong to more than one category)",
      display: true,
    },
    responsive: true,
    //maintainAspectRatio: false,
    //aspectRatio: 0.5,
    /*scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          labelString: "€",
        },
      ],
    },*/
    animation: {
      duration: 0,
    },
  };

  /*if (loading) {
    return (
      <IonItem>
        <IonTitle>Loading...</IonTitle>
        <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
      </IonItem>
    );
  }*/

  return (
    <div /*style={{position: "relative", height:"40vh", width:"80vw"}}*/>
      <IonItem>
        <IonTitle className="ion-text-wrap">Spending per item category</IonTitle>
        <IonButton size="small" shape="round" onClick={async () => await refreshData()}>
          <IonLabel>
            Change colours
          </IonLabel>          
        </IonButton>
      </IonItem>
      {itemCategoryChartLoading? 
        <IonItem>
          <IonTitle>Loading...</IonTitle>
          <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
        </IonItem>
        : <Pie ref={chartRef} data={dataTest} options={options} redraw></Pie>
      }
      
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingTrips: state.shoppingTripData.shoppingTrips,
    shoppingItems: state.shoppingTripData.shoppingItems,
    itemCategories: state.data.itemCategories,
    itemCategoryChartData: state.data.itemCategoryChartData,
    itemCategoryChartLoading: state.data.itemCategoryChartLoading,
  }),
  mapDispatchToProps: { 
      getShoppingItems,
      getItemCategoryChartData,
      setItemCategoryChartLoading,
     },
  component: React.memo(ItemCategoriesChart),
});
