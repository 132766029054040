import { Plugins } from "@capacitor/core";
import axios from "axios";

import { getDefaultItems, getDefaultItemCategories } from "./defaultLists";

const { Storage } = Plugins;

const HAS_LOGGED_IN = "hasLoggedIn";
const HAS_SEEN_TUTORIAL = "hasSeenTutorial";
const USERNAME = "username";

export const getItemData = async () => {
  const resItems = await axios.get("/api/items");
  const items = await resItems.data; //.json();
  const resUnits = await axios.get("/api/units"); //fetch("/api/units");
  const units = await resUnits.data;
  const resItemCategories = await axios.get("/api/itemcategories");
  const itemCategories = await resItemCategories.data;

  const defaultItems = getDefaultItems();
  const defaultItemCategories = getDefaultItemCategories();

  const data = {
    units,
    items,
    itemCategories,
    defaultItems,
    defaultItemCategories,
  };
  return data;
};

export const getStoreData = async () => {
  const resStores = await axios.get("/api/stores");
  const stores = await resStores.data;

  const data = {
    stores,
  };
  return data;
};

export const getShoppingTripData = async () => {
  const res = await axios.get("/api/shoppingTrips");
  const shoppingTrips = await res.data;

  const data = {
    shoppingTrips,
  };
  return data;
};

export const getShoppingListData = async () => {
  const res = await axios.get("/api/shoppingLists");
  const shoppingLists = await res.data;

  const data = {
    shoppingLists,
  };
  return data;
};

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: USERNAME }),
  ]);
  const isLoggedin = (await response[0].value) === "true";
  const hasSeenTutorial = (await response[1].value) === "true";
  const username = (await response[2].value) || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    username,
  };
  /*if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await fetch(`/api/users/${userId}`);
    const result = await res.json();
    console.info(result);

    //dispatch(setUser(result));
    return result;
  } catch (error) {
    console.log("Error in users.actions -> loadUser");
    //dispatch(loginFailure(error));
  }*/
  return data;
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  /*await Storage.set({
    key: HAS_SEEN_TUTORIAL,
    value: JSON.stringify(hasSeenTutorial),
  });*/
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios.post("/api/userseentutorial", config);
};

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
};

export const scanReceipt = async (data: File) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  console.log(data)
  let formData = new FormData();
  formData.append("image", data);
  console.log(formData)
  const res = await axios.post("/api/scanReceipt", formData, config);
  console.log(res);
}

export const loadData = async () => {
  await getItemData()
  await getStoreData()
  await getShoppingTripData()
  await getShoppingListData()
}
