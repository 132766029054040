import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { ShoppingListItem, ShoppingList } from "../../models/ShoppingList";
import { getShoppingListItems } from "../../data/shoppingLists/shoppingLists.actions";
import ShoppingListItemItem from "./ShoppingListItemItem";
import { connect } from "../../data/connect";

interface OwnProps {
  //shoppingItems: ShoppingItem[];
  shoppingList: ShoppingList;
  hide: boolean;
}

interface StateProps {
  shoppingListItems: ShoppingListItem[];
}

interface DispatchProps {
  getShoppingListItems: typeof getShoppingListItems;
}

interface ShoppingListItemListProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const ShoppingListItemList: React.FC<ShoppingListItemListProps> = ({
  //shoppingItems,
  shoppingList,
  hide,
  shoppingListItems,
  getShoppingListItems,
}) => {
  /*const [shoppingItemsList, setModifiedShoppingItemsList] = useState<
    ShoppingItem[]
  >(shoppingItems);*/

  useEffect(() => {
    (async function loadData() {
      if (shoppingList._id !== "") {
        //await getShoppingListItems(shoppingList);
      }      
    })();

    //setModifiedShoppingItemsList(shoppingItems);
  }, [shoppingList]); //, shoppingItems

  //console.log(shoppingItems);
  if (shoppingListItems.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Shopping Items Found</IonListHeader>
      </IonList>
    );
  }

  if (shoppingListItems[0].shoppingListId !== shoppingList._id && !hide) {
    return (
      <IonList>
        <IonListHeader>Loading...</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {shoppingListItems.map((shoppingListItem) => (
          <IonItemGroup key={`group-${shoppingListItem._id}`}>
            <ShoppingListItemItem
              shoppingListItem={shoppingListItem}
              shoppingList={shoppingList}
            ></ShoppingListItemItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingListItems: state.shoppingListData.shoppingListItems,
  }),
  mapDispatchToProps: { getShoppingListItems },
  component: ShoppingListItemList,
});
