import {
  IonContent,
  IonTitle,
  IonButton,
  IonItem,
  IonIcon,
  IonInput,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import UnitList from "../units/UnitList";
import CreateDefaultUnitModal from "../units/CreateDefaultUnitModal";
import SelectDefaultUnitModal from "../units/SelectDefaultUnitModal";
import { addNewUnit } from "../../data/items/items.actions";
import { Unit } from "../../models/Item";
import { addOutline, saveOutline } from "ionicons/icons";

interface OwnProps {}

interface StateProps {
  units: Unit[];
}

interface DispatchProps {
  addNewUnit: typeof addNewUnit;
}

type UnitTabProps = OwnProps & StateProps & DispatchProps;

const UnitContainer: React.FC<UnitTabProps> = ({ units, addNewUnit }) => {
  useEffect(() => {
    //loadItemData();
    // eslint-disable-next-line
  }, []);

  //console.log("Unit tab");

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreateLine, setShowCreateLine] = useState(false);
  const [showCreateDefaultModal, setShowCreateDefaultModal] = useState(false);

  const [unit, setUnit] = useState<Unit>({
    _id: "",
    name: "",
    symbol: "",
  });

  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarUnit");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  const name = unit.name;
  const symbol = unit.symbol;

  const onChange = (fieldName: string, fieldValue: string) => {
    setUnit({ ...unit, [fieldName]: fieldValue });
  };

  const onChanged = (units: Unit[]) => {};

  const insertUnit = async () => {
    //console.log(name);
    //console.log(unit);
    await addNewUnit(unit);
    setUnit({ _id: "", name: "", symbol: "" });
  };

  const showCreateUnitModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const showCreateDefaultUnitModal = () => {
    setShowCreateDefaultModal(!showCreateDefaultModal);
  };

  const showCreateUnitLine = () => {
    setShowCreateLine(!showCreateLine);
  };

  return (
    <IonContent class="scrollbarUnit">
      <IonItem>
        <IonButton
          size="small"
          shape="round"
          onClick={() => showCreateDefaultUnitModal()}
        >
          Add Default Units
        </IonButton>
        <SelectDefaultUnitModal
          onChanged={onChanged}
          slot={""}
          show={showCreateDefaultModal}
        />
      </IonItem>
      <UnitList units={units} listType="all" hide={false} />
      <IonItem hidden={!showCreateLine}>
        <IonInput
          value={name}
          placeholder="Enter the unit name"
          onIonChange={(e) => onChange("name", e.detail.value!)}
        ></IonInput>
        <IonInput
          value={symbol}
          placeholder="Enter the unit symbol"
          onIonChange={(e) => onChange("symbol", e.detail.value!)}
        ></IonInput>
        <IonIcon icon={saveOutline} color="primary" onClick={insertUnit} />
      </IonItem>
      <IonItem onClick={showCreateUnitLine}>
        <IonIcon icon={addOutline} /> Add a new unit
      </IonItem>
      <CreateDefaultUnitModal show={showCreateModal}></CreateDefaultUnitModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    units: state.data.units,
  }),
  mapDispatchToProps: { addNewUnit },
  component: React.memo(UnitContainer),
});
