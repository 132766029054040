import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  //IonItem,
  //IonButton,
  isPlatform,
  //getPlatforms,
} from "@ionic/react";
import React from "react";
//import { useParams } from "react-router";
//import { AddBtn } from "../components/layout/AddBtn";
import ChartsDashboard from "../components/charts/ChartsDashboard";
//import { Login } from "./Login";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { Footer } from "../components/layout/Footer";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
}

interface HomeProps extends OwnProps, DispatchProps, StateProps {}

const Home: React.FC<HomeProps> = ({ isAuthenticated, error, history }) => {
  //const { name } = useParams<{ name: string }>();
  //console.log(getPlatforms());

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonTitle size="large">Welcome!</IonTitle>
        <br />
        Welcome to the shopping app. Your tool to keep track of your shopping
        <br />
        <br />
        This is your personal dashboard, showing you some useful stats and
        updates (More stats coming soon)
        <br />
        {isPlatform("mobileweb") ? (
          <b>
            <br />
            Since you are using a mobile device this page will be best viewed in
            landscape (i.e. Please turn your phone horizontally)
          </b>
        ) : (
          <br />
        )}
        <br />
        <ChartsDashboard />
        <br />
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
  }),
  mapDispatchToProps: {},
  component: Home,
});
