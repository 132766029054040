import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
  IonCheckbox,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingListItem, ShoppingList } from "../../models/ShoppingList";
import { Item, Unit } from "../../models/Item";
import SelectUnitModal from "../units/SelectUnitModal";
import SelectItemModal from "../items/SelectItemModal";
import {
  editShoppingListItem,
  editShoppingList,
} from "../../data/shoppingLists/shoppingLists.actions";

interface OwnProps {
  show: boolean;
  shoppingListItem: ShoppingListItem;
  shoppingList: ShoppingList;
  onChanged: (shoppingListItem: ShoppingListItem) => void;
}

interface StateProps {}

interface DispatchProps {
  editShoppingList: typeof editShoppingList;
  editShoppingListItem: typeof editShoppingListItem;
}

type EditShoppingListItemProps = OwnProps & StateProps & DispatchProps;

export const EditShoppingListItemModal: React.FC<EditShoppingListItemProps> = ({
  show,
  shoppingListItem,
  shoppingList,
  onChanged,
  editShoppingList,
  editShoppingListItem,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedShoppingItem, setModifiedShoppingItem] = useState<
    ShoppingListItem
  >(shoppingListItem);

  const itemId = modifiedShoppingItem.itemId;
  const unitId = modifiedShoppingItem.unitId;
  const qty = modifiedShoppingItem.qty;
  const completed = modifiedShoppingItem.completed;
  const notes = modifiedShoppingItem.notes;

  const [showModal, setShowModal] = useState(show);
  const [showItemSelectModal, setShowItemSelectModal] = useState(false);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedShoppingItem({
      ...modifiedShoppingItem,
      [fieldName]: fieldValue,
    });
  };

  const onChangeUnit = (fieldValue: Unit) => {
    setModifiedShoppingItem({ ...shoppingListItem, unitId: fieldValue });
  };

  const onChangeItem = (fieldValue: Item) => {
    setModifiedShoppingItem({
      ...shoppingListItem,
      itemId: fieldValue,
      unitId: fieldValue.defaultUnitId,
    });
  };

  const onChangeCompleted = (value: boolean) => {
    setModifiedShoppingItem({
      ...shoppingListItem,
      completed: value,
    });
  };

  const showSelectItemModal = () => {
    setShowItemSelectModal(!showItemSelectModal);
  };

  const editCurrentShoppingListItem = async () => {
    editShoppingListItem(modifiedShoppingItem);
    editShoppingList(shoppingList);
    //onChanged(modifiedShoppingItem);

    setShowToastCreated(true);
  };

  return (
    <IonContent>
      <SelectItemModal
        item={itemId}
        onChanged={onChangeItem}
        show={showItemSelectModal}
      />
      <IonModal isOpen={showModal} id="edit-shoppingListItem-modal">
        <p>Please modify the details you wish to change</p>
        <IonItem>
          <IonLabel>Item:</IonLabel>
          <IonInput
            type="text"
            value={itemId.name}
            placeholder="Item"
            onClick={showSelectItemModal}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Unit:</IonLabel>
          <SelectUnitModal unit={unitId} onChanged={onChangeUnit} slot={""} />
        </IonItem>
        <IonItem>
          <IonLabel>Quantity:</IonLabel>
          <IonInput
            type="number"
            inputMode="decimal"
            value={qty}
            placeholder="Qty"
            onIonChange={(e) => onChange("qty", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Completed:</IonLabel>
          <IonCheckbox
            checked={completed}
            onIonChange={(e) => onChangeCompleted(e.detail.checked)}
          />
        </IonItem>
        <IonItem>
          <IonLabel> Notes:</IonLabel>
          <IonInput
            value={notes}
            placeholder="Enter your notes"
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={async () => {
              editCurrentShoppingListItem();
            }}
            slot="end"
          >
            Update Shopping Item
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your shopping item has been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { editShoppingListItem, editShoppingList },
  component: EditShoppingListItemModal,
});
