import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
  IonDatetime,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import { Store } from "../../models/Store";
import SelectStoreModal from "../stores/SelectStoreModal";
import { editShoppingTrip } from "../../data/shoppingTrips/shoppingTrips.actions";
//import { wait } from "@testing-library/react";

interface OwnProps {
  show: boolean;
  shoppingTrip: ShoppingTrip;
}

interface StateProps {}

interface DispatchProps {
  editShoppingTrip: typeof editShoppingTrip;
}

type EditShoppingTripProps = OwnProps & StateProps & DispatchProps;

export const EditShoppingTripModal: React.FC<EditShoppingTripProps> = ({
  show,
  shoppingTrip,
  editShoppingTrip,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedShoppingTrip, setModifiedShoppingTrip] = useState<
    ShoppingTrip
  >(shoppingTrip);

  const date = modifiedShoppingTrip.date;
  const notes = modifiedShoppingTrip.notes;

  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedShoppingTrip({
      ...modifiedShoppingTrip,
      [fieldName]: fieldValue,
    });
  };

  const onChangeStore = (fieldValue: Store) => {
    setModifiedShoppingTrip({ ...shoppingTrip, storeId: fieldValue });
  };

  const insertShoppingTrip = async () => {
    editShoppingTrip(modifiedShoppingTrip);
    setShowToastCreated(true);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-shoppingTrip-modal">
        <p>Please modify the details you wish to change</p>
        <IonItem>
          <IonLabel>Date:</IonLabel>
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={date}
            placeholder="Enter the date it took place"
            onIonChange={(e) => onChange("Date", e.detail.value!)}
            slot="end"
          ></IonDatetime>
        </IonItem>
        <IonItem>
          <IonLabel>Store:</IonLabel>
          <SelectStoreModal
            store={shoppingTrip.storeId}
            onChanged={onChangeStore}
            slot="end"
          />
        </IonItem>
        <IonItem>
          <IonLabel> Notes:</IonLabel>
          <IonInput
            value={notes}
            placeholder="Enter your notes"
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton onClick={insertShoppingTrip} slot="end">
            Update Shopping Trip
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => {
            setShowToastCreated(false);
            setShowModal(false);
          }}
          message="Your shopping trip has been updated."
          duration={1000}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { editShoppingTrip },
  component: EditShoppingTripModal,
});
