import React from "react";
import "./ExploreContainer.css";
import SettingsContainer from "./SettingsContainer";
import ItemsContainer from "./ItemsContainer";
import ItemCategoryContainier from "./ItemCategoryContainer";
import StoresContainer from "./StoresContainer";
import ShoppingTripContainer from "./ShoppingTripContainer";
import ShoppingListContainer from "./ShoppingListContainer";
import UnitContainer from "./UnitContainer";
import ChangeLogContainer from "./ChangeLogContainer";

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {  
  switch (name) {
    case "Settings":
      return <SettingsContainer name={name} />;
    case "ChangeLog":
      return <ChangeLogContainer />;
    case "Items":
      return <ItemsContainer />;
    case "ItemCategories":
      return <ItemCategoryContainier />;
    case "Stores":
      return <StoresContainer />;
    case "ShoppingTrips":
      return <ShoppingTripContainer />;
    case "ShoppingLists":
      return <ShoppingListContainer />;
    case "Units":
      return <UnitContainer />;
    default:
      return (
        <div className="container">
          <strong>{name}</strong>
          <p>
            Coming soon!!!
            <br />
            Explore{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ionicframework.com/docs/components"
            >
              UI Components
            </a>
          </p>
        </div>
      );
  }
};

export default ExploreContainer;
