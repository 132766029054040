import React, { useState, useEffect } from "react";
import { IonItem, IonIcon, IonInput, IonCheckbox } from "@ionic/react";
import {
  editShoppingList,
  //addNewShoppingListItem,
  addNewShoppingListItem,
  addNewShoppingList,
} from "../../data/shoppingLists/shoppingLists.actions";
import { ShoppingList, ShoppingListItem } from "../../models/ShoppingList";
import { Item } from "../../models/Item";
import SelectItemModal from "../items/SelectItemModal";
import { connect } from "../../data/connect";
import { addOutline, saveOutline } from "ionicons/icons";

interface OwnProps {
  shoppingList: ShoppingList;
}

interface StateProps {
  items: Item[]
}

interface DispatchProps {
  editShoppingList: typeof editShoppingList;
  //addNewShoppingListItem: typeof addNewShoppingListItem;
  addNewShoppingListItem: typeof addNewShoppingListItem;
  addNewShoppingList: typeof addNewShoppingList;
}

interface NewShoppingListItemLineProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const NewShoppingListItemLine: React.FC<NewShoppingListItemLineProps> = ({
  shoppingList,
  items,
  editShoppingList,
  //addNewShoppingListItem,
  addNewShoppingListItem,
  addNewShoppingList,
}) => {
  const [showCreateLine, setShowCreateLine] = useState(false);

  /*
  itemId: Item;
  unitId: Unit;
  qty: number;
  price: number;
  notes: string;
   */
  const [modifiedShoppingList, setModifiedShoppingList] = useState<
    ShoppingList
  >(shoppingList);
  const [showItemSelectModal, setShowItemSelectModal] = useState(false);
  const [shoppingListItem, setShoppingListItem] = useState<ShoppingListItem>({
    _id: "",
    shoppingListId: "",
    itemId: {
      _id: "",
      userId: "",
      name: "",
      defaultUnitId: {
        _id: "",
        name: "",
        symbol: "",
      },
      itemCategories: [],
      avePrice: 0,
      lastPrice: 0,
      storeLastPrice: [],
    },
    unitId: {
      _id: "",
      name: "",
      symbol: "",
    },
    qty: 0,
    completed: false,
    notes: "",
  });

  const itemId = shoppingListItem.itemId;
  //const unitId = shoppingListItem.unitId;
  const qty = shoppingListItem.qty;
  const completed = shoppingListItem.completed;
  const notes = shoppingListItem.notes;

  useEffect(() => {}, [modifiedShoppingList]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setShoppingListItem({ ...shoppingListItem, [fieldName]: fieldValue });
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      insertShoppingListItem()
    }
  }

  const onChangeItem = (fieldValue: Item) => {
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
          const filteredItems = items.filter((item) => item.name === fieldValue.name)
          //console.log(filteredItems)
          //onChanged(filteredStores[0]);
          if (filteredItems.length > 0) {
            setShoppingListItem({ ...shoppingListItem, itemId: filteredItems[0] });
          } else {
            setShoppingListItem({
              ...shoppingListItem,
              itemId: fieldValue,
            });
          }
          
        } else {
          setShoppingListItem({
            ...shoppingListItem,
            itemId: fieldValue,
          });
        } 
    }
    /*setShoppingListItem({
      ...shoppingListItem,
      itemId: fieldValue,
    });*/
  };

  const onChangeCompleted = (value: boolean) => {
    setShoppingListItem({
      ...shoppingListItem,
      completed: value,
    });
  };

  const addShoppingListItem = async (shoppingListItem: ShoppingListItem) => {
    if (shoppingListItem.itemId._id === "") {
      const filteredItems = items.filter((item) => item.name === shoppingListItem.itemId.name)
      //console.log(filteredItems)
      if (filteredItems.length > 0) {
        shoppingListItem.itemId = filteredItems[0];
      }
    }

    shoppingListItem.shoppingListId = shoppingList._id;
    await addNewShoppingListItem(shoppingListItem);
  };

  const clearShoppingListItem = async () => {
    setShoppingListItem({
      _id: "",
      shoppingListId: "",
      itemId: {
        _id: "",
        userId: "",
        name: "",
        defaultUnitId: {
          _id: "",
          name: "",
          symbol: "",
        },
        itemCategories: [],
        avePrice: 0,
        lastPrice: 0,
        storeLastPrice: [],
      },
      unitId: {
        _id: "",
        name: "",
        symbol: "",
      },
      qty: 0,
      completed: false,
      notes: "",
    });
  };

  const showCreateShoppingListLine = () => {
    /*console.log(shoppingList);
    if (shoppingList._id === "") {
      addNewShoppingList(shoppingList);
    } */    
    setShowCreateLine(!showCreateLine);
  };

  const showSelectItemModal = () => {
    setShowItemSelectModal(!showItemSelectModal);
  };

  const insertShoppingListItem = async () => {
    await addShoppingListItem(shoppingListItem);
    await editShoppingList(shoppingList);

    await clearShoppingListItem();
    //showCreateShoppingListLine();
  };

  return (
    <>
      <IonItem hidden={!showCreateLine} class="ion-no-padding" onKeyPress={(e) => onKeyPress(e)}>
        <IonInput
          type="text"
          value={itemId.name}
          placeholder="Item"
          //disabled={true}
          onIonChange={showSelectItemModal}
          onClick={showSelectItemModal}
        ></IonInput>
        <IonInput
          type="number"
          inputMode="decimal"
          value={qty}
          placeholder="Qty"
          onIonChange={(e) => onChange("qty", e.detail.value!)}
          className="ion-text-center"
        ></IonInput>  
        <IonInput
          type="text"
          value={notes}
          placeholder="Add a note"
          onIonChange={(e) => onChange("notes", e.detail.value!)}
          className="ion-hide-sm-down"
        ></IonInput>
        <IonIcon
          icon={saveOutline}
          color="primary"
          onClick={async () => {
            insertShoppingListItem();
          }}
          slot="end"
        />
      </IonItem>
      <IonItem hidden={showCreateLine} onClick={showCreateShoppingListLine}>
        <IonIcon icon={addOutline} /> Add a new item
      </IonItem>
      <SelectItemModal
        item={itemId}
        onChanged={onChangeItem}
        show={showItemSelectModal}
      />
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    items: state.data.items
  }),
  mapDispatchToProps: {
    editShoppingList,
    addNewShoppingListItem,
    addNewShoppingList,
  },
  component: React.memo(NewShoppingListItemLine),
});
