import { StoresActions } from "./stores.actions";
import { StoresState } from "./stores.state";

export const storesReducer = (
  state: StoresState,
  action: StoresActions
): StoresState => {
  switch (action.type) {
    case "set-stores-loading": {
      return { ...state, loading: action.isLoading };
    }
    case "set-stores-data": {
      return { ...state, ...action.data };
    }
    case "get-store": {
      return { ...state, currentStore: action.data };
    }
    case "add-store": {
      return { ...state, stores: [...state.stores, action.data] };
    }
    case "update-store": {
      return {
        ...state,
        stores: state.stores.map((store) =>
          store._id === action.data._id ? action.data : store
        ),
      };
    }
    case "delete-store": {
      return {
        ...state,
        stores: state.stores.filter((store) => store._id !== action.data._id),
      };
    }
    case "update-filtered-items": {
      return { ...state, filteredItems: action.filteredItems };
    }
    case "set-search-text": {
      return { ...state, searchText: action.searchText };
    }
  }
};
