import React, { useState, useEffect } from "react";
import {
  IonItem,
  //IonDatetime,
  IonIcon,
  IonInput,
  //IonCol,
  //IonRow,
  //IonItemGroup,
  //IonText,
} from "@ionic/react";
import {
  editShoppingTrip,
  //addNewShoppingTripItem,
  addNewShoppingItem,
} from "../../data/shoppingTrips/shoppingTrips.actions";
import { ShoppingTrip, ShoppingItem } from "../../models/ShoppingTrip";
import { Item } from "../../models/Item";
//import SelectUnitModal from "../units/SelectUnitModal";
import SelectItemModal from "../items/SelectItemModal";
import { connect } from "../../data/connect";
import { addOutline, saveOutline } from "ionicons/icons";
import { loadItemData } from "../../data/items/items.actions";

interface OwnProps {
  shoppingTrip: ShoppingTrip;
}

interface StateProps {
  items: Item[];
}

interface DispatchProps {
  editShoppingTrip: typeof editShoppingTrip;
  //addNewShoppingTripItem: typeof addNewShoppingTripItem;
  addNewShoppingItem: typeof addNewShoppingItem;
  loadItemData: typeof loadItemData;
}

interface NewShoppingItemLineProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const NewShoppingItemLine: React.FC<NewShoppingItemLineProps> = ({
  shoppingTrip,
  items,
  editShoppingTrip,
  //addNewShoppingTripItem,
  addNewShoppingItem,
  loadItemData,
}) => {
  const [showCreateLine, setShowCreateLine] = useState(false);

  /*
  itemId: Item;
  unitId: Unit;
  qty: number;
  price: number;
  notes: string;
   */
  const [modifiedShoppingTrip, setModifiedShoppingTrip] =
    useState<ShoppingTrip>(shoppingTrip);
  const [showItemSelectModal, setShowItemSelectModal] = useState(false);
  const [shoppingItem, setShoppingItem] = useState<ShoppingItem>({
    _id: "",
    shoppingTripId: "",
    itemId: {
      _id: "",
      userId: "",
      name: "",
      defaultUnitId: {
        _id: "",
        name: "",
        symbol: "",
      },
      itemCategories: [],
      avePrice: 0,
      lastPrice: 0,
      storeLastPrice: [],
    },
    unitId: {
      _id: "",
      name: "",
      symbol: "",
    },
    qty: 0,
    price: 0,
    notes: "",
  });

  const itemId = shoppingItem.itemId;
  const unitId = shoppingItem.unitId;
  const qty = shoppingItem.qty;
  const price = shoppingItem.price;
  const notes = shoppingItem.notes;

  useEffect(() => {
    //console.log("Changed!");
    //console.log(modifiedShoppingTrip);
    //editShoppingTrip(modifiedShoppingTrip);
    setModifiedShoppingTrip(shoppingTrip);
  }, [shoppingTrip]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setShoppingItem({ ...shoppingItem, [fieldName]: fieldValue });
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      insertShoppingItem();
    }
  };

  /*const onChangeUnit = (fieldValue: Unit) => {
    setShoppingItem({ ...shoppingItem, unitId: fieldValue });
  }; */

  const onChangeItem = (fieldValue: Item) => {
    //console.log(fieldValue)
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
        const filteredItems = items.filter(
          (item) => item.name === fieldValue.name
        );
        //console.log(filteredItems)
        //onChanged(filteredStores[0]);
        if (filteredItems.length > 0) {
          setShoppingItem({ ...shoppingItem, itemId: filteredItems[0] });
        } else {
          setShoppingItem({
            ...shoppingItem,
            itemId: fieldValue,
          });
        }
      } else {
        setShoppingItem({
          ...shoppingItem,
          itemId: fieldValue,
        });
      }
    }
    /*setShoppingItem({
      ...shoppingItem,
      //unitId: fieldValue.defaultUnitId,
      itemId: fieldValue,
    });*/

    // onChangeUnit(fieldValue.defaultUnitId);
    //setShoppingItem({ ...shoppingItem, unitId: fieldValue.defaultUnitId });
  };

  const addShoppingItem = async (shoppingItem: ShoppingItem) => {
    if (shoppingItem.itemId._id === "") {
      const filteredItems = items.filter(
        (item) => item.name === shoppingItem.itemId.name
      );
      //console.log(filteredItems)
      if (filteredItems.length > 0) {
        shoppingItem.itemId = filteredItems[0];
      }
    }
    shoppingItem.shoppingTripId = modifiedShoppingTrip._id;
    //console.log(modifiedShoppingTrip)
    //console.log(shoppingTrip)
    //await addNewShoppingTripItem(modifiedShoppingTrip, shoppingItem);
    await addNewShoppingItem(shoppingItem);
  };

  const clearShoppingItem = async () => {
    setShoppingItem({
      _id: "",
      shoppingTripId: "",
      itemId: {
        _id: "",
        userId: "",
        name: "",
        defaultUnitId: {
          _id: "",
          name: "",
          symbol: "",
        },
        itemCategories: [],
        avePrice: 0,
        lastPrice: 0,
        storeLastPrice: [],
      },
      unitId: {
        _id: "",
        name: "",
        symbol: "",
      },
      qty: 0,
      price: 0,
      notes: "",
    });
  };

  const showCreateShoppingTripLine = () => {
    setShowCreateLine(!showCreateLine);
  };

  const showSelectItemModal = () => {
    setShowItemSelectModal(!showItemSelectModal);
  };

  const insertShoppingItem = async () => {
    await addShoppingItem(shoppingItem);
    await editShoppingTrip(shoppingTrip);

    await clearShoppingItem();
    loadItemData();
    //showCreateShoppingTripLine();
    //<SelectUnitModal unit={unitId} onChanged={onChangeUnit} slot={""} />
    /*
    <IonInput
          type="text"
          size={2}
          disabled={true}
          value={itemId ? itemId.defaultUnitId.symbol : "Unit"}
        ></IonInput>
    */
  };

  return (
    <>
      <IonItem
        hidden={!showCreateLine}
        class="ion-no-padding"
        onKeyPress={(e) => onKeyPress(e)}
      >
        <IonInput
          type="text"
          value={itemId.name}
          placeholder="Item"
          //disabled={true}
          onIonChange={showSelectItemModal}
          onClick={showSelectItemModal}
        ></IonInput>
        <IonItem className="ion-text-end">
          {itemId.defaultUnitId.symbol !== ""
            ? itemId.defaultUnitId.symbol
            : ""}
        </IonItem>
        <IonInput
          type="number"
          inputMode="decimal"
          value={qty}
          placeholder="Qty"
          onIonChange={(e) => onChange("qty", e.detail.value!)}
          className="ion-text-right"
        ></IonInput>
        <IonInput
          type="number"
          inputMode="decimal"
          value={price}
          placeholder="Price"
          onIonChange={(e) => onChange("price", e.detail.value!)}
          className="ion-text-center"
        ></IonInput>
        <IonInput
          type="text"
          value={notes}
          placeholder="Add a note"
          onIonChange={(e) => onChange("notes", e.detail.value!)}
        ></IonInput>
        <IonIcon
          icon={saveOutline}
          color="primary"
          onClick={async () => {
            insertShoppingItem();
          }}
          slot="end"
        />
      </IonItem>
      <IonItem onClick={showCreateShoppingTripLine}>
        <IonIcon icon={addOutline} /> Add a new item
      </IonItem>
      <SelectItemModal
        item={itemId}
        onChanged={onChangeItem}
        show={showItemSelectModal}
      />
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({ items: state.data.items }),
  mapDispatchToProps: {
    editShoppingTrip,
    //addNewShoppingTripItem,
    addNewShoppingItem,
    loadItemData,
  },
  component: React.memo(NewShoppingItemLine),
});
