import React, { useState } from "react";
import { IonRow, IonItemGroup, IonAlert, IonIcon, IonCol } from "@ionic/react";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { Store } from "../../models/Store";
import { User } from "../../models/User"
import EditStoreModal from "./EditStoreModal";
import { removeStore } from "../../data/stores/stores.actions";
import { connect } from "../../data/connect";

interface OwnProps {
  store: Store;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  removeStore: typeof removeStore;
}

interface StoreItemProps extends OwnProps, StateProps, DispatchProps {}

const StoreItem: React.FC<StoreItemProps> = ({ store, user, removeStore }) => {
  /*
  name: string;
  symbol: string;
     */
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const showEditStoreModal = () => {
    setShowEditModal(!showEditModal);
  };
  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="5" onClick={showEditStoreModal}>
          {store.name}
        </IonCol>
        <IonCol size="2">{store.totalTrips}</IonCol>
        <IonCol size="2">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(store.totalSpent)}
        </IonCol>
        <IonCol size="2">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(
            store.totalTrips === 0 ? 0 : store.totalSpent / store.totalTrips
          )}
        </IonCol>
        <IonCol>
          <IonIcon icon={pencilOutline} onClick={() => showEditStoreModal()} />
          <IonIcon
            icon={trashOutline}
            onClick={() => setShowDeleteAlert(true)}
          />
        </IonCol>
      </IonRow>
      <EditStoreModal store={store} show={showEditModal} />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeStore(store);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({ user: state.user.user}),
  mapDispatchToProps: { removeStore },
  component: React.memo(StoreItem),
});
