import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Store } from "../../models/Store";
import { editStore } from "../../data/stores/stores.actions";

interface OwnProps {
  show: boolean;
  store: Store;
}

interface StateProps {}

interface DispatchProps {
  editStore: typeof editStore;
}

type EditStoreProps = OwnProps & StateProps & DispatchProps;

export const EditStoreModal: React.FC<EditStoreProps> = ({
  show,
  store,
  editStore,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedStore, setModifiedStore] = useState<Store>(store);

  //const id = item.id;
  const name = modifiedStore.name;
  const url = modifiedStore.url;

  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedStore({ ...modifiedStore, [fieldName]: fieldValue });
  };

  const insertStore = async () => {
    editStore(modifiedStore);
    setShowToastCreated(true);
    setTimeout(() => {
      setShowModal(false)
    }, 2000)
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-store-modal">
        <p>Please enter the details of your new store</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the store name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> URL:</IonLabel>
          <IonInput
            value={url}
            placeholder="Enter the store url"
            onIonChange={(e) => onChange("url", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton onClick={insertStore} slot="end">
            Update Store
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your store has been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { editStore },
  component: EditStoreModal,
});
