import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React from "react";
import { Store } from "../../models/Store";
import StoreItem from "./StoreItem";
import { connect } from "../../data/connect";

interface OwnProps {
  stores: Store[];
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface StoreListProps extends OwnProps, StateProps, DispatchProps {}

const StoreList: React.FC<StoreListProps> = ({ stores, hide }) => {
  //console.log(stores);
  if (stores.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Stores Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {stores.map((store, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <StoreItem store={store}></StoreItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: StoreList,
});
