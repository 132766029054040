import React, { useState, useEffect } from "react";
import {
  IonRow,
  IonItemGroup,
  IonAlert,
  IonIcon,
  IonCol,
  IonDatetime,
  IonCheckbox,
  IonButton,
} from "@ionic/react";
import { trashOutline, pencilOutline, statsChartOutline } from "ionicons/icons";
import { ShoppingList } from "../../models/ShoppingList";
import EditShoppingListModal from "./EditShoppingListModal";
import ShoppingListItemModal from "../shoppingListItems/ShoppingListItemModal";
import ShoppingCompareModal from "../shoppingCompare/shoppingCompareModal";
import {
  removeShoppingList,
  setCurrentShoppingList,
  getShoppingListItems,
} from "../../data/shoppingLists/shoppingLists.actions";
import { connect } from "../../data/connect";
import { useHistory } from "react-router-dom";

interface OwnProps {
  //extends RouteComponentProps {
  shoppingList: ShoppingList;
}

interface StateProps {
  //shoppingListItems: ShoppingListItem[];
}

interface DispatchProps {
  removeShoppingList: typeof removeShoppingList;
  setCurrentShoppingList: typeof setCurrentShoppingList;
  getShoppingListItems: typeof getShoppingListItems;
}

interface ShoppingListItemProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingListItem: React.FC<ShoppingListItemProps> = ({
  shoppingList,
  //shoppingListItems,
  removeShoppingList,
  setCurrentShoppingList,
  getShoppingListItems,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [showShoppingItemsModal, setShowShoppingItemsModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (shoppingList._id !== "") {
      getShoppingListItems(shoppingList);
    }    
  }, [shoppingList]);

  const showEditShoppingListModal = () => {
    setShowEditModal(!showEditModal);
  };

  const showShoppingCompareModal = () => {
    setShowCompareModal(!showCompareModal);
  };

  const showShoppingItems = () => {
    setShowShoppingItemsModal(!showShoppingItemsModal);
    setCurrentShoppingList(shoppingList);
  };

  const showShoppingItemsPage = () => {
    history.push(`/shoppinglist/${shoppingList._id}`);
  }

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol sizeSm="2" className="ion-hide-sm-down" onClick={showShoppingItemsPage}>
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={shoppingList.createdDate}
            disabled
          ></IonDatetime>
        </IonCol>
        <IonCol size="5" sizeSm="2" onClick={showShoppingItemsPage} className="ion-hide-sm-down">
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={shoppingList.completedDate}
            disabled
          ></IonDatetime>
        </IonCol>
        <IonCol size="2" sizeSm="2">
          <IonCheckbox checked={shoppingList.completed} disabled={true} />
        </IonCol>
        <IonCol size="2" sizeSm="1" className="ion-hide-sm-down">{shoppingList.totalLines}</IonCol>
        <IonCol size="6" sizeSm="3" onClick={showShoppingItemsPage}>{shoppingList.notes}</IonCol>
        <IonCol size="4" sizeSm="2">
          <IonButton
            size="small"
            shape="round"
            fill="clear" ////onClick={() => showShoppingCompareModal()}>
            routerLink={`/shoppingcompare/${shoppingList._id}`}
            routerDirection="none">
            
            <IonIcon          
              icon={statsChartOutline}              
            />
          </IonButton>
          <IonButton 
            size="small"
            shape="round"
            fill="clear"
            routerLink={`/shoppinglist/${shoppingList._id}`}
            routerDirection="none">
              <IonIcon slot="icon-only" icon={pencilOutline} />
          </IonButton> 
          <IonButton
            size="small"
            shape="round"
            fill="clear"
            onClick={() => setShowDeleteAlert(true)}>
            <IonIcon
              icon={trashOutline}              
            />
          </IonButton>
        </IonCol>
      </IonRow>
      <EditShoppingListModal show={showEditModal} shoppingList={shoppingList} />
      <ShoppingListItemModal
        show={showShoppingItemsModal}
        shoppingList={shoppingList}
      />
      <ShoppingCompareModal
        show={showCompareModal}
        shoppingList={shoppingList}
      />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeShoppingList(shoppingList);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    //shoppingListItems: state.shoppingListData.shoppingListItems,
  }),
  mapDispatchToProps: {
    removeShoppingList,
    setCurrentShoppingList,
    getShoppingListItems,
  },
  component: React.memo(ShoppingListItem),
});
