import React, { useState, useEffect } from "react";
import { IonModal } from "@ionic/react";
import { connect } from "../../data/connect";
import Calendar from "react-calendar";
import "./Calendar.css";
import "./DatePickerModal.scss";
import moment from "moment";

interface OwnProps {
  show: boolean;
  onSelected: (dates: string[]) => void;
  dates: string[];
}

interface StateProps {}

interface DispatchProps {}

type DateRangePickerProps = OwnProps & StateProps & DispatchProps;

export const DateRangePickerModal: React.FC<DateRangePickerProps> = ({
  show,
  onSelected,
  dates,
}) => {
  const [pickerStartDate, setPickerStartDate] = useState(dates[0]);
  const [pickerEndDate, setPickerEndDate] = useState(dates[1]);

  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    //console.log(show)
    setShowModal(show);
  }, [show]);

  const onSelect = (dateValue: Date | Date[]) => {
    //console.log(dateValue);
    if (Array.isArray(dateValue)) {
      let dateStringArray: string[];
      dateStringArray = [];
      dateValue.forEach((date) => {
        dateStringArray.push(moment(date.toString()).format("YYYY-MM-DD"));
      });

      if (dateStringArray !== null && dateStringArray.length === 2) {
        setPickerStartDate(dateStringArray[0]);
        setPickerEndDate(dateStringArray[1]);
        //);
        onSelected(dateStringArray);
        setShowModal(false);
      }
    }
    /*var dateString = moment(dateValue.toString()).format("YYYY-MM-DD");
    //console.log(dateString);
    setPickerStartDate(dateString);
    onSelected(dateString);
    setShowModal(false);*/
  };

  const onDismiss = () => {
    //console.log([pickerStartDate, pickerEndDate]);
    onSelected([pickerStartDate, pickerEndDate]);
    setShowModal(false);
  };

  return (
    <>
      <IonModal
        isOpen={showModal}
        id="date-picker-modal"
        onDidDismiss={onDismiss}
        cssClass={"auto-height"}
      >
        <div className="inner-content">
          <Calendar
            onChange={onSelect}
            value={[new Date(pickerStartDate), new Date(pickerEndDate)]}
            className="react-calendar"
            selectRange={true}
            //tileClassName="react-calendar__tile"
          />
        </div>
      </IonModal>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: DateRangePickerModal,
});
