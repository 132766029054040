import React, { useState, useRef } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonSlides,
  IonSlide,
  IonIcon,
  //IonItem,
  //useIonViewWillEnter,
} from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import { setHasSeenTutorial } from "../data/user/user.actions";
import "./Tutorial.scss";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import CreateDefaultItemCategoryList from "../components/itemCategories/CreateDefaultItemCategoryList";
import CreateDefaultItems from "../components/items/CreateDefaultItems";
import CreateStore from "../components/stores/CreateStore";
import { getDefaultItems } from "../data/defaultLists";
import { Footer } from "../components/layout/Footer";
//import { ItemCategory } from "../models/Item";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setHasSeenTutorial: typeof setHasSeenTutorial;
  getDefaultItems: typeof getDefaultItems;
}

interface TutorialProps extends OwnProps, DispatchProps {}

const Tutorial: React.FC<TutorialProps> = ({
  history,
  setHasSeenTutorial,
  getDefaultItems,
}) => {
  const [showSkip, setShowSkip] = useState(true);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

  const main = async () => {
    const content = document.querySelector("ion-content.ion-content-slides");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #63e0ff; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  /*useIonViewWillEnter(() => {
    setMenuEnabled(false);
  });*/

  const startApp = async () => {
    await setHasSeenTutorial(true);
    //await setMenuEnabled(true);
    history.push("/dashboard", { direction: "none" });
  };

  const startTrackingTrip = async () => {
    await setHasSeenTutorial(true);
    //await setMenuEnabled(true);
    history.push("/shoppingtrip/new", { direction: "none" });
  };

  const startShoppingList = async () => {
    await setHasSeenTutorial(true);
    //await setMenuEnabled(true);
    history.push("/shoppinglist/new", { direction: "none" });
  };

  const handleSlideChangeStart = () => {
    slideRef.current!.isEnd().then((isEnd) => {
      setShowSkip(!isEnd);
      setShowNext(!isEnd);
    });

    slideRef.current!.isBeginning().then((isBeginning) => {
      setShowPrev(!isBeginning);
    });
  };

  const nextSlide = () => {
    if (slideRef !== null && slideRef.current !== null) {
      slideRef.current.slideNext();
      //window.scrollTo({ top: 0, behavior: "smooth" });
      document.documentElement.scrollTo(0, 0); //.scrollTop = 0;
      document.body.scrollTo(0, 0); //.scrollTop = 0;
      slideRef.current.scrollTo(0, 0); //.scrollTop = 0;
    }
  };

  const prevSlide = () => {
    if (slideRef !== null && slideRef.current !== null) {
      slideRef.current.slidePrev();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <IonPage id="tutorial-page">
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && (
              <IonButton color="tertiary" onClick={startApp}>
                Skip
              </IonButton>
            )}
            {showPrev && (
              <IonButton color="tertiary" onClick={prevSlide}>
                Previous
              </IonButton>
            )}
            {showNext && (
              <IonButton color="tertiary" onClick={nextSlide}>
                Next
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-content-slides">
        <IonSlides
          ref={slideRef}
          onIonSlideWillChange={handleSlideChangeStart}
          pager={false}
        >
          <IonSlide>
            <h2 className="slide-title">
              Welcome to <b>Shopping Tracker</b>
            </h2>
            <p>
              We know that the intial setup can be quite daunting so we are
              going to help you to setup some initial items and categories to
              get you started.
            </p>
          </IonSlide>

          <IonSlide>
            <h2 className="slide-title">Item categories</h2>
            <p>
              Please select the item categories below that you wish to start off
              with (You can always edit them later or even add some of your own)
            </p>
            <CreateDefaultItemCategoryList onCreate={nextSlide} />
          </IonSlide>

          <IonSlide>
            <h2 className="slide-title">Items</h2>
            <p>
              Your items will form an essential part of your shopping lists and
              trips, and creating every item from scratch can be very time
              consuming. We have prepopulated a list of items that we think you
              might be interested in. Please <b>clear</b> the checkbox for the
              items you <b>do not</b> want us to automatically create for you.{" "}
              <br />
              Once again, you can always edit or delete them later, and if you
              carry on to use the site then you will certainly create many more.
            </p>
            <CreateDefaultItems onCreate={nextSlide} />
          </IonSlide>
          <IonSlide>
            <h2 className="slide-title">Create a store</h2>
            <p>
              Tracking a shopping trip is a key part of the process, and for
              that we need to create a store. So let's create one below.
            </p>
            <CreateStore onCreate={nextSlide} />
          </IonSlide>

          <IonSlide>
            <img
              src={
                prefersDark.matches
                  ? "assets/icon/Logo-Dark.svg"
                  : "assets/icon/Logo-Light.svg"
              }
              alt=""
              className="slide-image"
            />
            <h2 className="slide-title">Ready to go?</h2>
            <IonButton fill="clear" onClick={startApp}>
              Go to Dashbaord!
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>
            <br />
            <IonButton fill="clear" onClick={startShoppingList}>
              Create a shopping list!
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>
            <br />
            <IonButton fill="clear" onClick={startTrackingTrip}>
              Track a shopping trip!
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>
          </IonSlide>
        </IonSlides>
      </IonContent>
      <Footer hide={false} />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setHasSeenTutorial,
    getDefaultItems,
    //setMenuEnabled
  },
  component: Tutorial,
});
