import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Store } from "../../models/Store";
import { addNewStore } from "../../data/stores/stores.actions";
import { allowedToAddStore } from "../../data/premiumChecks";
import PremiumAlert from "../layout/PremiumAlert";

interface OwnProps {
  show: boolean;
  onCreated: (store: Store) => void;
}

interface StateProps {
  stores: Store[];
  premium: boolean;
}

interface DispatchProps {
  addNewStore: typeof addNewStore;
}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type CreateStoreProps = OwnProps & StateProps & DispatchProps;

export const CreateStoreModal: React.FC<CreateStoreProps> = ({
  show,
  onCreated,
  stores,
  premium,
  addNewStore,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [store, setStore] = useState<Store>({
    _id: "",
    userId: "",
    name: "",
    url: "",
    totalTrips: 0,
    totalSpent: 0,
  });

  //const id = item.id;
  const name = store.name;
  const url = store.url;

  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);
  const [showPremiumAlert, setShowPremiumAlert] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setStore({ ...store, [fieldName]: fieldValue });
  };

  const onPremiumAlertClose = () => {
    setShowPremiumAlert(false)
  }

  const insertStore = async () => {
    if (allowedToAddStore(stores.length, premium) === true) {
      await addNewStore(store);    
      setShowToastCreated(true);
      setTimeout(() => {
        setShowModal(false);
        onCreated(store);
        setStore({
          _id: "",
          userId: "",
          name: "",
          url: "",
          totalTrips: 0,
          totalSpent: 0,
        }); 
      }, 2000) 
    } else {
      setShowPremiumAlert(true);
    }    
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-store-modal">
        <p>Please enter the details of your new store</p>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the store name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> URL:</IonLabel>
          <IonInput
            value={url}
            placeholder="Enter the store url"
            onIonChange={(e) => onChange("url", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton onClick={async () => await insertStore()} slot="end">
            Create Store
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your store has been created."
          duration={1500}
          color="primary"
        />
      </IonModal>
      <PremiumAlert show={showPremiumAlert} onClosed={onPremiumAlertClose} type="stores"/>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    premium: state.user.user.premium,
    stores: state.storeData.stores,
  }),
  mapDispatchToProps: { addNewStore },
  component: CreateStoreModal,
});
