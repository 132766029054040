import React, { useState } from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { connect } from "../../data/connect";
import { Unit } from "../../models/Item";
import { addNewUnit } from "../../data/items/items.actions";

interface OwnProps {
  slot?: string;
  onChanged: (units: Unit[]) => void;
  show: boolean;
}

interface StateProps {
  units: Unit[];
}

interface DispatchProps {
  addNewUnit: typeof addNewUnit;
}

const defaultUnits: Unit[] = [
  { _id: "1", name: "Kilogrammes", symbol: "kg" },
  { _id: "2", name: "Litres", symbol: "l" },
  { _id: "3", name: "Pieces", symbol: "pcs" },
  { _id: "4", name: "Grams", symbol: "g" },
  { _id: "5", name: "500 grams", symbol: "500g" },
  { _id: "6", name: "500 millilitres", symbol: "500ml" },
  { _id: "7", name: "100 grams", symbol: "100g" },
  { _id: "8", name: "100 millilitres", symbol: "100ml" },
];

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type SelectUnitProps = OwnProps & StateProps & DispatchProps;

export const SelectDefaultUnitModal: React.FC<SelectUnitProps> = ({
  slot,
  onChanged,
  show,
  units,
  addNewUnit,
}) => {
  const [selectedUnits, setSelectedUnits] = useState<Unit[]>([]);

  const unitCompareWith = (o1: Unit, o2: Unit) => {
    return o1 && o2 ? o1._id === o2._id : o1 === o2;
  };

  const onChange = async (fieldValue: Unit[]) => {
    //fieldValue.map((unit) => addNewUnit(unit));
    //onChanged(fieldValue);
    //console.log(fieldValue);
    for await (let newUnit of fieldValue) {
      if (units.filter((unit) => unit.symbol === newUnit.symbol).length === 0) {
        await addNewUnit(newUnit);
        console.log(newUnit);
      }
    }
    //setUnits([]);
    //setSelectedUnits([]);
    //setShowToastCreated(true);
  };

  return (
    <>
      <IonSelect
        id="newItem-select-unit"
        placeholder="Select units to add"
        compareWith={unitCompareWith}
        onIonChange={(e) => onChange(e.detail.value)}
        slot={slot}
        multiple={true}
        hidden={!show}
        value={selectedUnits}
      >
        {defaultUnits
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((unit) => (
            <IonSelectOption key={unit._id} value={unit}>
              {unit.symbol} ({unit.name})
            </IonSelectOption>
          ))}
      </IonSelect>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    units: state.data.units,
  }),
  mapDispatchToProps: { addNewUnit },
  component: SelectDefaultUnitModal,
});
