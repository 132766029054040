import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ShoppingItem, ShoppingTrip } from "../../models/ShoppingTrip";
import { Item, Unit } from "../../models/Item";
import SelectUnitModal from "../units/SelectUnitModal";
import SelectItemModal from "../items/SelectItemModal";
import {
  editShoppingItem,
  editShoppingTrip,
} from "../../data/shoppingTrips/shoppingTrips.actions";
import { editItem } from "../../data/items/items.actions";
import { loadItemData } from "../../data/items/items.actions";
//import { wait } from "@testing-library/react";
//import { editShoppingTrip } from "../../data/shoppingTrips/shoppingTrips.actions";

interface OwnProps {
  show: boolean;
  shoppingItem: ShoppingItem;
  shoppingTrip: ShoppingTrip;
  onChanged: (shoppingItem: ShoppingItem) => void;
}

interface StateProps {}

interface DispatchProps {
  editShoppingTrip: typeof editShoppingTrip;
  editShoppingItem: typeof editShoppingItem;
  editItem: typeof editItem;
  loadItemData: typeof loadItemData;
}

type EditShoppingItemProps = OwnProps & StateProps & DispatchProps;

export const EditShoppingItemModal: React.FC<EditShoppingItemProps> = ({
  show,
  shoppingItem,
  shoppingTrip,
  onChanged,
  editShoppingTrip,
  editShoppingItem,
  editItem,
  loadItemData,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const [modifiedShoppingItem, setModifiedShoppingItem] =
    useState<ShoppingItem>(shoppingItem);

  const itemId = modifiedShoppingItem.itemId;
  const unitId = modifiedShoppingItem.unitId;
  const qty = modifiedShoppingItem.qty;
  const price = modifiedShoppingItem.price;
  const notes = modifiedShoppingItem.notes;

  const [showModal, setShowModal] = useState(show);
  const [showItemSelectModal, setShowItemSelectModal] = useState(false);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setModifiedShoppingItem({
      ...modifiedShoppingItem,
      [fieldName]: fieldValue,
    });
  };

  const onChangeUnit = (fieldValue: Unit) => {
    setModifiedShoppingItem({ ...shoppingItem, unitId: fieldValue });
  };

  const onChangeItem = (fieldValue: Item) => {
    setModifiedShoppingItem({
      ...shoppingItem,
      itemId: fieldValue,
      unitId: fieldValue.defaultUnitId,
    });
  };

  const showSelectItemModal = () => {
    setShowItemSelectModal(!showItemSelectModal);
  };

  const editCurrentShoppingItem = async () => {
    editShoppingItem(modifiedShoppingItem);
    editShoppingTrip(shoppingTrip);
    loadItemData();
    //editItem(modifiedShoppingItem.itemId);
    //onChanged(modifiedShoppingItem);

    setShowToastCreated(true);
    setTimeout(() => {
      setShowModal(false);
    }, 2000);
    //setShowModal(false);
  };

  return (
    <IonContent>
      <SelectItemModal
        item={itemId}
        onChanged={onChangeItem}
        show={showItemSelectModal}
      />
      <IonModal isOpen={showModal} id="edit-shoppingItem-modal">
        <p>Please modify the details you wish to change</p>
        <IonItem>
          <IonLabel>Item:</IonLabel>
          <IonInput
            type="text"
            value={itemId.name}
            placeholder="Item"
            onClick={showSelectItemModal}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Unit:</IonLabel>
          <SelectUnitModal unit={unitId} onChanged={onChangeUnit} slot={""} />
        </IonItem>
        <IonItem>
          <IonLabel>Quantity:</IonLabel>
          <IonInput
            type="number"
            inputMode="decimal"
            value={qty}
            placeholder="Qty"
            onIonChange={(e) => onChange("qty", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Price:</IonLabel>
          <IonInput
            type="number"
            inputMode="decimal"
            value={price}
            placeholder="Price"
            onIonChange={(e) => onChange("price", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel> Notes:</IonLabel>
          <IonInput
            value={notes}
            placeholder="Enter your notes"
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={async () => {
              editCurrentShoppingItem();
            }}
            slot="end"
          >
            Update Shopping Item
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your shopping item has been updated."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {
    editShoppingItem,
    editShoppingTrip,
    editItem,
    loadItemData,
  },
  component: EditShoppingItemModal,
});
