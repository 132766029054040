import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { ShoppingList } from "../../models/ShoppingList";
import ShoppingListItem from "./ShoppingListItem";
import { connect } from "../../data/connect";

interface OwnProps {
  shoppingLists: ShoppingList[];
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface ShoppingListListProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingListList: React.FC<ShoppingListListProps> = ({
  shoppingLists,
  hide,
}) => {
  const [listItems, setListItems] = useState<ShoppingList[]>(shoppingLists.slice(0, 4));
  //let length = listItems.length;

  const recursive = () => {
    setTimeout(() => {
      if (listItems.length  < shoppingLists.length) {
        setListItems( 
          shoppingLists.slice(0, listItems.length + 1)//length)
        );
      }
      
      //length += 4;
      //if (hasMore) recursive();
    }, 0);
  }

  useEffect(() => {
    //length = listItems.length;
    //console.log(listItems.length);
    recursive();
  }, [listItems, shoppingLists]);

  if (shoppingLists.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Shopping Lists Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {listItems.map((shoppingList, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <ShoppingListItem shoppingList={shoppingList}></ShoppingListItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ShoppingListList,
});
