import React, { useEffect, useState } from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import { connect } from "../../data/connect";

interface OwnProps {
  selectionStart: String;
  selectionEnd: String;
}

interface StateProps {
  shoppingTrips: ShoppingTrip[];
}

interface DispatchProps {}

interface StatsBoardProps extends OwnProps, StateProps, DispatchProps {}

const StatsBoard: React.FC<StatsBoardProps> = ({
  selectionStart,
  selectionEnd,
  shoppingTrips,
}) => {
  const [totalTrips, setTotalTrips] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);

  useEffect(() => {
    getData();
  }, [shoppingTrips, selectionStart, selectionEnd]);

  const getData = async () => {
    var spent = 0;

    var dateFilteredTrips = shoppingTrips.filter(
      (trip) =>
        trip.date.substring(0, 10) >= selectionStart.substring(0, 10) &&
        trip.date.substring(0, 10) <= selectionEnd.substring(0, 10) &&
        trip.totalAmount !== 0
    );

    dateFilteredTrips.forEach((shoppingTrip) => {
      spent += shoppingTrip.totalAmount;
    });

    setTotalTrips(dateFilteredTrips.length);
    setTotalSpent(spent);
  };

  return (
    <div>
      <IonItem>
        <IonLabel>Total trips:</IonLabel>
        <IonInput value={totalTrips} readonly></IonInput>
        <IonLabel>Total spent:</IonLabel>
        <IonInput
          value={new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(totalSpent)}
          readonly
        ></IonInput>
      </IonItem>
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingTrips: state.shoppingTripData.shoppingTrips,
  }),
  mapDispatchToProps: {},
  component: React.memo(StatsBoard),
});
