import React, { useState } from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { connect } from "../../data/connect";
import { ItemCategory } from "../../models/Item";
import { addNewItemCategory } from "../../data/items/items.actions";

interface OwnProps {
  slot?: string;
  onChanged: (itemCategories: ItemCategory[]) => void;
  show: boolean;
}

interface StateProps {
  itemCategories: ItemCategory[];
}

interface DispatchProps {
  addNewItemCategory: typeof addNewItemCategory;
}

const defaultItemCategories: ItemCategory[] = [
  { _id: "1", name: "Fruit", userId: "0" },
  { _id: "2", name: "Vegetables", userId: "0" },
  { _id: "3", name: "Starch", userId: "0" },
  { _id: "4", name: "Meat", userId: "0" },
  { _id: "5", name: "Treats", userId: "0" },
  { _id: "6", name: "Baking", userId: "0" },
];

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type SelectItemCategoryProps = OwnProps & StateProps & DispatchProps;

export const SelectDefaultItemCategoryModal: React.FC<SelectItemCategoryProps> = ({
  slot,
  onChanged,
  show,
  itemCategories,
  addNewItemCategory,
}) => {
  const [selectedItemCategories, setSelectedItemCategories] = useState<
    ItemCategory[]
  >([]);

  const itemCategoryCompareWith = (o1: ItemCategory, o2: ItemCategory) => {
    return o1 && o2 ? o1._id === o2._id : o1 === o2;
  };

  const onChange = async (fieldValue: ItemCategory[]) => {
    //fieldValue.map((itemCategory) => addNewItemCategory(itemCategory));
    //onChanged(fieldValue);
    console.log(fieldValue);
    for await (let newItemCategory of fieldValue) {
      if (
        itemCategories.filter(
          (itemCategory) => itemCategory.name === newItemCategory.name
        ).length === 0
      ) {
        await addNewItemCategory(newItemCategory);
        console.log(newItemCategory);
      }
    }

    //setItemCategories([]);

    //setShowToastCreated(true);
  };

  return (
    <>
      <IonSelect
        id="newItem-select-itemCategory"
        placeholder="Select itemCategories to add"
        compareWith={itemCategoryCompareWith}
        onIonChange={(e) => onChange(e.detail.value)}
        slot={slot}
        multiple={true}
        hidden={!show}
        value={selectedItemCategories}
      >
        {defaultItemCategories
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((itemCategory) => (
            <IonSelectOption key={itemCategory._id} value={itemCategory}>
              ({itemCategory.name})
            </IonSelectOption>
          ))}
      </IonSelect>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    itemCategories: state.data.itemCategories,
  }),
  mapDispatchToProps: { addNewItemCategory },
  component: SelectDefaultItemCategoryModal,
});
