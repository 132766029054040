import React, { useState } from "react";
import {
  IonRow,
  IonItemGroup,
  IonAlert,
  IonIcon,
  IonCol,
  //IonDatetime,
} from "@ionic/react";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { ShoppingTrip, ShoppingItem } from "../../models/ShoppingTrip";
import { User } from "../../models/User";
//import { Item, Unit } from "../../models/Item";
import EditShoppingItemModal from "../shoppingItems/EditShoppingItemModal";
import {
  editShoppingTrip,
  removeShoppingItem,
} from "../../data/shoppingTrips/shoppingTrips.actions";
import { connect } from "../../data/connect";

interface OwnProps {
  shoppingItem: ShoppingItem;
  shoppingTrip: ShoppingTrip;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  removeShoppingItem: typeof removeShoppingItem;
}

interface ShoppingItemItemProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingItemItem: React.FC<ShoppingItemItemProps> = ({
  shoppingItem,
  shoppingTrip,
  user,
  removeShoppingItem,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  /*const [modifiedShoppingTrip, setModifiedShoppingTrip] = useState<
    ShoppingTrip
  >(shoppingTrip);*/

  //console.log(shoppingItem);

  const showEditShoppingItemModal = () => {
    setShowEditModal(!showEditModal);
  };

  const onChangeShoppingItem = (fieldValue: ShoppingItem) => {
    //console.log(modifiedShoppingTrip);
    /*setModifiedShoppingTrip({
      ...modifiedShoppingTrip,
      shoppingTripItems: modifiedShoppingTrip.shoppingTripItems.map((item) =>
        item._id === fieldValue._id ? fieldValue : item
      ),
    });*/
    //console.log(modifiedShoppingTrip);
    editShoppingTrip(shoppingTrip);
    //console.log(modifiedShoppingTrip);
  };

  const deleteShoppingItem = async () => {
    await removeShoppingItem(shoppingItem);
  };

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="5" sizeSm="3" onClick={showEditShoppingItemModal}>
          {shoppingItem.itemId.name}
        </IonCol>
        <IonCol size="2">{shoppingItem.unitId.symbol}</IonCol>
        <IonCol size="2" sizeSm="1">
          {shoppingItem.qty}
        </IonCol>
        <IonCol size="2">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(shoppingItem.price)}
        </IonCol>
        <IonCol sizeSm="3" className="ion-hide-sm-down">
          {shoppingItem.notes}
        </IonCol>
        <IonCol>
          <IonIcon
            color="tertiary"
            icon={pencilOutline}
            onClick={() => showEditShoppingItemModal()}
          />
          <IonIcon
            color="tertiary"
            icon={trashOutline}
            onClick={() => setShowDeleteAlert(true)}
          />
        </IonCol>
      </IonRow>
      <EditShoppingItemModal
        shoppingItem={shoppingItem}
        shoppingTrip={shoppingTrip}
        onChanged={onChangeShoppingItem}
        show={showEditModal}
      />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: async () => {
              await deleteShoppingItem(); //removeShoppingItem(shoppingItem);
              await editShoppingTrip(shoppingTrip);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  mapDispatchToProps: {
    removeShoppingItem,
  },
  component: React.memo(ShoppingItemItem),
});
