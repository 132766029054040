import { getItemData } from "../dataApi";
import { ActionType } from "../../utils/types";
import { ItemsState } from "./items.state";
import {
  Item,
  Unit,
  ItemCategory,
  DefaultItemCategory,
} from "../../models/Item";
import axios from "axios";
import { ChartData, ChartDateParameters, ChartDateItemParameters } from "../../models/ChartObjects";

export const loadItemData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getItemData();
  dispatch(setData(data));
  dispatch(setLoading(false));
};

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-items-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<ItemsState>) =>
  ({
    type: "set-items-data",
    data,
  } as const);

export const addNewItem = (data: Item) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    /*const res = await fetch("/api/items", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });*/
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.post("/api/items", data, config);
    const result = await res.data; //.json();
    //console.info(result);

    dispatch(addItem(result));
  } catch {
    console.log("Error in items.actions -> addNewItem");
  }
};

export const editItem = (data: Item) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.put(`/api/items/${data._id}`, data, config);
    const result = await res.data;
    //console.info(result);

    dispatch(updateItem(data));
  } catch {
    console.log("Error in items.actions -> editItem");
  }
};

export const removeItem = (data: Item) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.delete(`/api/items/${data._id}`, config);
    const result = await res.data;
    //console.info(result);

    dispatch(deleteItem(data));
  } catch (e) {
    alert(e.response.data);
    console.log("Error in items.actions -> removeItem");
  }
};

export const addItem = (data: Item) =>
  ({
    type: "add-item",
    data,
  } as const);

export const updateItem = (data: Item) =>
  ({
    type: "update-item",
    data,
  } as const);

export const deleteItem = (data: Item) =>
  ({
    type: "delete-item",
    data,
  } as const);

export const setCurrentUnit = (id: string) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    const resUnits = await fetch(`/api/units/${id}`);
    const unit = await resUnits.json();

    dispatch(getUnit(unit));
  } catch {
    console.log("Error in items.actions -> setCurrentUnit");
  }
};

export const addNewUnit = (data: Unit) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.post("/api/units", data, config);
    const result = await res.data;
    //console.info(result);

    dispatch(addUnit(result));
  } catch {
    console.log("Error in items.actions -> addNewUnit");
  }
};

export const editUnit = (data: Unit) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.put(`/api/units/${data._id}`, data, config);
    const result = await res.data;
    //console.info(result);

    dispatch(updateUnit(data));
  } catch {
    console.log("Error in items.actions -> editUnit");
  }
};

export const removeUnit = (data: Unit) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.delete(`/api/units/${data._id}`, config);
    const result = await res.data;
    //console.info(result);

    dispatch(deleteUnit(data));
  } catch (e) {
    alert(e.response.data);
    console.log("Error in items.actions -> editUnit");
  }
};

export const getUnit = (data: Unit) =>
  ({
    type: "get-unit",
    data,
  } as const);

export const addUnit = (data: Unit) =>
  ({
    type: "add-unit",
    data,
  } as const);

export const updateUnit = (data: Unit) =>
  ({
    type: "update-unit",
    data,
  } as const);

export const deleteUnit = (data: Unit) =>
  ({
    type: "delete-unit",
    data,
  } as const);

export const addNewItemCategory = (data: ItemCategory) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.post("/api/itemCategories", data, config);
    const result = await res.data;
    //console.info(result);

    dispatch(addItemCategory(result));
  } catch {
    console.log("Error in items.actions -> addNewItemCategory");
  }
};

export const editItemCategory = (data: ItemCategory) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.put(
      `/api/itemCategories/${data._id}`,
      data,
      config
    );
    const result = await res.data;
    //console.info(result);

    dispatch(updateItemCategory(data));
  } catch {
    console.log("Error in items.actions -> editItemCategory");
  }
};

export const removeItemCategory = (data: ItemCategory) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.delete(`/api/itemCategories/${data._id}`, config);
    const result = await res.data;
    //console.info(result);

    dispatch(deleteItemCategory(data));
  } catch (e) {
    alert(e.response.data);
    console.log("Error in items.actions -> editItemCategory");
  }
};

export const addItemCategory = (data: ItemCategory) =>
  ({
    type: "add-itemCategory",
    data,
  } as const);

export const updateItemCategory = (data: ItemCategory) =>
  ({
    type: "update-itemCategory",
    data,
  } as const);

export const deleteItemCategory = (data: ItemCategory) =>
  ({
    type: "delete-itemCategory",
    data,
  } as const);

export const updateDefaultItemCategories = (
  updatedCategories: DefaultItemCategory[]
) =>
  ({
    type: "update-defaultItemCategories",
    updatedCategories,
  } as const);

  export const getItemCategoryChartData = (data: ChartDateParameters) => async (
    dispatch: React.Dispatch<any>
  ) => {
    try {
      //console.info(data);
  
      const res = await axios.get(`/api/itemcategorychart/?startdate=${data.startDate}&enddate=${data.endDate}`);
      const result = await res.data; //json();
  
      //console.log(result);
  
      dispatch(setItemCategoryChartData(result));
    } catch {
      console.log("Error in items.actions -> getItemCategoryChartData");
    }
  };

  export const setItemCategoryChartData = (chartData: ChartData) =>
  ({
    type: "set-item-category-chart-data",
    chartData,
  } as const)

  export const setItemCategoryChartLoading = (loading: boolean) =>
  ({
    type: "set-item-category-chart-loading",
    loading,
  } as const)

  export const getItemPriceHistoryChartData = (data: ChartDateItemParameters) => async (
    dispatch: React.Dispatch<any>
  ) => {
    try {
      //console.info(data);
  
      if (data.itemId) {
        const res = await axios.get(`/api/itempricehistorychart/?startdate=${data.startDate}&enddate=${data.endDate}&itemid=${data.itemId}`);
        const result = await res.data; //json();
  
        //console.log(result);
  
        dispatch(setItemPriceHistoryChartData(result));
      }      
    } catch (e) {
      alert(e.response.data);
      console.log("Error in items.actions -> getItemPriceHistoryChartData");
    }
  };

  export const setItemPriceHistoryChartData = (chartData: ChartData) =>
  ({
    type: "set-item-price-history-chart-data",
    chartData,
  } as const)

  export const setItemPriceHistoryChartLoading = (loading: boolean) =>
  ({
    type: "set-item-price-history-chart-loading",
    loading,
  } as const)

export const addFavorite = (sessionId: number) =>
  ({
    type: "add-favorite",
    sessionId,
  } as const);

export const removeFavorite = (sessionId: number) =>
  ({
    type: "remove-favorite",
    sessionId,
  } as const);

export const updateFilteredItems = (filteredItems: string[]) =>
  ({
    type: "update-filtered-items",
    filteredItems,
  } as const);

export const setSearchText = (searchText?: string) =>
  ({
    type: "set-search-text",
    searchText,
  } as const);

export const setMenuEnabled = (menuEnabled: boolean) =>
  ({
    type: "set-menu-enabled",
    menuEnabled,
  } as const);

export type ItemsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof addItem>
  | ActionType<typeof updateItem>
  | ActionType<typeof deleteItem>
  | ActionType<typeof getUnit>
  | ActionType<typeof addUnit>
  | ActionType<typeof updateUnit>
  | ActionType<typeof deleteUnit>
  | ActionType<typeof addItemCategory>
  | ActionType<typeof updateItemCategory>
  | ActionType<typeof deleteItemCategory>
  | ActionType<typeof updateDefaultItemCategories>
  | ActionType<typeof setItemCategoryChartData>
  | ActionType<typeof setItemCategoryChartLoading>
  | ActionType<typeof setItemPriceHistoryChartData>
  | ActionType<typeof setItemPriceHistoryChartLoading>
  | ActionType<typeof addFavorite>
  | ActionType<typeof removeFavorite>
  | ActionType<typeof updateFilteredItems>
  | ActionType<typeof setSearchText>
  | ActionType<typeof setMenuEnabled>;
