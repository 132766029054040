import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Item } from "../../models/Item";
import ItemShopCompareList from "./ItemShopCompareList";

interface OwnProps {
  show: boolean;
  item: Item;
}

interface StateProps {}

interface DispatchProps {}

type ItemShopCompareProps = OwnProps & StateProps & DispatchProps;

export const ItemShopCompareModal: React.FC<ItemShopCompareProps> = ({
  show,
  item,
}) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="shoppingItem-modal">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="3">Store</IonCol>
              <IonCol size="3">Average Price</IonCol>
              <IonCol size="3">Last Price</IonCol>
            </IonRow>
            <ItemShopCompareList
              //shoppingItems={shoppingItems}
              item={item}
              hide={false}
            />
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={() => {
              setShowModal(false);
            }}
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ItemShopCompareModal,
});
