import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonToast,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { ItemCategory } from "../../models/Item";
import { addNewItemCategory } from "../../data/items/items.actions";
//import "./CreateUnitModal.scss";

interface OwnProps {
  show: boolean;
  onCreated: (itemCategory: ItemCategory) => void;
}

interface StateProps {}

interface DispatchProps {
  addNewItemCategory: typeof addNewItemCategory;
}

interface CreateItemCategoryProps extends OwnProps, StateProps, DispatchProps {}

const CreateItemCategoryModal: React.FC<CreateItemCategoryProps> = ({
  show,
  onCreated,
  addNewItemCategory,
}) => {
  const [itemCategory, setItemCategory] = useState<ItemCategory>({
    _id: "",
    userId: "",
    name: "",
  });

  const name = itemCategory.name;
  const [showModal, setShowModal] = useState(show);
  const [showToastCreated, setShowToastCreated] = useState(false);

  useEffect(() => {
    setShowModal(show);
    //console.log(`Show: ${show}`);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) =>
    setItemCategory({ ...itemCategory, [fieldName]: fieldValue });

  const insertItemCategory = async () => {
    console.log(name);
    console.log(itemCategory);
    await addNewItemCategory(itemCategory);
    setShowToastCreated(true);
    setTimeout(() => {
      setShowModal(false);
      onCreated(itemCategory);
      setItemCategory({ _id: "", userId: "", name: "" });
    }, 2000)     
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="create-itemCategory-modal">
        <IonLabel>Please enter the details for your new item category</IonLabel>
        <IonItem>
          <IonLabel>Name:</IonLabel>
          <IonInput
            value={name}
            placeholder="Enter the item category name"
            onIonChange={(e) => onChange("name", e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem slot="end">
          <IonButton onClick={insertItemCategory} slot="end">
            Create Item Category
          </IonButton>
          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message="Your item category has been added."
          duration={1500}
          color="primary"
        />
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { addNewItemCategory },
  component: CreateItemCategoryModal,
});
