import React, { useState } from "react";
import { IonItem, IonInput, IonIcon } from "@ionic/react";
import { addNewShoppingList } from "../../data/shoppingLists/shoppingLists.actions";
import { ShoppingList } from "../../models/ShoppingList";
import { connect } from "../../data/connect";
import { addOutline, saveOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {
  addNewShoppingList: typeof addNewShoppingList;
}

interface NewShoppingListLineProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const NewShoppingListLine: React.FC<NewShoppingListLineProps> = ({
  addNewShoppingList,
}) => {
  let history = useHistory();

  const [showCreateLine, setShowCreateLine] = useState(false);

  const [shoppingList, setShoppingList] = useState<ShoppingList>({
    _id: "",
    userId: "",
    createdDate: "",
    completedDate: "",
    completed: false,
    notes: "",
    totalLines: 0,
  });

  const onChange = (fieldName: string, fieldValue: string) => {
    setShoppingList({ ...shoppingList, [fieldName]: fieldValue });
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      insertShoppingList();
    }
  };

  const notes = shoppingList.notes;

  const showCreateShoppingListLine = () => {
    //setShowCreateLine(!showCreateLine);
    history.push("/shoppinglist/new");
  };

  const insertShoppingList = async () => {
    await addNewShoppingList(shoppingList);
    setShoppingList({
      _id: "",
      userId: "",
      createdDate: "",
      completedDate: "",
      completed: false,
      notes: "",
      totalLines: 0,
    });
  };

  return (
    <>
      <IonItem hidden={!showCreateLine} onKeyPress={(e) => onKeyPress(e)}>
        <IonInput
          value={notes}
          placeholder="Enter any notes you want to add"
          onIonChange={(e) => onChange("notes", e.detail.value!)}
          slot="start"
        ></IonInput>
        <IonIcon
          icon={saveOutline}
          color="primary"
          onClick={insertShoppingList}
          slot="end"
        />
      </IonItem>
      <IonItem onClick={showCreateShoppingListLine}>
        <IonIcon icon={addOutline} /> Add a new shopping list
      </IonItem>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { addNewShoppingList },
  component: React.memo(NewShoppingListLine),
});
