import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { getShoppingItems } from "../../data/shoppingTrips/shoppingTrips.actions";
import { ShoppingList } from "../../models/ShoppingList";
import {
  getShoppingCompares,
  setLoadingCompareValue,
} from "../../data/shoppingLists/shoppingLists.actions";
import ShoppingCompareList from "../shoppingCompare/ShoppingCompareList";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  getShoppingCompares: typeof getShoppingCompares;
  setLoadingCompareValue: typeof setLoadingCompareValue;
}

interface StateProps {
  shoppingLists: ShoppingList[];
}

interface ShoppingComparePageProps
  extends OwnProps,
    DispatchProps,
    StateProps {}

const ShoppingComparePage: React.FC<ShoppingComparePageProps> = ({
  shoppingLists,
  getShoppingCompares,
  setLoadingCompareValue,
}) => {
  var { shoppinglistid } = useParams<{ shoppinglistid: string }>();

  const [shoppingList, setShoppingList] = useState<ShoppingList>({
    _id: "",
    userId: "",
    createdDate: "",
    completedDate: "",
    completed: false,
    notes: "",
    totalLines: 0,
  });

  const main = async () => {
    const content = document.querySelector(
      "ion-content.scrollbarShoppingTripItems"
    );
    const styles = document.createElement("style");

    styles.textContent = `
          ::-webkit-scrollbar {
            width: 5px;
          }
      
          /* Track */
          ::-webkit-scrollbar-track {
            box-shadow: 0 0 5px grey; 
            border-radius: 10px;
          }
      
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: lightgrey; 
            border-radius: 10px;
          }
      
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #bdc9bd; 
          }
        `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      const filteredShoppingLists = shoppingLists.filter(
        (list) => list._id === shoppinglistid
      );
      if (filteredShoppingLists.length > 0) {
        setShoppingList(filteredShoppingLists[0]);
        await setLoadingCompareValue(true);
        await getShoppingCompares(filteredShoppingLists[0]);
      }
    })();
  }, [shoppinglistid, getShoppingItems]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Compare prices</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="scrollbarShoppingTripItems">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="1"></IonCol>
              <IonCol size="3">Store</IonCol>
              <IonCol size="3">Ave Price</IonCol>
              <IonCol size="3">Last Price</IonCol>
              <IonCol size="2">Complete</IonCol>
            </IonRow>
            <ShoppingCompareList
              //shoppingItems={shoppingItems}
              shoppingList={shoppingList}
              hide={false}
            />
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonButton
            routerLink={`/page/ShoppingLists`}
            routerDirection="none"
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingLists: state.shoppingListData.shoppingLists,
  }),
  mapDispatchToProps: {
    getShoppingCompares,
    setLoadingCompareValue,
  },
  component: ShoppingComparePage,
});
