import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonInput,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "../../data/connect";
import { RouteComponentProps } from "react-router";
import { ShoppingTrip /*, ShoppingItem*/ } from "../../models/ShoppingTrip";
import { Store } from "../../models/Store";
import { User } from "../../models/User";
import {
  editShoppingTrip,
  getShoppingItems,
  addNewShoppingTrip,
} from "../../data/shoppingTrips/shoppingTrips.actions";
import ShoppingItemList from "./ShoppingItemList";
import NewShoppingItemLine from "./NewShoppingItemLine";
import SelectStoreModal from "../stores/SelectStoreModal";
import DatePickerModal from "../layout/DatePickerModal";
//import DatePicker from "react-date-picker";
import moment from "moment";
import "../../theme/styling.scss";
//import { filter } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {
  //shoppingTrip: ShoppingTrip;
}

interface DispatchProps {
  editShoppingTrip: typeof editShoppingTrip;
  getShoppingItems: typeof getShoppingItems;
  addNewShoppingTrip: typeof addNewShoppingTrip;
}

interface StateProps {
  //isAuthenticated: boolean;
  //error: string;
  //shoppingItems: ShoppingItem[];
  shoppingTrips: ShoppingTrip[];
  stores: Store[];
  lastCreatedId: string;
  user: User;
}

interface ShoppingItemsPageProps extends OwnProps, DispatchProps, StateProps {}

const ShoppingItemsPage: React.FC<ShoppingItemsPageProps> = ({
  //isAuthenticated,
  //error,
  //history,
  //shoppingItems,
  shoppingTrips,
  stores,
  lastCreatedId,
  user,
  editShoppingTrip,
  getShoppingItems,
  addNewShoppingTrip,
}) => {
  var { shoppingtripid } = useParams<{ shoppingtripid: string }>();

  const [showToastCreated, setShowToastCreated] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [shoppingTrip, setShoppingTrip] = useState<ShoppingTrip>({
    _id: "",
    userId: "",
    storeId: {
      _id: "",
      userId: "",
      name: "",
      url: "",
      totalTrips: 0,
      totalSpent: 0,
    },
    date: moment(new Date()).format("YYYY-MM-DD"), //
    notes: "",
    shoppingTripItems: [],
    totalLines: 0,
    totalAmount: 0,
  });
  const [modifiedShoppingTrip, setModifiedShoppingTrip] =
    useState<ShoppingTrip>(shoppingTrip);
  //const [date, setDate] = useState(modifiedShoppingTrip.date);

  const date = modifiedShoppingTrip.date;

  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const main = async () => {
    const content = document.querySelector(
      "ion-content.scrollbarShoppingTripItems"
    );
    const styles = document.createElement("style");

    styles.textContent = `
          ::-webkit-scrollbar {
            width: 5px;
          }
      
          /* Track */
          ::-webkit-scrollbar-track {
            box-shadow: 0 0 5px grey; 
            border-radius: 10px;
          }
      
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: lightgrey; 
            border-radius: 10px;
          }
      
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #bdc9bd; 
          }
        `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  useEffect(() => {
    (async function loadData() {
      //console.log(lastCreatedId);
      if (shoppingtripid === "new" && lastCreatedId !== "") {
        //shoppinglistid = lastCreatedId;
        const filteredShoppingTrips = shoppingTrips.filter(
          (trip) => trip._id === lastCreatedId
        );
        //console.log(filteredShoppingTrips[0]);
        //console.log(modifiedShoppingTrip);
        //console.log(modifiedShoppingTrip.date.substring(0, 10));
        if (
          filteredShoppingTrips.length > 0 &&
          modifiedShoppingTrip.date.substring(0, 10) ===
            filteredShoppingTrips[0].date.substring(0, 10) &&
          modifiedShoppingTrip.totalLines ===
            filteredShoppingTrips[0].totalLines &&
          modifiedShoppingTrip.notes === filteredShoppingTrips[0].notes &&
          modifiedShoppingTrip.storeId._id ===
            filteredShoppingTrips[0].storeId._id
        ) {
          setShoppingTrip(filteredShoppingTrips[0]);
          setModifiedShoppingTrip(filteredShoppingTrips[0]);
          //console.log(filteredShoppingLists[0])
          await getShoppingItems(filteredShoppingTrips[0]);
          shoppingtripid = lastCreatedId;
          //console.log("Found!");
          //console.log(filteredShoppingLists[0]);
        }
      }

      //console.log(shoppingtripid);
      if (shoppingtripid !== "new") {
        const filteredShoppingTrips = shoppingTrips.filter(
          (trip) => trip._id === shoppingtripid
        );
        if (filteredShoppingTrips.length > 0) {
          setShoppingTrip(filteredShoppingTrips[0]);
          setModifiedShoppingTrip(filteredShoppingTrips[0]);
          //console.log(filteredShoppingTrips[0])
          await getShoppingItems(filteredShoppingTrips[0]);
        }
      }

      if (shoppingTrip._id !== "") {
        const filteredShoppingTrips = shoppingTrips.filter(
          (trip) => trip._id === shoppingTrip._id
        );
        if (filteredShoppingTrips.length > 0) {
          setShoppingTrip(filteredShoppingTrips[0]);
          setModifiedShoppingTrip(filteredShoppingTrips[0]);
          //console.log(filteredShoppingTrips[0])
          await getShoppingItems(filteredShoppingTrips[0]);
        }
      }
    })();
    //console.log("useEffect triggered");
    //systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  }, [shoppingtripid, getShoppingItems, lastCreatedId, shoppingTrips]);

  const editCurrentShoppingTrip = async () => {
    if (modifiedShoppingTrip._id === "") {
      addNewShoppingTrip(modifiedShoppingTrip);
    } else {
      editShoppingTrip(modifiedShoppingTrip);
    }
    setShowToastCreated(true);
  };

  const onChangeStore = (fieldValue: Store) => {
    //console.log(fieldValue)
    if (fieldValue !== undefined) {
      if (fieldValue._id === "") {
        const filteredStores = stores.filter(
          (store) => store.name === fieldValue.name
        );
        //console.log(filteredStores[0])
        //onChanged(filteredStores[0]);
        if (filteredStores.length > 0) {
          setModifiedShoppingTrip({
            ...modifiedShoppingTrip,
            storeId: filteredStores[0],
          });
        } else {
          setModifiedShoppingTrip({
            ...modifiedShoppingTrip,
            storeId: fieldValue,
          });
        }
      } else {
        setModifiedShoppingTrip({
          ...modifiedShoppingTrip,
          storeId: fieldValue,
        });
      }
    }
  };

  const onNewRowClicked = async () => {
    //console.log("Clicked!")
    //console.log(modifiedShoppingList)
    if (modifiedShoppingTrip._id === "") {
      await addNewShoppingTrip(modifiedShoppingTrip);
    }
  };

  const onChange = (fieldName: string, fieldValue: string) => {
    //console.log(fieldValue)
    setModifiedShoppingTrip({
      ...modifiedShoppingTrip,
      [fieldName]: fieldValue,
    });
  };

  const onChangeDatePicker = (dateValue: string) => {
    //console.log(dateValue);
    onChange("date", dateValue);
    setShowDatePickerModal(false);
  };

  /*const onChangeDate = (dateValue: Date | Date[]) => {
    console.log(dateValue);
    setModifiedShoppingTrip({
      ...modifiedShoppingTrip,
      date: dateValue.toString(),
    });
  };*/

  return (
    <IonPage id="newShoppingTrip">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Shopping trip</IonTitle>
        </IonToolbar>
        <IonItem
          onClick={() => setShowDatePickerModal(true)}
          className={systemDark ? "" : "ion-item-background"}
        >
          <IonLabel>Date:</IonLabel>
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={date}
            placeholder="Enter the date it took place"
            onIonChange={(e) => onChange("date", e.detail.value!)}
            slot="end"
            readonly={true}
            //onClick={() => setShowDatePickerModal(!showDatePickerModal)}
          ></IonDatetime>
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Store:</IonLabel>
          <SelectStoreModal
            store={modifiedShoppingTrip.storeId}
            onChanged={onChangeStore}
            slot="end"
          />
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Total items:</IonLabel>
          {shoppingTrip.totalLines}
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel>Total spent:</IonLabel>
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(shoppingTrip.totalAmount)}
        </IonItem>
        <IonItem className={systemDark ? "" : "ion-item-background"}>
          <IonLabel> Notes:</IonLabel>
          <IonInput
            value={modifiedShoppingTrip.notes}
            placeholder="Enter your notes"
            onIonChange={(e) => onChange("notes", e.detail.value!)}
          ></IonInput>
        </IonItem>
      </IonHeader>

      <IonContent class="scrollbarShoppingTripItems">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="5" sizeSm="3">
                Item
              </IonCol>
              <IonCol size="2">Unit</IonCol>
              <IonCol size="2" sizeSm="1">
                Qty
              </IonCol>
              <IonCol size="2">Price</IonCol>
              <IonCol sizeSm="3" className="ion-hide-sm-down">
                Notes
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <ShoppingItemList
              //shoppingItems={shoppingItems}
              shoppingTrip={shoppingTrip}
              hide={false}
            />
            <IonRow
              onClick={async () => {
                onNewRowClicked();
              }}
            >
              <NewShoppingItemLine shoppingTrip={shoppingTrip} />
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonButton
            routerLink={`/page/ShoppingTrips`}
            routerDirection="none"
            onClick={editCurrentShoppingTrip}
            slot="end"
          >
            {modifiedShoppingTrip._id === "" ? "Create " : "Update "}Shopping
            Trip
          </IonButton>
          <IonButton
            routerLink={`/page/ShoppingTrips`}
            routerDirection="none"
            slot="end"
          >
            Close
          </IonButton>
        </IonItem>
        <DatePickerModal
          show={showDatePickerModal}
          date={moment(modifiedShoppingTrip.date).format("DD MMM YYYY")}
          onSelected={onChangeDatePicker}
        />
        <IonToast
          isOpen={showToastCreated}
          onDidDismiss={() => setShowToastCreated(false)}
          message={`Your shopping trip has been ${
            modifiedShoppingTrip._id === "" ? "created" : "updated"
          }.`}
          duration={1500}
          color="primary"
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    //isAuthenticated: state.user.isAuthenticated,
    //error: state.user.error,
    //shoppingItems: state.shoppingTripData.shoppingItems,
    shoppingTrips: state.shoppingTripData.shoppingTrips,
    stores: state.storeData.stores,
    lastCreatedId: state.shoppingTripData.lastCreatedId,
    user: state.user.user,
  }),
  mapDispatchToProps: {
    editShoppingTrip,
    getShoppingItems,
    addNewShoppingTrip,
  },
  component: ShoppingItemsPage,
});
