import React from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonHeader,
} from "@ionic/react";
import "./ExploreContainer.css";
import { connect } from "../../data/connect";
import { Item } from "../../models/Item";
import ItemList from "../items/ItemList";
import NewItemLine from "../items/NewItemLine";

interface OwnProps {}

interface StateProps {
  items: Item[];
}

interface DispatchProps {}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

const ItemsContainer: React.FC<ContainerProps> = ({ items }) => {
  const main = async () => {
    const content = document.querySelector("ion-content.scrollbarItems");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="5" sizeSm="2">
                Name
              </IonCol>
              <IonCol sizeSm="3" className="ion-hide-sm-down">
                Category
              </IonCol>
              <IonCol size="3" sizeSm="2">
                Ave price
              </IonCol>
              <IonCol sizeSm="2" className="ion-hide-sm-down">
                Last price
              </IonCol>
              <IonCol size="2">Per</IonCol>
              <IonCol size="2" sizeSm="1"></IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent class="scrollbarItems" overflow-scroll="true">
        <IonGrid>
          <ItemList items={items} hide={false} />
          <NewItemLine />
        </IonGrid>
      </IonContent>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    items: state.data.items,
  }),
  mapDispatchToProps: {},
  component: ItemsContainer,
});
