import React, { useState } from "react";
import {
  //IonItem,
  IonLabel,
  IonChip,
  IonAlert,
  IonItemGroup,
  IonIcon,
  IonRow,
  IonCol,
} from "@ionic/react";
import { trashOutline, pencilOutline, statsChartOutline } from "ionicons/icons";
import { Item } from "../../models/Item";
import { User } from "../../models/User";
import { removeItem } from "../../data/items/items.actions";
import { connect } from "../../data/connect";
import EditItemModal from "../items/EditItemModal";
import ItemShopCompareModal from "../itemShopCompare/ItemShopCompareModal";
import { useHistory } from "react-router-dom";

interface OwnProps {
  item: Item;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  removeItem: typeof removeItem;
}

interface ItemItemProps extends OwnProps, StateProps, DispatchProps {}

const ItemItem: React.FC<ItemItemProps> = ({ item, user, removeItem }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShopCompareModal, setShowShopCompareModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  let history = useHistory();

  const showEditItemModal = () => {
    setShowEditModal(!showEditModal);
  };

  const showItemShopCompareModal = () => {
    //setShowShopCompareModal(!showShopCompareModal);
    history.push(`/itemshopcompare/${item._id}`);
  };

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="5" sizeSm="2" onClick={() => showEditItemModal()}>
          {item.name}
        </IonCol>
        <IonCol sizeSm="3" className="ion-hide-sm-down">
          {item.itemCategories.length
            ? item.itemCategories.map((itemCategory) => (
                <IonChip key={itemCategory._id}>
                  <IonLabel>{itemCategory.name}</IonLabel>
                </IonChip>
              ))
            : "(none selected)"}
        </IonCol>
        <IonCol size="3" sizeSm="2">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.avePrice)}
        </IonCol>
        <IonCol sizeSm="2" className="ion-hide-sm-down">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.lastPrice)}
        </IonCol>
        <IonCol size="2">{item.defaultUnitId.symbol}</IonCol>
        <IonCol size="2" sizeSm="1">
          <IonIcon
            icon={statsChartOutline}
            onClick={() => showItemShopCompareModal()}
          />
          <IonIcon icon={pencilOutline} onClick={() => showEditItemModal()} />
          <IonIcon
            icon={trashOutline}
            onClick={() => setShowDeleteAlert(true)}
          />
        </IonCol>
      </IonRow>
      <EditItemModal item={item} show={showEditModal}></EditItemModal>
      <ItemShopCompareModal item={item} show={showShopCompareModal} />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeItem(item);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  mapDispatchToProps: { removeItem },
  component: React.memo(ItemItem),
});
