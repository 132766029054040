import React, { useState, useEffect } from "react";
import {
  IonRow,
  IonItemGroup,
  IonAlert,
  IonIcon,
  IonCol,
  IonDatetime,
  IonButton,
} from "@ionic/react";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { ShoppingTrip, ShoppingItem } from "../../models/ShoppingTrip";
import { User } from "../../models/User";
import EditShoppingTripModal from "./EditShoppingTripModal";
import ShoppingItemModal from "../shoppingItems/ShoppingItemModal";
import {
  removeShoppingTrip,
  setCurrentShoppingTrip,
  getShoppingItems,
} from "../../data/shoppingTrips/shoppingTrips.actions";
import { connect } from "../../data/connect";
//import { RouteComponentProps } from "react-router";'
import { useHistory } from "react-router-dom";

interface OwnProps {
  //extends RouteComponentProps {
  shoppingTrip: ShoppingTrip;
}

interface StateProps {
  shoppingItems: ShoppingItem[];
  user: User;
}

interface DispatchProps {
  removeShoppingTrip: typeof removeShoppingTrip;
  setCurrentShoppingTrip: typeof setCurrentShoppingTrip;
  getShoppingItems: typeof getShoppingItems;
}

interface ShoppingTripItemProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingTripItem: React.FC<ShoppingTripItemProps> = ({
  shoppingTrip,
  shoppingItems,
  user,
  removeShoppingTrip,
  setCurrentShoppingTrip,
  getShoppingItems,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showShoppingItemsModal, setShowShoppingItemsModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  //const [totalSpent, setTotalSpent] = useState(0);
  /*const [localShoppingItems, setLocalShoppingItems] = useState<ShoppingItem[]>(
    []
  );*/
  let history = useHistory();

  useEffect(() => {
    /*if (shoppingItems.length !== 0 ||
      shoppingItems[0].shoppingTripId === shoppingTrip._id) {

      }*/
    getShoppingItems(shoppingTrip);
    //setLocalShoppingItems(shoppingItems);
    //getTotalSpent();
  }, [shoppingTrip]);

  //console.log(shoppingTrip);
  //console.log(shoppingTrip.date);

  const showEditShoppingTripModal = () => {
    setShowEditModal(!showEditModal);
  };

  const showShoppingItems = () => {
    setShowShoppingItemsModal(!showShoppingItemsModal);
    setCurrentShoppingTrip(shoppingTrip);
    //history.push(`/shoppingtrip/${shoppingTrip._id}`);
  };

  const showShoppingItemsPage = () => {
    history.push(`/shoppingtrip/${shoppingTrip._id}`);
  };

  /*const getTotalSpent = async () => {
    if (
      localShoppingItems.length === 0 ||
      localShoppingItems[0].shoppingTripId !== shoppingTrip._id
    ) {
      setTotalSpent(0);
      console.log(shoppingTrip);
      localShoppingItems.map((shoppingItem, index: number) => {
        setTotalSpent(totalSpent + shoppingItem.price);
        console.log(totalSpent);
      });
    }
  };*/

  return (
    <IonItemGroup>
      <IonRow>
        <IonCol size="3" sizeSm="2" onClick={showShoppingItemsPage}>
          <IonDatetime
            displayFormat="DD MMM YYYY"
            value={shoppingTrip.date}
            disabled
          ></IonDatetime>
        </IonCol>
        <IonCol size="4" sizeSm="2">
          {shoppingTrip.storeId.name}
        </IonCol>
        <IonCol sizeSm="1" className="ion-hide-sm-down">
          {shoppingTrip.totalLines}
        </IonCol>
        <IonCol size="3" sizeSm="2">
          {new Intl.NumberFormat(`${user.numberFormat}`, {
            style: "currency",
            currency: `${user.currency}`,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(shoppingTrip.totalAmount)}
        </IonCol>
        <IonCol sizeSm="3" className="ion-hide-sm-down">
          {shoppingTrip.notes}
        </IonCol>
        <IonCol size="2">
          <IonButton
            size="small"
            shape="round"
            fill="clear"
            routerLink={`/shoppingtrip/${shoppingTrip._id}`}
            routerDirection="none"
          >
            <IonIcon slot="icon-only" icon={pencilOutline} />
          </IonButton>
          <IonButton
            size="small"
            shape="round"
            fill="clear"
            onClick={() => {
              setShowDeleteAlert(true);
            }}
          >
            <IonIcon slot="icon-only" icon={trashOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
      <EditShoppingTripModal show={showEditModal} shoppingTrip={shoppingTrip} />
      <ShoppingItemModal
        show={showShoppingItemsModal}
        shoppingTrip={shoppingTrip}
      />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeShoppingTrip(shoppingTrip);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    shoppingItems: state.shoppingTripData.shoppingItems,
    user: state.user.user,
  }),
  mapDispatchToProps: {
    removeShoppingTrip,
    setCurrentShoppingTrip,
    getShoppingItems,
  },
  component: React.memo(ShoppingTripItem),
});
