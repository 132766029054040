import React from "react";
import { Route, Redirect } from "react-router-dom";
//import { RouteComponentProps } from "react-router";
import Page from "../../pages/Page";
import Dashboard from "../../pages/Dashboard";
import Tutorial from "../../pages/Tutorial";
import Subscribe from "../../pages/Subscribe";
import ShoppingItemsPage from "../shoppingItems/ShoppingItemsPage";
import ShoppingListItemsPage from "../shoppingListItems/ShoppingListItemsPage";
import ShoppingComparePage from "../shoppingCompare/ShoppingComparePage";
import ItemShopComparePage from "../itemShopCompare/ItemShopComparePage";
import { connect } from "../../data/connect";

interface OwnProps {
  path: string;
}

interface StateProps {
  isAuthenticated: boolean;
  hasSeenTutorial: boolean;
}

interface DispatchProps {}

interface ContainerProps extends OwnProps, StateProps, DispatchProps {}

export const PrivateRoute: React.FC<ContainerProps> = ({
  path,
  isAuthenticated,
  hasSeenTutorial,
}) => {
  if (!isAuthenticated) {
    return <Redirect to="/" />
    //history.push("/");
    //return <Route path="/" direction="forward" />;
  } else if (path === "/tutorial") {
    return <Route path="/tutorial" component={Tutorial} exact />;
  } else if (path === "/dashboard") {
    return <Route path="/dashboard" component={Dashboard} exact />;
  } else if (path.startsWith("/shoppingtrip")) { 
    return <Route path={path} component={ShoppingItemsPage} />;
  } else if (path.startsWith("/shoppinglist")) {
    return <Route path={path} component={ShoppingListItemsPage} />;
  } else if (path.startsWith("/shoppingcompare")) {
    return <Route path={path} component={ShoppingComparePage} />;
  } else if (path.startsWith("/itemshopcompare")) {
    return <Route path={path} component={ItemShopComparePage} />;
  } else if (path.startsWith("/subscribe")) {
    return <Route path={path} component={Subscribe} />;
  } else{
    //console.log("Private route isAuthenitcated is true");
    return <Route path={path} component={Page} />;
  }
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    hasSeenTutorial: state.user.hasSeenTutorial,
  }),
  mapDispatchToProps: {},
  component: PrivateRoute,
});
