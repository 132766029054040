import Menu from "./components/Menu";
//import Page from "./pages/Page";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import LoggedOut from "./pages/LoggedOut";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Impressum from "./pages/Impressum";
//import Subscribe from "./pages/Subscribe";
import ConfirmEmail from "./pages/ConfirmEmail";
import Demo from "./pages/Demo";

//import { Footer } from "./components/layout/Footer";

import React, { useEffect } from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { connect } from "./data/connect";
import { AppContextProvider } from "./data/AppContext";

import { setIsLoggedIn, loadUserData } from "./data/user/user.actions";
import { loadItemData } from "./data/items/items.actions";
import { loadStoreData } from "./data/stores/stores.actions";
import { loadShoppingTripData } from "./data/shoppingTrips/shoppingTrips.actions";
import { loadShoppingListData } from "./data/shoppingLists/shoppingLists.actions";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/routing/PrivateRoute";

import Plausible from "plausible-tracker";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "./global.scss";

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  isLoggingOut: boolean;
  loading: boolean;
}

interface DispatchProps {
  loadItemData: typeof loadItemData;
  loadUserData: typeof loadUserData;
  loadStoreData: typeof loadStoreData;
  loadShoppingTripData: typeof loadShoppingTripData;
  loadShoppingListData: typeof loadShoppingListData;
  setIsLoggedIn: typeof setIsLoggedIn;
}

interface IonicAppProps extends StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  darkMode,
  isAuthenticated,
  isLoggingOut,
  loading,
  loadUserData,
  loadItemData,
  loadStoreData,
  loadShoppingTripData,
  loadShoppingListData,
}) => {
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    if (!isAuthenticated && !isLoggingOut) {
      (async function loadData() {
        await loadUserData();
      })();
      /*if (!isAuthenticated)
      {
        history.push("/page/Home", { direction: "none" });
      }*/
    }
    //console.log(isAuthenticated);

    if (isAuthenticated) {
      // && !loading
      loadItemData();
      loadStoreData();
      loadShoppingTripData();
      loadShoppingListData();
      //history.push("/page/Home", { direction: "none" });
    }
    // eslint-disable-next-line
  }, [isAuthenticated, isLoggingOut]); //, loading

  const main = async () => {
    const { enableAutoPageviews } = Plausible();

    // This tracks the current page view and all future ones as well
    enableAutoPageviews();

    const content = document.querySelector("ion-content");
    const styles = document.createElement("style");

    styles.textContent = `
      ::-webkit-scrollbar {
        width: 5px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: 0 0 5px grey; 
        border-radius: 10px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 10px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #bdc9bd; 
      }
    `;
    if (content !== null && content.shadowRoot !== null) {
      content.shadowRoot.appendChild(styles);
    }
  };

  setTimeout(() => {
    main();
  }, 250);

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  return (
    <IonApp className={`${darkMode ? "dark-theme" : ""}`}>
      <script
        defer
        data-domain="app.shoppingtracker.app"
        src="https://plausible.io/js/plausible.js"
      ></script>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu hide={!isAuthenticated} />
          <IonRouterOutlet id="main">
            <PrivateRoute path="/page/:name" />
            <PrivateRoute path="/dashboard" />
            <PrivateRoute path="/tutorial" />
            <PrivateRoute path="/shoppingtrip/:shoppingtripid" />
            <PrivateRoute path="/shoppinglist/:shoppinglistid" />
            <PrivateRoute path="/shoppingcompare/:shoppinglistid" />
            <PrivateRoute path="/itemshopcompare/:itemid" />
            <PrivateRoute path="/subscribe" />
            <Route path="/login" component={Login} exact />
            <Route path="/signup" component={Signup} exact />
            <Route path="/home" component={Home} exact />
            <Route path="/loggedout" component={LoggedOut} exact />
            <Route path="/forgotpassword" component={ForgotPassword} exact />
            <Route path="/confirmEmail/:token" component={ConfirmEmail} exact />
            <Route
              path="/resetpassword/:token"
              component={ResetPassword}
              exact
            />
            <Route path="/impressum" component={Impressum} exact />
            <Route path="/demo" component={Demo} exact />
            <Redirect from="/" to="/home" exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isAuthenticated,
    isLoggingOut: state.user.isLoggingOut,
    loading: state.user.loading,
    units: state.data.units,
  }),
  mapDispatchToProps: {
    loadUserData,
    setIsLoggedIn,
    loadItemData,
    loadStoreData,
    loadShoppingTripData,
    loadShoppingListData,
  },
  component: IonicApp,
});
