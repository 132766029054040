import React, { useState, useEffect } from "react";
import {
  //IonHeader,
  //IonToolbar,
  IonTitle,
  IonContent,
  //IonPage,
  //IonButtons,
  //IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
} from "@ionic/react";
import "./Login.scss";
import { LoginDetails } from "../../models/User";
import { login, clearError } from "../../data/user/user.actions";
//import { useAuth, login, clearErrors } from "../data/user/AuthState";
import { connect } from "../../data/connect";
import { useLocation, RouteComponentProps } from "react-router";
import DemoLoginDetails from "./DemoLoginDetails";
import DemoLoginButton from "./DemoLoginButton";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  login: typeof login;
  clearError: typeof clearError;
}

interface StateProps {
  isAuthenticated: boolean;
  error: string;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps {}

const LoginComponent: React.FC<LoginProps> = ({
  login,
  clearError,
  isAuthenticated,
  error,
  history,
  match,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [loginDetails, setLoginDetails] = useState<LoginDetails>({
    email: "",
    password: "",
  });
  const location = useLocation();
  //console.log(location);

  const email = loginDetails.email;
  const password = loginDetails.password;

  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (isAuthenticated) {
      //if (history.length > 1) history.goBack();
      //.go(1);
      //history.length - 2);
      //else
      //history.push("/");
    }

    if (error === "Invalid Credentials") {
      clearError();
    }
  }, [error, isAuthenticated, history]);

  const onChange = (fieldName: string, fieldValue: string) => {
    setLoginDetails({ ...loginDetails, [fieldName]: fieldValue });
  };

  const loginUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!email) {
      setEmailError(true);
    }
    if (!password) {
      setPasswordError(true);
    }

    if (email && password) {
      await login(loginDetails);
      history.push("/home", { direction: "none" });
    }
  };

  return (
    <IonContent
      hidden={isAuthenticated}
      className="login-block"
      id="login-page"
    >
      <div className="login-logo" id="logo">
        <img
          src={
            prefersDark.matches
              ? "assets/icon/Logo-Dark.svg"
              : "assets/icon/Logo-Light.svg"
          }
          alt="Shopping Tracker Logo"
        />
      </div>
      <form noValidate onSubmit={loginUser} className="login-block">
        <IonTitle size="large">Login</IonTitle>
        <IonList className="login-block">
          <IonItem>
            <IonLabel position="stacked" color="primary">
              Email
            </IonLabel>
            <IonInput
              name="email"
              type="email"
              value={email}
              spellCheck={false}
              autocapitalize="off"
              onIonChange={(e) => onChange("email", e.detail.value!)}
              required
            ></IonInput>
          </IonItem>

          {formSubmitted && emailError && (
            <IonText color="danger">
              <p className="ion-padding-start">Email is required</p>
            </IonText>
          )}

          <IonItem>
            <IonLabel position="stacked" color="primary">
              Password
            </IonLabel>
            <IonInput
              name="password"
              type="password"
              value={password}
              onIonChange={(e) => onChange("password", e.detail.value!)}
            ></IonInput>
          </IonItem>

          {formSubmitted && passwordError && (
            <IonText color="danger">
              <p className="ion-padding-start">Password is required</p>
            </IonText>
          )}

          {error !== "" && (
            <IonText color="danger">
              <p className="ion-padding-start">{error ? error : ""}</p>
            </IonText>
          )}
        </IonList>

        <IonRow className="login-block">
          <IonCol>
            <IonButton type="submit" expand="block">
              Login
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              routerLink="/forgotpassword"
              color="light"
              expand="block"
            >
              Forgot password
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton routerLink="/signup" color="light" expand="block">
              Signup
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem className="ion-text-center">OR</IonItem>
          </IonCol>
        </IonRow>
        <IonRow className="login-block">
          <IonCol>
            <DemoLoginButton
              history={history}
              location={location}
              match={match}
            />
          </IonCol>
        </IonRow>
      </form>
      <DemoLoginDetails />
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    error: state.user.error,
  }),
  mapDispatchToProps: {
    login,
    clearError,
  },
  component: React.memo(LoginComponent),
});
