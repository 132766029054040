import { IonItemGroup, IonList, IonListHeader } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import ShoppingTripItem from "./ShoppingTripItem";
import { connect } from "../../data/connect";

interface OwnProps {
  shoppingTrips: ShoppingTrip[];
  hide: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface ShoppingTripListProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingTripList: React.FC<ShoppingTripListProps> = ({
  shoppingTrips,
  hide,
}) => {
  const [listItems, setListItems] = useState<ShoppingTrip[]>(shoppingTrips.slice(0, 4));

  const recursive = () => {
    setTimeout(() => {
      if (listItems.length  < shoppingTrips.length) {
        setListItems( 
          shoppingTrips.slice(0, listItems.length + 1)//length)
        );
      }
    }, 0);
  }

  useEffect(() => {
    recursive();
  }, [listItems, shoppingTrips]);

  if (shoppingTrips.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Shopping Trips Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {listItems.map((shoppingTrip, index: number) => (
          <IonItemGroup key={`group-${index}`}>
            <ShoppingTripItem shoppingTrip={shoppingTrip}></ShoppingTripItem>
          </IonItemGroup>
        ))}
      </IonList>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ShoppingTripList,
});
