import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { IonButton, IonItem, IonLabel, IonTitle, IonProgressBar } from "@ionic/react";
//import { ShoppingTrip, ShoppingItem } from "../../models/ShoppingTrip";
//import { ItemCategory } from "../../models/Item";
import { ChartDataset, ChartData, ChartDateItemParameters } from "../../models/ChartObjects";
//import { getShoppingItems } from "../../data/shoppingTrips/shoppingTrips.actions";
import { getItemPriceHistoryChartData, setItemPriceHistoryChartLoading } from "../../data/items/items.actions"
import { connect } from "../../data/connect";
import { Bar } from "react-chartjs-2";

interface OwnProps {
  selectionStart: String;
  selectionEnd: String;
  itemId: String;
}

interface StateProps {
  itemPriceHistoryChartData: ChartData;
  itemPriceHistoryChartLoading?: boolean;
}

interface DispatchProps {
    getItemPriceHistoryChartData: typeof getItemPriceHistoryChartData;
    setItemPriceHistoryChartLoading: typeof setItemPriceHistoryChartLoading;
}

interface ItemPriceHistoryChartProps extends OwnProps, StateProps, DispatchProps {}

const ItemPriceHistoryChart: React.FC<ItemPriceHistoryChartProps> = ({
  selectionStart,
  selectionEnd,
  itemId,
  itemPriceHistoryChartData,
  itemPriceHistoryChartLoading,
  getItemPriceHistoryChartData,
  setItemPriceHistoryChartLoading,
}) => {
  const [labels, setLabels] = useState<String[]>([]);
  const [datasets, setDatasets] = useState<ChartDataset[]>([]);
  //const [loading, setLoading] = useState(false);
  const chartRef = useRef<Bar>(null);

  //var loading = false;
  //console.log(loading);

  var data = [] as Number[];

  useEffect(() => {
    //setLoading(true);
    getData();   
    //setLoading(false); 
  }, [itemPriceHistoryChartData]);

  useLayoutEffect(() => {
    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.chartInstance.update();
    }
  }, [datasets, labels]);

  var dynamicColors = function (alpha: number) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    //var a = Math.random() * 1;
    return "rgb(" + r + "," + g + "," + b + ", " + alpha + ")";
  };

  const refreshData = async () => {
    //setLoading(true);
    await setItemPriceHistoryChartLoading(true);
    let chartDates = {startDate: selectionStart, endDate: selectionEnd, itemId: itemId} as ChartDateItemParameters;
      await getItemPriceHistoryChartData(chartDates);
      //setLoading(false);
  }

  const getData = async () => {
    setLabels(itemPriceHistoryChartData.labels);
    setDatasets(itemPriceHistoryChartData.datasets);           

    if (chartRef !== null && chartRef.current !== null) {
      chartRef.current.chartInstance.update();
      chartRef.current.chartInstance.resize();
    }
  };

  const dataTest = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    title: {
      text: "Item price history from " + selectionStart + " to " + selectionEnd,
      display: true,
    },
    responsive: true,
    //maintainAspectRatio: false,
    //aspectRatio: 0.5,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          labelString: "€",
        },
      ],
    },
    animation: {
      duration: 0,
    },
  };

  return (
    <div /*style={{position: "relative", height:"40vh", width:"80vw"}}*/>
      <IonItem>
        <IonTitle className="ion-text-wrap">Item price history</IonTitle>
        <IonButton size="small" shape="round" onClick={async () => await refreshData()}>
          <IonLabel>
            Change colours
          </IonLabel>          
        </IonButton>
      </IonItem>
      {itemPriceHistoryChartLoading? 
        <IonItem>
          <IonTitle>Loading...</IonTitle>
          <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
        </IonItem>
        : <Bar ref={chartRef} data={dataTest} options={options} redraw></Bar>
      }
      
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    itemPriceHistoryChartData: state.data.itemPriceHistoryChartData,
    itemPriceHistoryChartLoading: state.data.itemPriceHistoryChartLoading,
  }),
  mapDispatchToProps: { 
      getItemPriceHistoryChartData,
      setItemPriceHistoryChartLoading,
     },
  component: React.memo(ItemPriceHistoryChart),
});
