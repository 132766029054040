import { getStoreData } from "../dataApi";
import { ActionType } from "../../utils/types";
import { StoresState } from "./stores.state";
import { Store } from "../../models/Store";
import axios from "axios";

export const loadStoreData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getStoreData();
  dispatch(setData(data));
  dispatch(setLoading(false));
};

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-stores-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<StoresState>) =>
  ({
    type: "set-stores-data",
    data,
  } as const);

export const addNewStore = (data: Store) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data);
    const res = await axios.post("/api/stores", data, config);
    const result = await res.data; //.json();
    //console.info(result);

    dispatch(addStore(result));
  } catch {
    console.log("Error in stores.actions -> addNewStore");
  }
};

export const editStore = (data: Store) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.info(data._id);
    const res = await axios.put(`/api/stores/${data._id}`, data, config);
    //console.info(data);
    /*const res = await fetch(`/api/stores/${data.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });*/
    const result = await res.data; //.json();

    dispatch(updateStore(result));
  } catch (e) {
    console.log(e);
    console.log("Error in stores.actions -> editStore");
  }
};

export const removeStore = (data: Store) => async (
  dispatch: React.Dispatch<any>
) => {
  try {
    //console.info(data);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/api/stores/${data._id}`, config);
    const result = await res.data;
    //console.info(result);

    dispatch(deleteStore(data));
  } catch (Error) {
    //console.log(Error.response)
    alert(Error.response.data);
    console.log("Error in stores.actions -> editStore");
  }
};

export const getStore = (data: Store) =>
  ({
    type: "get-store",
    data,
  } as const);

export const addStore = (data: Store) =>
  ({
    type: "add-store",
    data,
  } as const);

export const updateStore = (data: Store) =>
  ({
    type: "update-store",
    data,
  } as const);

export const deleteStore = (data: Store) =>
  ({
    type: "delete-store",
    data,
  } as const);

export const updateFilteredItems = (filteredItems: string[]) =>
  ({
    type: "update-filtered-items",
    filteredItems,
  } as const);

export const setSearchText = (searchText?: string) =>
  ({
    type: "set-search-text",
    searchText,
  } as const);

export type StoresActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof getStore>
  | ActionType<typeof addStore>
  | ActionType<typeof updateStore>
  | ActionType<typeof deleteStore>
  | ActionType<typeof updateFilteredItems>
  | ActionType<typeof setSearchText>;
