import React, { useState } from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { connect } from "../../data/connect";
import { Store } from "../../models/Store";
import CreateStoreModal from "../stores/CreateStoreModal";

interface OwnProps {
  store: Store;
  onChanged: (store: Store) => void;
  slot: string;
}

interface StateProps {
  stores: Store[];
}

interface DispatchProps {}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type SelectStoreProps = OwnProps & StateProps & DispatchProps;

export const SelectStoreModal: React.FC<SelectStoreProps> = ({
  store,
  onChanged,
  slot,
  stores,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [addNewStoreOption, setAddNewStoreOption] = useState<Store>({
    _id: "addNewStore",
    userId: "",
    name: "addNewStore",
    url: "",
    totalTrips: 0,
    totalSpent: 0,
  });

  const compareWith = (o1: Store, o2: Store) => {
    return o1 && o2 ? o1._id === o2._id : o1 === o2;
  };

  const onChange = (fieldValue: Store) => {
    if (fieldValue === addNewStoreOption) {
      showCreateStoreModal(true);
    } else {
      onChanged(fieldValue);
    }
  };

  const onCreate = (newStore: Store) => {
    //console.log(newStore)
    //console.log(stores)
    const filteredStores = stores.filter((store) => store.name === newStore.name)
    //console.log(filteredStores[0])
    if (newStore !== undefined) {
      onChanged(newStore);
    }    
  }

  const showCreateStoreModal = (showStoreModal: boolean) => {
    setShowCreateModal(showStoreModal);
  };

  return (
    <>
      <IonSelect
        id="newItem-select-store"
        placeholder="Store"
        compareWith={compareWith}
        value={store}
        onIonChange={(e) => onChange(e.detail.value)}
        slot={slot}
        interface="popover"
      >
        {stores
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((store) => (
            <IonSelectOption key={store._id} value={store}>
              {store.name}
            </IonSelectOption>
          ))}
        <IonSelectOption key="newStore" value={addNewStoreOption}>
          ...Add new store
        </IonSelectOption>
        <CreateStoreModal show={showCreateModal} onCreated={onCreate}></CreateStoreModal>
      </IonSelect>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    stores: state.storeData.stores,
  }),
  mapDispatchToProps: {},
  component: SelectStoreModal,
});
