import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonLabel,
  IonItem,
  IonCol,
  IonImg,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { connect } from "../../data/connect";
import { Store } from "../../models/Store";
import SelectStoreModal from "../stores/SelectStoreModal";
import { usePhotoGallery, Photo } from "../../utils/usePhotoGallery";
import { createWorker } from "tesseract.js";
import { scanReceipt } from "../../data/dataApi";
//import { FileUploader } from "../layout/FileUploader"
//import { useFileUpload } from 'use-file-upload';
import FileInput from "../layout/FileInput";
//import FileReader from "FileReader";
//import "./CreateUnitModal.scss";

interface OwnProps {
  show: boolean;
}

interface StateProps {}

interface DispatchProps {}

interface ScanInReceiptModalProps extends OwnProps, StateProps, DispatchProps {}

const ScanInReceiptModal: React.FC<ScanInReceiptModalProps> = ({ show }) => {
  const [store, setStore] = useState<Store>({
    _id: "",
    userId: "",
    name: "",
    url: "",
    totalTrips: 0,
    totalSpent: 0,
  });
  const [photo, setPhoto] = useState<Photo>();
  const [file, setFile] = useState<File>();
  //const [file, selectFiles] = useFileUpload();

  /*const [state, setState] = useState({
    file: '',
  });

  const {
    file,
  } = state;*/

  const worker = createWorker({
    logger: (m) => console.log(m),
  });

  const name = store.name;
  const [showModal, setShowModal] = useState(show);

  const { photos, takePhoto, getBase64 } = usePhotoGallery();

  useEffect(() => {
    setShowModal(show);
    //console.log(`Show: ${show}`);
  }, [show]);

  const onChange = (fieldName: string, fieldValue: string) =>
    setStore({ ...store, [fieldName]: fieldValue });

  const captureReceipt = async () => {
    let photo = await takePhoto();
    console.log(photo);

    setPhoto(photo);

    //let photo = photos.pop();
    //if (photo !== null && photo !== undefined) {
    //  const base64Data = await getBase64(photo.webviewPath? photo.webviewPath : "");

    /*await worker.load();
      await worker.loadLanguage('deu');
      await worker.initialize('deu');
      const { data: { text } } = await worker.recognize(base64Data);
      console.log(text);
      await worker.terminate();*/
    //}
  };

  const processReceipt = async () => {
    //scanReceipt(photo?.data)
    console.log(photo);

    const fileReader = new FileReader();
    //const blob = photo?.data;
    //fileReader.
    //fileReader.readAsArrayBuffer(photo?.filepath)
    //fileReader.readAsDataURL(photo?.data);
    const image = new Image();
    //image.src
  };

  const readFileOnUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e !== null && e.target !== null && e.target.files !== null) {
      var uploadedFile = e.target.files[0];

      const fileReader = new FileReader();
      if (uploadedFile !== undefined) fileReader.readAsText(uploadedFile);
      console.log(uploadedFile);
      scanReceipt(uploadedFile);
    }
  };

  const fileUploaded = (files: FileList | null) => {
    if (files) {
      console.log(files[0]);
      scanReceipt(files[0]);
    }
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} id="scanIn-Receipt-modal">
        <IonLabel>Let's scan in your receipt</IonLabel>
        <IonItem>
          <IonLabel>Store:</IonLabel>
          <SelectStoreModal store={store} onChanged={setStore} slot="end" />
        </IonItem>
        <IonGrid>
          <IonRow>
            {photos.map((photo, index) => (
              <IonCol size="6" key={index}>
                <IonImg src={photo.webviewPath} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
        <FileInput value={file} onChange={fileUploaded}>
          Upload file
        </FileInput>
        <IonItem slot="end">
          <IonButton onClick={captureReceipt} slot="end">
            Take photo
          </IonButton>
          <IonButton onClick={processReceipt} slot="end">
            Process receipt
          </IonButton>

          <IonButton onClick={() => setShowModal(false)} slot="end">
            Close
          </IonButton>
        </IonItem>
      </IonModal>
    </IonContent>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: ScanInReceiptModal,
});

/*
<input type="file"
            id='fileSelector'
            accept='image/png, image/jpeg'
            value={file}
            onChange={(e)=>
                readFileOnUpload(e)}>Upload file</input>
 */
