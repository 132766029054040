import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCheckbox,
  //IonContent,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
} from "@ionic/react";
import { connect } from "../../data/connect";
import {
  Item,
  Unit,
  ItemCategory,
  DefaultItem,
  DefaultItemCategory,
} from "../../models/Item";
import { addNewItem } from "../../data/items/items.actions";

interface OwnProps {
  onCreate: () => void;
}

interface StateProps {
  itemCategories: ItemCategory[];
  units: Unit[];
  items: Item[];
  defaultItems: DefaultItem[];
  defaultItemCategories: DefaultItemCategory[];
}

interface DispatchProps {
  addNewItem: typeof addNewItem;
}

type CreateDefaultItemsProps = OwnProps & StateProps & DispatchProps;

export const CreateDefaultItems: React.FC<CreateDefaultItemsProps> = ({
  onCreate,
  defaultItems,
  itemCategories,
  units,
  items,
  defaultItemCategories,
  addNewItem,
}) => {
  const [categoryPos, setCategoryPos] = useState(0);
  const [categoryLabel, setCategoryLabel] = useState("");

  var selectedCategories = defaultItemCategories.filter(
    (category) => category.checked === true
  );

  //if (selectedCategories.length > 0) console.log(defaultItemCategories);

  useEffect(() => {
    //console.log(defaultItemCategories);
    selectedCategories = defaultItemCategories.filter(
      (category) => category.checked === true
    );

    selectedCategories.sort((a, b) => (a.name > b.name ? 1 : -1));

    if (
      selectedCategories.length !== 0 &&
      selectedCategories[categoryPos] !== null
    )
      setCategoryLabel(selectedCategories[categoryPos].name);

    //console.log(selectedCategories);
    //console.log(categoryLabel);
  }, [defaultItemCategories, categoryPos]);

  const setChecked = async (item: DefaultItem, checked: boolean) => {
    defaultItems[Number(item._id)] = {
      _id: item._id,
      name: item.name,
      defaultUnitId: item.defaultUnitId,
      itemCategories: item.itemCategories,
      checked: checked,
    };
  };

  //const showCategory = (categoryName: string) => {};

  const onClick = async () => {
    selectedCategories = defaultItemCategories.filter(
      (category) => category.checked === true
    );

    var checkedItems = defaultItems.filter((item) => {
      return item.checked === true;
    });

    for await (let item of checkedItems) {
      var create = false;

      var unit = units.filter((u) => u.name === item.defaultUnitId);

      var itemCategory = itemCategories.filter(
        (cat) => item.itemCategories.findIndex((ic) => ic === cat.name) !== -1
      ) as ItemCategory[];

      itemCategory.forEach((cate) => {
        if (
          selectedCategories.findIndex((ic) => ic.name === cate.name) !== -1
        ) {
          create = true;
        }
      });

      const newItem: Item = {
        _id: "",
        name: item.name,
        defaultUnitId: unit[0],
        itemCategories: itemCategory,
        avePrice: 0,
        lastPrice: 0,
        storeLastPrice: [],
        userId: "0",
      };
      if (
        items.filter((item) => item.name === newItem.name).length === 0 &&
        create
      ) {
        await addNewItem(newItem);
      }
    }

    //window.scrollTo({ top: 0, behavior: "smooth" });
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    onCreate();
  };

  return (
    <>
      <IonLabel>{categoryLabel}</IonLabel>
      <IonItemGroup>
        <IonList>
          {defaultItemCategories
            .filter((category) => category.checked === true)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((category, index) => (
              <IonItemGroup key={`ItemItemCate-${category._id}-${index}`}>
                <IonItemDivider>
                  <IonLabel>{category.name}</IonLabel>
                </IonItemDivider>
                <IonList>
                  {defaultItems
                    .filter(
                      (item) =>
                        item.itemCategories.includes(category.name) === true
                    )
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => (
                      <IonItem key={`ItemItem-${item._id}`}>
                        <IonCheckbox
                          key={`Item-${item._id}`}
                          checked={item.checked}
                          onIonChange={(e) =>
                            setChecked(item, e.detail.checked)
                          }
                        ></IonCheckbox>
                        <IonLabel> {item.name}</IonLabel>
                      </IonItem>
                    ))}
                </IonList>
              </IonItemGroup>
            ))}
        </IonList>
      </IonItemGroup>
      <IonButton onClick={onClick}>Create</IonButton>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    units: state.data.units,
    itemCategories: state.data.itemCategories,
    items: state.data.items,
    defaultItems: state.data.defaultItems,
    defaultItemCategories: state.data.defaultItemCategories,
  }),
  mapDispatchToProps: { addNewItem },
  component: CreateDefaultItems,
});
