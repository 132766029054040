import { combineReducers } from "./combineReducers";
//import { combineReducers } from 'redux'
import { itemsReducer } from "./items/items.reducer";
import { storesReducer } from "./stores/stores.reducer";
import { userReducer } from "./user/user.reducer";
import { shoppingTripsReducer } from "./shoppingTrips/shoppingTrips.reducer";
import { shoppingListsReducer } from "./shoppingLists/shoppingLists.reducer";

export const initialState: AppState = {
  data: {
    items: [],
    units: [],
    currentUnit: { _id: "", name: "", symbol: "" },
    itemCategories: [],
    favorites: [],
    filteredItems: [],
    defaultItems: [],
    defaultItemCategories: [],
    loading: false,
    menuEnabled: true,
    itemCategoryChartData: { labels: [], datasets: [] },
    itemPriceHistoryChartData: { labels: [], datasets: [] },
  },
  user: {
    hasSeenTutorial: false,
    darkMode: true,
    isAuthenticated: false,
    isLoggingOut: false,
    loading: false,
    voucherCodeResult: false,
    token: "",
    user: {
      name: "",
      email: "",
      hasSeenTutorial: false,
      currency: "",
      numberFormat: "",
      premium: false,
    },
    error: "",
  },
  storeData: {
    currentStore: {
      _id: "",
      userId: "",
      name: "",
      url: "",
      totalTrips: 0,
      totalSpent: 0,
    },
    stores: [],
    filteredItems: [],
    loading: false,
  },
  shoppingTripData: {
    currentShoppingTrip: {
      _id: "",
      userId: "",
      storeId: {
        _id: "",
        userId: "",
        name: "",
        url: "",
        totalTrips: 0,
        totalSpent: 0,
      },
      date: "",
      notes: "",
      shoppingTripItems: [],
      totalLines: 0,
      totalAmount: 0,
    },
    shoppingTrips: [],
    shoppingItems: [],
    lastCreatedId: "",
    filteredTrips: [],
    loading: false,
  },
  shoppingListData: {
    currentShoppingList: {
      _id: "",
      userId: "",
      createdDate: "",
      completedDate: "",
      completed: false,
      notes: "",
      totalLines: 0,
    },
    shoppingLists: [],
    shoppingListItems: [],
    shoppingCompares: [],
    shoppingCompareItems: [],
    lastCreatedId: "",
    filteredLists: [],
    loading: false,
  },
};

export const reducers = combineReducers({
  data: itemsReducer,
  storeData: storesReducer,
  shoppingTripData: shoppingTripsReducer,
  shoppingListData: shoppingListsReducer,
  user: userReducer,
});

export type AppState = ReturnType<typeof reducers>;
