import React, { useState } from "react";
import {
  IonItem,
  IonLabel,
  IonItemGroup,
  IonAlert,
  IonIcon,
} from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { ItemCategory } from "../../models/Item";
import EditItemCategoryModal from "./EditItemCategoryModal";
import { removeItemCategory } from "../../data/items/items.actions";
import { connect } from "../../data/connect";

interface OwnProps {
  itemCategory: ItemCategory;
}

interface StateProps {}

interface DispatchProps {
  removeItemCategory: typeof removeItemCategory;
}

interface ItemCategoryItemProps extends OwnProps, StateProps, DispatchProps {}

const ItemCategoryItem: React.FC<ItemCategoryItemProps> = ({
  itemCategory,
  removeItemCategory,
}) => {
  /*
  name: string;
  symbol: string;
     */
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const showEditUnitModal = () => {
    setShowEditModal(!showEditModal);
  };
  return (
    <IonItemGroup>
      <IonItem>
        <IonLabel onClick={showEditUnitModal}>{itemCategory.name}</IonLabel>
        <IonIcon icon={trashOutline} onClick={() => setShowDeleteAlert(true)} />
      </IonItem>
      <EditItemCategoryModal show={showEditModal} itemCategory={itemCategory} />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Confirm!"}
        message={"<strong>Are you sure you want to delete this?</strong>!!!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Okay",
            handler: () => {
              removeItemCategory(itemCategory);
            },
          },
        ]}
      />
    </IonItemGroup>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { removeItemCategory },
  component: React.memo(ItemCategoryItem),
});
