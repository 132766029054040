import React, { useState } from "react";
import { IonItem, IonDatetime, IonIcon } from "@ionic/react";
import { addNewShoppingTrip } from "../../data/shoppingTrips/shoppingTrips.actions";
import { ShoppingTrip } from "../../models/ShoppingTrip";
import { Store } from "../../models/Store";
import SelectStoreModal from "../stores/SelectStoreModal";
import { connect } from "../../data/connect";
import { addOutline, saveOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {
  addNewShoppingTrip: typeof addNewShoppingTrip;
}

interface NewShoppingTripLineProps
  extends OwnProps,
    StateProps,
    DispatchProps {}

const NewShoppingTripLine: React.FC<NewShoppingTripLineProps> = ({
  addNewShoppingTrip,
}) => {
  const [showCreateLine, setShowCreateLine] = useState(false);

  let history = useHistory();

  /*
  id: string;
  userId: string;
  storeId: Store;
  Date: string;
  notes: string;
  shoppingItems: ShoppingItem[];
   */
  const [shoppingTrip, setShoppingTrip] = useState<ShoppingTrip>({
    _id: "",
    userId: "",
    storeId: {
      _id: "",
      userId: "",
      name: "",
      url: "",
      totalTrips: 0,
      totalSpent: 0,
    },
    date: "",
    notes: "",
    shoppingTripItems: [],
    totalLines: 0,
    totalAmount: 0,
  });

  const onChange = (fieldName: string, fieldValue: string) => {
    setShoppingTrip({ ...shoppingTrip, [fieldName]: fieldValue });
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      insertShoppingTrip();
    }
  };

  const onChangeStore = (fieldValue: Store) => {
    setShoppingTrip({ ...shoppingTrip, storeId: fieldValue });
  };

  const date = shoppingTrip.date;

  const showCreateShoppingTripLine = () => {
    //setShowCreateLine(!showCreateLine);
    history.push("/shoppingtrip/new");
  };

  const insertShoppingTrip = async () => {
    await addNewShoppingTrip(shoppingTrip);
    //setShowToastCreated(true);
    setShoppingTrip({
      _id: "",
      userId: "",
      storeId: {
        _id: "",
        userId: "",
        name: "",
        url: "",
        totalTrips: 0,
        totalSpent: 0,
      },
      date: "",
      notes: "",
      shoppingTripItems: [],
      totalLines: 0,
      totalAmount: 0,
    });
  };

  return (
    <>
      <IonItem hidden={!showCreateLine} onKeyDown={(e) => onKeyPress(e)}>
        <IonDatetime
          displayFormat="DD MMM YYYY"
          value={date}
          placeholder="Date"
          onIonChange={(e) => onChange("date", e.detail.value!)}
          onKeyDown={(e) => onKeyPress(e)}
          slot="start"
        ></IonDatetime>
        <SelectStoreModal
          store={shoppingTrip.storeId}
          onChanged={onChangeStore}
          slot="start"
        />
        <IonIcon
          icon={saveOutline}
          color="primary"
          onClick={insertShoppingTrip}
          slot="end"
        />
      </IonItem>
      <IonItem onClick={showCreateShoppingTripLine}>
        <IonIcon icon={addOutline} /> Add a new shopping trip
      </IonItem>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: { addNewShoppingTrip },
  component: React.memo(NewShoppingTripLine),
});
