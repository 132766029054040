import React from "react";
import { IonCol, IonGrid, IonRow, IonIcon, IonButton } from "@ionic/react";
import { checkmarkOutline, helpOutline } from "ionicons/icons";
import { connect } from "../../data/connect";
import { maxStores, maxItems } from "../../data/premiumChecks";

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface GridProps extends OwnProps, StateProps, DispatchProps {}

const FreemiumComparisonGrid: React.FC<GridProps> = ({}) => {
  return (
    <>
      <IonGrid className="freemium-compare-grid">
        <IonRow>
          <IonCol size="8">
            <b>Feature</b>
          </IonCol>
          <IonCol size="2">
            <b>Free</b>
          </IonCol>
          <IonCol size="2">
            <b>Premium</b>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Stores</IonCol>
          <IonCol size="2">{maxStores}</IonCol>
          <IonCol size="2">Unlimited</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Items</IonCol>
          <IonCol size="2">{maxItems}</IonCol>
          <IonCol size="2">Unlimited</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Item categories</IonCol>
          <IonCol size="2">Unlimited</IonCol>
          <IonCol size="2">Unlimited</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Shopping trips</IonCol>
          <IonCol size="2">Unlimited</IonCol>
          <IonCol size="2">Unlimited</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Shopping lists</IonCol>
          <IonCol size="2">Unlimited</IonCol>
          <IonCol size="2">Unlimited</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Convert shopping lists into trips</IonCol>
          <IonCol size="2">
            <IonIcon icon={checkmarkOutline} />
          </IonCol>
          <IonCol size="2">
            <IonIcon icon={checkmarkOutline} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">See item price history</IonCol>
          <IonCol size="2">
            <IonIcon icon={checkmarkOutline} />
          </IonCol>
          <IonCol size="2">
            <IonIcon icon={checkmarkOutline} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">See spending charts</IonCol>
          <IonCol size="2">
            <IonIcon icon={checkmarkOutline} />
          </IonCol>
          <IonCol size="2">
            <IonIcon icon={checkmarkOutline} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Up and coming features</IonCol>
          <IonCol size="2">
            <IonIcon icon={helpOutline} />
          </IonCol>
          <IonCol size="2">
            <IonIcon icon={checkmarkOutline} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="8">Price</IonCol>
          <IonCol size="2">
            <IonButton color="tertiary" disabled={true}>
              Free
            </IonButton>
          </IonCol>
          <IonCol size="2">
            <IonButton color="tertiary" disabled={true}>
              2,99€ per month /
              <br />
              29,99€ per year
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(FreemiumComparisonGrid),
});
