import React, { useState } from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { connect } from "../../data/connect";
import { Unit } from "../../models/Item";
import CreateUnitModal from "../units/CreateUnitModal";

interface OwnProps {
  unit: Unit;
  onChanged: (unit: Unit) => void;
  slot?: string;
}

interface StateProps {
  units: Unit[];
}

interface DispatchProps {}

//interface CreateItemProps extends OwnProps, StateProps, DispatchProps {}
type SelectUnitProps = OwnProps & StateProps & DispatchProps;

export const SelectUnitModal: React.FC<SelectUnitProps> = ({
  unit,
  onChanged,
  slot,
  units,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [addNewUnitOption, setAddNewUnitOption] = useState<Unit>({
    _id: "addNewUnit",
    name: "addNewUnit",
    symbol: "",
  });

  const unitCompareWith = (o1: Unit, o2: Unit) => {
    return o1 && o2 ? o1._id === o2._id : o1 === o2;
  };

  const onChange = (fieldValue: Unit) => {
    if (fieldValue === addNewUnitOption) {
      showCreateUnitModal(true);
    } else {
      onChanged(fieldValue);
    }
  };

  const onCreate = (newUnit: Unit) => {
    console.log(newUnit)
    //const filteredItems = items.filter((item) => item.name === newItem.name)
    //console.log(filteredItems[0])
    if (newUnit !== undefined) {
      //console.log("Here")
      onChange(newUnit);
    }    
    showCreateUnitModal(false);
  }

  const showCreateUnitModal = (showUnitModal: boolean) => {
    setShowCreateModal(showUnitModal);
  };

  return (
    <>
      <IonSelect
        id="newItem-select-unit"
        placeholder="Unit"
        compareWith={unitCompareWith}
        value={unit}
        onIonChange={(e) => onChange(e.detail.value)}
        slot={slot}
        interface="popover"
      >
        {units
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((unit) => (
            <IonSelectOption key={unit._id} value={unit}>
              {unit.symbol} ({unit.name})
            </IonSelectOption>
          ))}
        <IonSelectOption key="newUnit" value={addNewUnitOption}>
          ...Add new unit
        </IonSelectOption>
        <CreateUnitModal show={showCreateModal} onCreated={onCreate}></CreateUnitModal>
      </IonSelect>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    units: state.data.units,
  }),
  mapDispatchToProps: {},
  component: SelectUnitModal,
});
